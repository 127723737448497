// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.namedot{
    width: 30px;
    height: 30px;
    background-color: blue;
    color: white !important;
    font-weight: 800;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 5px;
    top: 13px;
}
.namedot h1{
    color: white !important;
  
}
.drop-namedot{
    display: flex;
    justify-content: center;
    align-items: center;
}

.jobnames{
    text-transform: uppercase !important;
}
.namesfirst {
    font-weight: 700;
}

.custom-dropdown-containerss{
    width: 80px !important;
}`, "",{"version":3,"sources":["webpack://./src/Styles/EmployerDashboardH.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,SAAS;AACb;AACA;IACI,uBAAuB;;AAE3B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;AACxC;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".namedot{\r\n    width: 30px;\r\n    height: 30px;\r\n    background-color: blue;\r\n    color: white !important;\r\n    font-weight: 800;\r\n    border-radius: 50%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: absolute;\r\n    left: 5px;\r\n    top: 13px;\r\n}\r\n.namedot h1{\r\n    color: white !important;\r\n  \r\n}\r\n.drop-namedot{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.jobnames{\r\n    text-transform: uppercase !important;\r\n}\r\n.namesfirst {\r\n    font-weight: 700;\r\n}\r\n\r\n.custom-dropdown-containerss{\r\n    width: 80px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
