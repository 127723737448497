import React from 'react';
import Footer from '../components/Footer';
import { MdStarRate } from "react-icons/md";

function CompanyProfile() {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f5f8fa', color: '#333', margin: 0 }}>
            {/* Main Container */}
            <div style={{
                maxWidth: '100%',
                margin: '20px auto',
                padding: '20px',
                backgroundColor: '#ffffff',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px'
            }}>
                {/* Company Header */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h2>W GOA</h2>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px', color: '#333' }}>
                            <span style={{
                                backgroundColor: '#d1eaff',
                                color: '#0074cc',
                                padding: '5px 8px',
                                borderRadius: '5px',
                                marginRight: '5px'
                            }}>
                                <MdStarRate />3.9
                            </span>
                            Based on 35 Reviews
                        </div>
                    </div>
                </div>

                {/* About Section */}
                <div style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', gap: '20px', marginBottom: '15px'}}>
                        <button style={tabButtonStyle}>About</button>
                        <button style={tabButtonStyle1}>Reviews 35</button>
                    </div>
                    <h3 style={{ fontSize: '18px', marginBottom: '15px' }}>About W Goa</h3>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '20px',
                        marginBottom: '15px'
                    }}>
                        <div style={infoTextStyle}>Founded in <strong>2016 (8 yrs old)</strong></div>
                        <div style={infoTextStyle}>India Employee Count</div>
                        <div style={infoTextStyle}>India Headquarters<br />Goa, India</div>
                        <div style={infoTextStyle}>Office Locations</div>
                        <div style={infoTextStyle}>Primary Industry<br /><strong>Hospitality</strong></div>
                        <div style={infoTextStyle}>Other Industries</div>
                        <div style={infoTextStyle}>Global Employee Count<br /><strong>201-500</strong></div>
                        <div style={infoTextStyle}>Website<br /><a href="https://marriott.com" target="_blank" rel="noopener noreferrer">marriott.com</a></div>
                    </div>
                    <p style={{
                        fontSize: '14px',
                        lineHeight: '1.6',
                        color: '#444'
                    }}>
                        Soak up the golden rays and unwind in our secluded oasis, surrounded by the lush scenery of Goa, India.
                        Lounge in private seaside accommodations or revel in electrifying dining and nightlife as sensational
                        music plays through the landscaped grounds of our resort. Find yourself exactly where you should be at
                        W Goa. Our luxury hotel captures the drama and cosmopolitan vibe of Goa, injecting Vagator with the
                        exuberant spirit of W Hotels...
                    </p>
                </div>
                <Footer />
            </div>
        </div>
    );
}

const tabButtonStyle = {
    width: '100px',
    color: '#ffffff',
    padding: '8px 16px',
    border: 'none',
    backgroundColor: '#61b2db',
    borderRadius: '5px',
    cursor: 'pointer'
};


const tabButtonStyle1 = {
    width: '120px',
    padding: '8px 16px',
    border: 'none',
    backgroundColor: '#cccccc', 
    borderRadius: '5px',
    cursor: 'pointer'
};

const infoTextStyle = {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    fontSize: '14px',
    color: '#555'
};

export default CompanyProfile;
