import React from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from '@mui/material';

import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h2: {
      fontSize: '2.5rem',
      color: '#007bff',
      textAlign: 'center',
      marginBottom: '20px',
    },
    h3: {
      fontSize: '1.8rem',
      color: '#333',
      marginTop: '20px',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
      margin: '10px 0',
      color: '#555',
    },
  },
});

const TermsAndConditions = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Box sx={{ bgcolor: '#f9f9f9', p: 3, borderRadius: 2, boxShadow: 2, mt: 3 }}>
          <Typography variant="h2">Terms and Conditions</Typography>
        </Box>

        <Box sx={{ bgcolor: '#fff', p: 3, borderRadius: 2, boxShadow: 2, mt: 3 }}>
          <Typography variant="h3">AGREEMENT TO TERMS</Typography>
          <Typography variant="body1">
          
               These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name](“we,” “us” or “our”), concerning your access to and use of the www.panskillconnect.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).
          
          You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Use. If you do not agree with all of these Terms of Use, then you are expressly prohibited from using the Site and you must discontinue use immediately.
          
          Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason.
          
          We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such chan
          </Typography>

          <Typography>
          
                            
                            It is your responsibility to periodically review these Terms of Use to stay informed of updates.You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.
                            
                            The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
                            
                            Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                            
                      
          </Typography>
          <Typography>
                            Option 1: The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to register for the Site.
                            
                        

                       
          </Typography>
          <Typography>
          Option 2: [The Site is intended for users who are at least 13 years of age.] All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Site. If you are a minor, you must have your parent or guardian read and agree to these Terms of Use prior to you using the Site.

          </Typography>

          <Typography variant="h3">INTELLECTUAL PROPERTY RIGHTS</Typography>
          <Typography variant="body1">
          
                            Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.

                            Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.

                        
          </Typography>

<Typography variant="h3">USER REPRESENTATIONS</Typography>
<Typography variant="body1">
  By using the Site, you represent and warrant that:
</Typography>
<Typography variant="body2">
  - All registration information you submit will be true, accurate, current, and complete.  
  - You will maintain the accuracy of such information and promptly update such registration information as necessary.  
  - You have the legal capacity and you agree to comply with these Terms of Use.  
  - You are not under the age of 13.  
  - You are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Site.  
  - You will not access the Site through automated or non-human means, whether through a bot, script, or otherwise.  
  - You will not use the Site for any illegal or unauthorized purpose.  
  - Your use of the Site will not violate any applicable law or regulation.  
</Typography>

<Typography variant="body1">
  If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
</Typography>

<Typography variant="h3">USER REGISTRATION</Typography>
<Typography variant="body1">
  You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
</Typography>

          

        
  <Typography variant="h3" gutterBottom>
    PROHIBITED ACTIVITIES
  </Typography>
  <Typography variant="body1" gutterBottom>
    You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
  </Typography>
  <Typography variant="body1" gutterBottom>
    As a user of the Site, you agree not to:
  </Typography>
  <Box component="ul" pl={2}>
    <Typography component="li" variant="body1">
      Systematically retrieve data or other content from the Site to create or compile a collection, compilation, database, or directory without written permission.
    </Typography>
    <Typography component="li" variant="body1">
      Make unauthorized use of the Site, including collecting user data for unsolicited emails or creating accounts by automated means.
    </Typography>
    <Typography component="li" variant="body1">
      Use the Site to advertise or sell goods and services without authorization.
    </Typography>
    <Typography component="li" variant="body1">
      Circumvent, disable, or interfere with security features of the Site.
    </Typography>
    <Typography component="li" variant="body1">
      Trick, defraud, or mislead others to access sensitive account information.
    </Typography>
    <Typography component="li" variant="body1">
      Engage in any automated use of the system, such as data mining, bots, or scripts.
    </Typography>
    <Typography component="li" variant="body1">
      Interfere with the operation or functionality of the Site or its connected services.
    </Typography>
    <Typography component="li" variant="body1">
      Use any information obtained from the Site to harass, abuse, or harm others.
    </Typography>
    <Typography component="li" variant="body1">
      Attempt to bypass any measures that restrict access to the Site.
    </Typography>
    <Typography component="li" variant="body1">
      Upload or transmit harmful material such as viruses or excessive spam.
    </Typography>
    <Typography component="li" variant="body1">
      Use the Site in a manner inconsistent with applicable laws or regulations.
    </Typography>
  </Box>

  <Typography variant="h3" gutterBottom>
    USER GENERATED CONTRIBUTIONS
  </Typography>
  <Typography variant="body1" gutterBottom>
    The Site may allow you to create and share content, including text, images, videos, and other materials (“Contributions”). Contributions may be viewable by other users or third-party websites. By sharing Contributions, you represent and warrant that:
  </Typography>
  <Box component="ul" pl={2}>
    <Typography component="li" variant="body1">
      You own the rights to your Contributions or have necessary permissions to share them.
    </Typography>
    <Typography component="li" variant="body1">
      Your Contributions do not infringe on intellectual property, privacy, or proprietary rights.
    </Typography>
    <Typography component="li" variant="body1">
      Your Contributions are not false, misleading, or violate any applicable laws or regulations.
    </Typography>
    <Typography component="li" variant="body1">
      Your Contributions are not offensive, harassing, or inappropriate in any way.
    </Typography>
    <Typography component="li" variant="body1">
      You will not use Contributions to harm or exploit others, particularly minors.
    </Typography>
  </Box>
  <Typography variant="body1" gutterBottom>
    Any use of the Site in violation of these guidelines may result in suspension or termination of your account.
  </Typography>

  <Typography variant="h3" gutterBottom>
    CONTRIBUTION LICENSE
  </Typography>
  <Typography variant="body1" gutterBottom>
    By posting Contributions, you grant us a non-exclusive, royalty-free, worldwide license to use, display, modify, and distribute your Contributions for any purpose, including commercial or advertising purposes. You retain ownership of your Contributions but waive any moral rights related to them. 
  </Typography>
  <Typography variant="body1" gutterBottom>
    We may edit or remove Contributions as necessary and have no obligation to monitor user-provided content.
  </Typography>

  <Typography variant="h3" gutterBottom>
    GUIDELINES FOR REVIEWS
  </Typography>
  <Typography variant="body1" gutterBottom>
    When posting reviews, you agree to:
  </Typography>
  <Box component="ul" pl={2}>
    <Typography component="li" variant="body1">
      Share truthful, firsthand experiences.
    </Typography>
    <Typography component="li" variant="body1">
      Avoid offensive language, discriminatory remarks, or references to illegal activity.
    </Typography>
    <Typography component="li" variant="body1">
      Refrain from posting false, misleading, or promotional content.
    </Typography>
    <Typography component="li" variant="body1">
      Avoid reviewing competitors unfairly or engaging in review campaigns.
    </Typography>
  </Box>
  <Typography variant="body1" gutterBottom>
    We reserve the right to moderate and remove reviews as deemed necessary.
  </Typography>

  <Typography variant="h3" gutterBottom>
    SOCIAL MEDIA
  </Typography>
  <Typography variant="body1" gutterBottom>
    You may link your account with third-party social media accounts to enhance your experience on the Site. By doing so, you grant us permission to access and use certain data from these accounts in accordance with our Terms of Use.
  </Typography>
  <Typography variant="body1" gutterBottom>
    Please note that your relationship with third-party service providers is governed by their terms and conditions. You can disconnect your social media accounts from the Site at any time by contacting us or through account settings.
  </Typography>


            <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        Submissions
                    </Typography>
                    <Typography paragraph>
                        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site (“Submissions”)
                        provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual
                        property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or
                        otherwise, without acknowledgment or compensation to you.
                    </Typography>
                    <Typography paragraph>
                        You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you
                        have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or
                        misappropriation of any proprietary right in your Submissions.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        Advertisers
                    </Typography>
                    <Typography paragraph>
                        We allow advertisers to display their advertisements and other information in certain areas of the Site, such as sidebar advertisements or
                        banner advertisements. If you are an advertiser, you shall take full responsibility for any advertisements you place on the Site and any
                        services provided on the Site or products sold through those advertisements.
                    </Typography>
                    <Typography paragraph>
                        Further, as an advertiser, you warrant and represent that you possess all rights and authority to place advertisements on the Site, including,
                        but not limited to, intellectual property rights, publicity rights, and contractual rights.
                    </Typography>
                    <Typography paragraph>
                        As an advertiser, you agree that such advertisements are subject to our Digital Millennium Copyright Act (“DMCA”) Notice and Policy provisions
                        as described below, and you understand and agree there will be no refund or other compensation for DMCA takedown-related issues. We simply
                        provide the space to place such advertisements, and we have no other relationship with advertisers.
                    </Typography>
                </Box>

                <Box mt={3}>
  <Typography variant="h5" gutterBottom>
    Site Management
  </Typography>
  <Typography paragraph>
    We reserve the right, but not the obligation, to:
  </Typography>

      <Typography>
        Monitor the Site for violations of these Terms of Use.
      </Typography>
    
      <Typography>
        Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities.
      </Typography>
    
      <Typography>
        In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof.
      </Typography>
    
      <Typography>
        In our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems.
      </Typography>
   
      <Typography>
        Otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
      </Typography>
</Box>


                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        Copyright Infringements
                    </Typography>
                    <Typography paragraph>
                        We respect the intellectual property rights of others. If you believe that any material available on or through the Site infringes upon any
                        copyright you own or control, please immediately notify us using the contact information provided below (a “Notification”). A copy of your
                        Notification will be sent to the person who posted or stored the material addressed in the Notification.
                    </Typography>
                    <Typography paragraph>
                        Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification.
                        Thus, if you are not sure that material located on or linked to by the Site infringes your copyright, you should consider first contacting an
                        attorney.
                    </Typography>
                </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TermsAndConditions;
