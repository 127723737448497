import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  // const location = useLocation();
  // const pathnames = location.pathname.split("/").filter((x) => x);

 
  // const routeNames = {
  //   home: "Home",
  //   login: "Login",
  //   list: "Job Listings",
  //   "post-job": "Post Job",
  //   "find-job": "Find Job",
  //   "job-details": "Job Details",
  //   plumber: "Plumber",
  //   welding: "Welding",
  //   mechanic: "Mechanic",
  //   electrician: "Electrician",
  //   viewdetails: "View Details",
  //   profile: "Employer Profile",
  //   resumebuilder: "Resume Builder",
  //   employerdashboard: "Employer Dashboard",
  //   jobdescription: "Job Description",
  // };

  // return (
  //   <nav aria-label="breadcrumb">
  //     <span>
  //       <Link to="/">Home</Link>
  //       {pathnames.map((value, index) => {
  //         const to = `/${pathnames.slice(0, index + 1).join("/")}`;
  //         const isLast = index === pathnames.length - 1;

  //         return isLast ? (
  //           <span key={to}> / {routeNames[value] || value}</span>
  //         ) : (
  //           <span key={to}>
  //             {" "}
  //             / <Link to={to}>{routeNames[value] || value}</Link>
  //           </span>
  //         );
  //       })}
  //     </span>
  //   </nav>
  // );
};

export default Breadcrumbs;
