import React, { useState } from "react";
import logo from '../assets/workaroo_logo.png';
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
import Modal from "../components/Login";
import { FaBriefcase, FaFileAlt, FaMapMarkerAlt, FaChevronDown, FaChevronUp } from "react-icons/fa";
import Pagination from "../components/Pagination";
import { useNavigate } from 'react-router-dom';

const ListPage = () => {
  const [jobTitleFilter, setJobTitleFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState("");
  const [salary, setSalary] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    jobCategories: false,
    location: false,
    jobType: false,
    experienceLevel: false,
  });
  const navigate = useNavigate(); 
  

 

  const toggleDropdown = (section) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
    navigate('/job-details');
  };
  const handleOpenModal1 = () => {
    setIsModalOpen(true);
   
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);}
  const jobData = [
    {
      id: 1,
      title: "Electrician and Enineer",
      experience:"Experience 0-3Yrs",
      company: "Guljag Industries Limited",
      experience:"Experience 0-2 Yrs",
      salary: "₹ 2.25-35 Lacs P.A - $100,000",
      location: "Coimbatore",
      description: "Relavent vocational training or certification in electrical work preferred can...",
      type: "Full Time",
      description2:"Technician analysis, Strong understanding electrical system, Problem solving",
      days:"15 days ago",
    
    },
  

    {
      id: 2,
      title: "Mechanic",
      experience:"Experience 1-2Yrs",
      company: "Electro Green Motor",
      experience:"Experience 0-2 Yrs",
      salary: "₹ Not disclosed",
      location: "Tenkasi",
      description: "Your duties will include monitoring inventory, and inspecting vehicles . To succeed in ...",
      type: "Full Time",
      description2:"Automobile ,Diagnostics, Consulting, Mechanical, Inspection, Customer",
      days:"19 days ago",
    
    },
    {
      id: 3,
      title: "Technicians / Operators (Stamping, Machining, Welding MN ",
      company: "Posted by Adonis Recruitment Constants",
      experience:"Experience 0-3 Yrs",
      salary: "₹ Not disclosed",
      location: "Chennai",
      description: "Looking for 50 TECHNICIANS & OPERATORS who will do PARTS ASSEMBLY, PARTS ERECTION, MATE...",
      type: "Full Time",
      description2:"Operator, Technician, CNC Programming, Laser Machine Operator, Laser...",
      days:"5 days ago",
    },
    {
      id: 4,
      title: "Plumber",
      company: "W Goa",
      experience:"Experience 1-4 Yrs",
      salary: "₹ Not disclosed",
      location: "Bangalore",
      description: " Respond and attend to guest repair requests. Communicate with guests/customers ...",
      type: "Full Time",
      description2:"Housekeeping, Energy conservation, Safety training, Mechanical, ...",
      days:"3 days ago",
    },
    {
      id: 5,
      title: "Plumbers / Technicians",
      company: "Sevense Enterprices",
      experience:"Experience 2-7 Yrs",
      salary: "₹ Not disclosed",
      location: "Bangalore",
      description: "Installation: Install and set up plumbing systems, including pipes, fixtures, and app...",
      type: "Full Time",
      description2:"HVAC, Diagnostics, Appliances, Inspection, Valves, Ventilation, Cost",
      days:"5 days ago",
    },
    {
      id: 6,
      title: "Electrician - Mechanic",
      company: "Makin Developers",
      experience:"Experience 1-3 Yrs",
      salary: "₹ Not disclosed",
      location: "Dehradun",
      description: "Industrial Training Institute) or equivalent certification in electrical tradeRequireme...",
      type: "Full Time",
      description2:"Health insurance, TradeConstruction, Compliance, Commercial buildings",
      days:"14 days ago",
    },
    {
      id: 7,
      title: "Steam/Gas Mechanic",
      company: "Field Core",
      experience:"Experience 1-5 Yrs",
      salary: "₹ Not disclosed",
      location: "Dehradun",
      description: "Must work well both independently and in a team environmentInstall machinery and...",
      type: "Full Time",
      description2:"EHS, Hydraulics, Pumps, Mechanical, Gas turbine, Machinery, Power tools",
      days:"20 days ago",
    },
    {
      id: 8,
      title: "Machin Oprator",
      company: "Product company",
      experience:"Experience 0-3 Yrs",
      salary: "₹ Not disclosed",
      location: "chennai",
      description: "Must work well both independently and in a team environmentInstall machinery and...",
      type: "Full Time",
      description2:"EHS, Hydraulics, Pumps, Mechanical, Gas turbine, Machinery, Power tools",
      days:"30 days ago",
    },
    {
      id: 9,
      title: "Electrician and Mechanic",
      experience:"Experience 0-3Yrs",
      company: "Guljag Industries Limited",
      experience:"Experience 0-2 Yrs",
      salary: "₹ 2.25-35 Lacs P.A - $100,000",
      location: "Coimbatore",
      description: "Relavent vocational training or certification in electrical work preferred can...",
      type: "Full Time",
      description2:"Technician analysis, Strong understanding electrical system, Problem solving",
      days:"15 days ago",
    
    },
  ];

  const filteredJobs = jobData.filter(job => 
    job.title.toLowerCase().includes(jobTitleFilter.toLowerCase()) &&
    job.location.toLowerCase().includes(cityFilter.toLowerCase()) &&
    (jobTypeFilter ? job.type === jobTypeFilter : true)
  );

  return (
    <div className="container" style={{ display: 'flex', flexDirection: 'column' ,backgroundColor:'#fff3f7'}}>
<header className="navbar" style={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', padding: '10px 20px', backgroundColor: '#f8f9fa', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
  {/* Logo */}
  <div className="navbar-logo" style={{ display: 'flex',float: 'left',gap: '800px' }}>
    <Link to="/" style={{ textDecoration: 'none' }}>
      <img src={logo} alt="Logo" style={{ height: '30px', marginRight: '10px' }} />
    </Link>
    <nav className="navbar-links" style={{ display: 'flex', gap: '20px' }}>
    <Link to="/" style={{ textDecoration: 'none', color: '#007bff', fontWeight: '500', whiteSpace:'nowrap' }}>Find New Job</Link>
    <Link to="/users" style={{ textDecoration: 'none', color: '#007bff', fontWeight: '500' }}>FAQ/Help</Link>
    <span 
      onClick={handleOpenModal1} 
      style={{ cursor: 'pointer', color: '#007bff', fontWeight: '500' }}
    >
      Login
    </span>
  </nav>
  </div>
  
</header>
<header className="navbar" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 20px', backgroundColor: ' #210d25', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', }}>
  {/* Slogan */}
  <div className="navbar-slogan" style={{ alignSelf: 'flex-start', marginBottom: '20px', fontSize: '25px', fontWeight: '600', color: 'white',marginLeft:'70px'}}>
    Find Your Next Job Here
  </div>
  
  {/* Search Bar */}
  <div className="navbar-search" style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        placeholder="Search..."
        style={{
          width: '100%',
          padding: '20px 60px 20px 20px', // Padding to leave space for the search button
          borderRadius: '30px',
          border: '1px solid #ccc',
          fontSize: '16px',
        }}
      />
      {/* Search Button */}
      <button 
        type="button"
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          border: 'none',
          backgroundColor: '#210d25',
          color: '#fff',
          padding: '15px',
          borderRadius: '20px',
          cursor: 'pointer',
          maxWidth:'150px'
        }}
      >
        Search
      </button>
    </div>
  </div>
</header>

<div style={{
  display: 'flex',
  justifyContent: 'space-between',  // Distribute space between the items
  alignItems: 'center',  // Vertically align the items to the center
  padding: '10px',
  height: '50px',  // Adjust the height as needed
}}>
  

  <h3 style={{
    margin: 0, 
    fontSize: '25px',
    marginLeft: '20px',
    marginTop:'20px' , // Left margin to add spacing
  }}>
    Recommended Jobs
  </h3>

 
  <button style={{
    borderRadius: '20px',
    border:'1px solid #333',
    backgroundColor:'transparent',
    color: 'black',
    marginTop:'20px' ,
    cursor: 'pointer',
    padding: '8px 16px', 
    maxWidth:'150px' ,
    fontSize:'10px'
    // Button padding for spacing
  }}>
    Most Recent
  </button>

</div>





<div style={{ display: 'flex', flexGrow: 1 }}>

    {/* Sidebar Filters */}
    <div 
  className="sidebar1" 
  style={{ 
    width: '10%', // Set the sidebar width to 20%
    height: '100vh',
    padding: '10px',
    fontSize: '12px',
    position: 'sticky', 
    top: 0,
    backgroundColor: "transparent", // Optional: To visually distinguish the sidebar
  }}
>
<div className="filter-section">
      <h3 className="sidebar-title">Filters</h3>
      {/* Location - Select Dropdown */}
      <div style={{ marginBottom: '10px' }}>
        <select 
          onChange={(e) => setCityFilter(e.target.value)} 
          className="filter-dropdown"
          style={{
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '100%',
            padding: '8px',
          }}
        >
        <option value="">Location</option>
          <option value="Coimbatore">Coimbatore</option>
          <option value="Tenkasi">Tenkasi</option>
          <option value="Chennai">Chennai</option>
          <option value="Bangalore">Bangalore</option>
          <option value="Dehradun">Dehradun</option>
        </select>
      </div>

    {/* Job Type - Radio Buttons */}
    <h4 className="sidebar-title">Job Type</h4>
    <div style={{ marginBottom: '15px' }}>
      <div className="radio-group">
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="job-type" 
            value="Full-time"
            onChange={() => setJobTypeFilter('Full-time')}
            className="square-radio"
          /> 
          Full-time
        </label>
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="job-type" 
            value="Part-time"
            onChange={() => setJobTypeFilter('Part-time')}
            className="square-radio"
          /> 
          Part-time
        </label>
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="job-type" 
            value="Contract"
            onChange={() => setJobTypeFilter('Contract')}
            className="square-radio"
          /> 
          Contract
        </label>
      </div>
    </div>

    {/* Experience Level - Radio Buttons */}
    <h4 className="sidebar-title">Experience Level</h4>
    <div style={{ marginBottom: '15px' }}>
      <div className="radio-group">
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="experience-level" 
            value="Fresher"
            onChange={() => setJobTypeFilter('Fresher')}
            className="square-radio"
          /> 
          Fresher
        </label>
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="experience-level" 
            value="Intermediate"
            onChange={() => setJobTypeFilter('Intermediate')}
            className="square-radio"
          /> 
          Intermediate
        </label>
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="experience-level" 
            value="Expert"
            onChange={() => setJobTypeFilter('Expert')}
            className="square-radio"
          /> 
          Expert
        </label>
        <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input 
            type="radio" 
            name="experience-level" 
            value="No Experience"
            onChange={() => setJobTypeFilter('No Experience')}
            className="square-radio"
          /> 
          No Experience
        </label>
      </div>
    </div>
  </div>

 
  </div>




{/* Main Content */}
<div className="content" style={{ width: '90%', padding: '0 20px' }}>
    <div className="joblist">
      {filteredJobs.length > 0 ? (
        filteredJobs.map((job) => (
          <div key={job.id} className="jobcard">
            <div className="jobcard-content">
              <h4 className="job-title">{job.title}</h4>
              <p className="job-company">{job.company}</p>
              <p className="job-description2"><FaBriefcase /> {job.experience}</p>
              <p className="job-salary">{job.salary}</p>
              <p className="job-location"><FaMapMarkerAlt /> {job.location}</p>
              <p className="job-description2"><FaFileAlt /> {job.description}</p>
              <p className="job-days">{job.days}</p>
            </div>
            <div className="job-actions">
              <button className="save-btn1">Save</button>
              <span className="job-link" onClick={handleOpenModal}>View Details</span>
            </div>
          </div>
        ))
      ) : (
        <p>No jobs available</p>
      )}
    </div>
  </div>
</div>
</div>

  );
};

export default ListPage;
