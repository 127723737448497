import React, { useState,useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid
} from "@mui/material";
import toast from "react-hot-toast";
// import Grid from "@mui/material/Unstable_Grid2";

const SubscriptionPacks = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [subscriptionData,setSubscriptionData] = useState("")
  // const [loading, setLoading] = useState(true);
  // const [subscriptionData, setSubscriptionData] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const userId = sessionStorage.getItem("userId");
  const userPlanId = localStorage.getItem("planId")

const subscriptionPlans = [
    {
      "planId": "67727b4f28653946487401da",
      "planName": "gold",
      "validityInDays": 365,
      "jobCredits": 15,
      "databaseCredits": 1500,
      // "price": 12600,
      "price": 20,
      "additionalFeatures": {},
      "isDeleted": false,
      "createdAt": "2024-12-30T12:00:00.000Z",
      "updatedAt": "2024-12-30T12:00:00.000Z"
    },
    {
      "planId": "67727be228653946487401db",
      "planName": "silverplus",
      "validityInDays": 180,
      "jobCredits": 7,
      "databaseCredits": 600,
      // "price": 9800,
      "price": 15,
      "additionalFeatures": {},
      "isDeleted": false,
      "createdAt": "2024-12-30T12:00:00.000Z",
      "updatedAt": "2024-12-30T12:00:00.000Z"
    },
    {
      "planId": "67727cca28653946487401dc",
      "planName": "silver",
      "validityInDays": 90,
      "jobCredits": 4,
      "databaseCredits": 300,
      // "price": 4900,
      "price": 10,
      "additionalFeatures": {},
      "isDeleted": false,
      "createdAt": "2024-12-30T12:00:00.000Z",
      "updatedAt": "2024-12-30T12:00:00.000Z"
    },
    {
      "planId": "67727d0328653946487401dd",
      "planName": "basic",
      "validityInDays": 30,
      "jobCredits": 2,
      "databaseCredits": 100,
      // "price": 2500,
      "price": 1,
      "additionalFeatures": {},
      "isDeleted": false,
      "createdAt": "2024-12-30T12:00:00.000Z",
      "updatedAt": "2024-12-30T12:00:00.000Z"
    }
  ]

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiUrl}/subscription/getEmployerPlan/${userId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log(response);
        if (response.status) {

          console.log("Response Status:", response.status);
          if (response.data && response.data.data) {
          const userData = response.data.data;
          console.log(userData);
          
          // setFormData({
          //   jobTitle: userData.jobTitle || "",
          //   experience: userData.totalExperience || "",
          //   skills: userData.skills || [],
          //   responsibilities: userData.responsibilities || "",
          //   currentCTC: userData.salary || "",
          //   expectCTC: userData.expectedSalary || "",
          //   coverLetter: "",
          // });
        } else {
          console.error("Invalid data format in response.");
        }
      } else {
        console.error(`Unexpected status code: ${response.status}`);
      }
      } catch (err) {
        if (err.response) {
          const { status } = err.response;
          if (status === 400) {
            // toast.error("Bad Request: Please check the input data.");
            // alert(status?.data?.errorMsg)
            // toast.error(err?.response?.data?.errorMsg)
          } else if (status === 401) {
            toast.error("Unauthorized: Please check your authentication token.");
          } else if (status === 404) {
            toast.error("Not Found: Unable to find the requested resource.");
          } else {
            toast.error(`Error ${status}:`, err.response.data.message || err.message);
          }
        } else {
          toast.error("Network error or server not reachable:", err.message);
        }
      }
    };

    fetchProfile();
  }, []);
  
//  const SubscribePlan = (plan)=>{
//   setSelectedPlan(plan)
//   handlePayment(plan.price);
//  }

 const SubscribePlan = (plan) => {
  if (!plan || !plan.price) {
    toast.error("Invalid plan selection!");
    return;
  }
  setSelectedPlan(plan);
  handlePayment(plan.price);
  localStorage.setItem("planId",plan.planId)
};

 const AddUserSubscription1= async (PaymentId)=>{
  try{
    const PaymentData = {
      paymentId: PaymentId,
      userId: userId,
      planId: userPlanId,
    };
    const Response = await axios.post(`${apiUrl}/subscription/addusertosubscription`,PaymentData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const addSubscription = await Response.json();

    if (addSubscription.success) {
      alert("subscription added successfully!");
      toast.success("subscription added successfully!")
    } else {
      alert("subscription add failed!");
      toast.error("subscription add failed!")


    }
  }catch(err){
            if (err.response) {
          const { status } = err.response;
          if (status === 400) {
            // toast.error("Bad Request: Please check the input data.");
            // alert(status?.data?.errorMsg)
            toast.error(err?.response?.data?.errorMsg)
          } else if (status === 401) {
            toast.error("Unauthorized: Please check your authentication token.");
          } else if (status === 404) {
            toast.error("Not Found: Unable to find the requested resource.");
          } else {
            toast.error(`Error ${status}:`, err.response.data.message || err.message);
          }
        } else {
          toast.success("subscription added successfully!",);
        }
  }
  
}


const AddUserSubscription = async (paymentId) => {
  alert("test")
  try {
    const paymentData = {
      paymentId,
      userId,
      planId:userPlanId,
    };

    const response = await axios.post(`${apiUrl}/subscription/addusertosubscription`, paymentData, {
      headers: {
        Authorization: `${token}`,
      },
    });

    
    if (response.data) {
      // toast.success("Subscription added successfully!");
      // toast.success(response?.data?.message);
    } else {
      // toast.error("Failed to add subscription!");
      // toast.success(response?.data?.message);
    }
  } catch (err) {
    if (err.response) {
      // toast.error(err.response.data?.message || "Failed to add subscription!");
    } else {
      // toast.error("Network error. Please try again.");
    }
  }
};



 const handlePayment = async (Amount) => {
  // alert(amount)
  // const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      if (!token) {
        throw new Error('Authentication token is missing');
      }

      const response = await axios.get(`${apiUrl}/subscription`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (Array.isArray(response.data.data)) {
        setSubscriptionData(response.data.data);
      } else {
        throw new Error('Expected data to be an array');
      }
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoading(false);
    }
  // };

  // fetchApplicants();

  // Create order using backend API
  
  const OrderResponse = await fetch(`${apiUrl}/razorpay/create-order`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ amount: Amount, currency: 'INR' }),
    // 'amount': amount, 
    // 'currency':'INR',
  });

  // console.log(response);

  const order = await OrderResponse.json();
  // console.log(order);
  

  // Load Razorpay payment widget
  const options = {
    key: 'rzp_live_q9CXwHp4B3rnrW',  // live id
    // key:'rzp_test_OEGbB0fk6OKxB5',  // test id
    amount: order.amount,
    currency: order.currency,
    // amount: "500",
    // currency: 'INR',
    name: 'Your Company Name',
    description: 'Test Transaction',
    image: 'https://example.com/your_logo',
    order_id: order.id,
    // order_id: "568974523",
    handler: async (response) => {

      toast.success(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
      await AddUserSubscription(response.razorpay_payment_id);
    },
    prefill: {
      name: 'Your Name',
      email: 'your.email@example.com',
      contact: '9999999999',
    },
    notes: {
      address: 'Razorpay Corporate Office',
    },
    theme: {
      color: '#3399cc',
    },
  };

  const rzp = new window.Razorpay(options);
  rzp.open();
};


const handlePayment1 = async (Amount) => {
  try {
    const response = await fetch(`${apiUrl}/razorpay/create-order`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount:Amount, currency: "INR" }),
    });

    const order = await response.json(); // This contains order.id, order.amount, etc.

    // Load Razorpay payment widget
    const options = {
      key: "rzp_live_q9CXwHp4B3rnrW", // Replace with your Razorpay key
      //  key:'rzp_test_OEGbB0fk6OKxB5',  // test id
      amount: order.amount,
      currency: order.currency,
      name: "Arffy",
      description: "Test Transaction",
      image: "https://example.com/your_logo", // Optional logo
      order_id: order.id, // Pass the `order_id` from Razorpay
      handler: async (response) => {
        // `handler` function processes successful payment response
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
        console.log("response",response);
        

        // Send payment details to your backend for verification
        // const verifyResponse = await fetch(`${apiUrl}/razorpay/confirm-payment`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     paymentId: response.razorpay_payment_id,
        //     userId: userId,
        //     planId: selectedPlan?.planId,
        //   }),
        // });

        const PaymentData = {
          paymentId: response.razorpay_payment_id,
          userId: userId,
          planId: selectedPlan.planId,
        };
        const verifyResponse = await axios.post(`${apiUrl}/razorpay/confirm-payment`,PaymentData, {
          headers: {
            Authorization: `${token}`,
          },
      
        });

        const verifyResult = await verifyResponse.json();

        if (verifyResult.success) {
          alert("Payment verified successfully!");
        } else {
          alert("Payment verification failed!");
        }
      },
      prefill: {
        name: "Customer Name",
        email: "customer.email@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);

    // Open the Razorpay payment widget
    rzp.open();

    // If the payment widget is closed without completing payment
    rzp.on("payment.failed", (response) => {
      alert("Payment failed. Please try again.");
    });
  } catch (error) {
    console.error("Error initiating payment:", error.message);
    alert("An error occurred. Please try again.");
  }
};





// const subscribeToPlan = async (planId) => {
//   try {
//     const token = sessionStorage.getItem("accessToken");
//     if (!token) {
//       throw new Error("Authentication token is missing");
//     }

//     // Update subscription plan for the user
//     const response = await axios.post(
//       `${apiUrl}/user/subscribe`,
//       { planId },
//       {
//         headers: {
//           Authorization: token,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     alert("Subscription updated successfully!");
//     setCurrentPlan(response.data.data); // Update the current plan state
//   } catch (err) {
//     console.error(err.message);
//   }
// };

// useEffect(() => {
//   const fetchPlans = async () => {
//     try {
//       const token = sessionStorage.getItem("accessToken");
//       if (!token) {
//         throw new Error("Authentication token is missing");
//       }

//       // Fetch subscription plans
//       const plansResponse = await axios.get(`${apiUrl}/subscription`, {
//         headers: {
//           Authorization: token,
//           "Content-Type": "application/json",
//         },
//       });

//       setSubscriptionData(plansResponse.data.data);

//       // Fetch current user's subscription
//       const userPlanResponse = await axios.get(`${apiUrl}/user/current-plan`, {
//         headers: {
//           Authorization: token,
//           "Content-Type": "application/json",
//         },
//       });

//       setCurrentPlan(userPlanResponse.data.data); // Assuming the response contains the user's current plan
//     } catch (err) {
//       console.error(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   fetchPlans();
// }, [apiUrl]);




  return (

     <Box sx={{ padding: 4, textAlign: "center" }}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ fontSize: "2.05rem !important" }}
      >
        Choose Your Subscription Plan
      </Typography>

      {currentPlan && (
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h6" color="textSecondary">
            Your Current Plan: {currentPlan.planName}
          </Typography>
          <Typography variant="body2">
            Valid Until:{" "}
            {new Date(currentPlan.validUntil).toLocaleDateString()}
          </Typography>
        </Box>
      )}

      {/* Subscription Packs */}
      <Grid container spacing={5} justifyContent="center">
        {subscriptionPlans
        .slice() 
        .reverse()
        .map((plan) => (
          <Grid key={plan.planId} item xs={12} sm={6} md={6}>
            <Card className="subscription-card">
              <CardContent>
                {/* <Typography variant="h6">{plan.planName} Plan</Typography> */}
                <Typography variant="h6">
                  {plan.planName.charAt(0).toUpperCase() + plan.planName.slice(1)} Plan
                </Typography>
                <Typography variant="body2">
                  Job Credits: {plan.jobCredits}
                </Typography>
                <Typography variant="body2">
                  Database Credits: {plan.databaseCredits}
                </Typography>
                <Typography
                  variant="h4"
                  // color={plan.color}
                  className={` title-card price-card-${plan.planName.toLowerCase()}`}
                  // className={` button-${plan.planName.toLowerCase()}`}
                  gutterBottom
                >
                  ₹{plan.price}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Validity {plan.validityInDays} days
                </Typography>
                <Button
                  onClick={() => SubscribePlan(plan)}
                  variant="contained"
                  // color={plan.color}
                  className={` button-${plan.planName.toLowerCase()}`}
                  fullWidth
                >
                  Subscribe
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SubscriptionPacks;
