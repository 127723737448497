import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Table, Form, Spinner, InputGroup, Button } from 'react-bootstrap'; 
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { useLocation } from "react-router-dom";
import { toast } from 'react-hot-toast';
import moment from 'moment';
import '../Styles/jobseekerjoblist.css';
import { PiDotsSixBold } from "react-icons/pi";
import { FaEye } from "react-icons/fa";
import { TiPlus } from "react-icons/ti";
import ReactPaginate from 'react-paginate';  // Importing react-paginate

const ListPage = () => {
  const [jobData, setJobData] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    jobTitle: "",
    jobCategory: "",
    totalExperience: "",
    salary: "",
    location: "" 
  });
  const [allChecked, setAllChecked] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState({});

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0); // react-paginate starts at 0
  const [jobsPerPage, setJobsPerPage] = useState(10); // How many jobs per page

  const [filtersVisible, setFiltersVisible] = useState(false);  // New state to manage visibility of filters

  const location = useLocation();
  const { selectedIds } = location.state || {};
  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("role");
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [jobSeekersData, setJobSeekersData] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setJobData(response.data.data);
        setFilteredJobData(response.data.data);
        setLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };

    setSelectedUserData(selectedIds);
    fetchJobs();
  }, [token, apiUrl, selectedIds]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };
      filterData(updatedFilters);
      return updatedFilters;
    });
  };

  const filterData = (filters) => {
    let filteredData = jobData;

    if (filters.jobTitle) {
      filteredData = filteredData.filter((job) =>
        job.jobTitle?.toLowerCase().includes(filters.jobTitle.toLowerCase())
      );
    }

    if (filters.jobCategory) {
      filteredData = filteredData.filter((job) =>
        job.jobCategory?.toLowerCase().includes(filters.jobCategory.toLowerCase())
      );
    }

    if (filters.totalExperience) {
      filteredData = filteredData.filter((job) =>
        job.totalExperience >= filters.totalExperience
      );
    }

    if (filters.salary) {
      filteredData = filteredData.filter((job) =>
        job.salary >= filters.salary
      );
    }

    if (filters.location) {
      filteredData = filteredData.filter((job) =>
        job.location?.toLowerCase().includes(filters.location.toLowerCase())
      );
    }

    setFilteredJobData(filteredData);
  };

  // Handle page change using react-paginate
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // Determine the range of jobs to display on the current page
  const indexOfLastJob = (currentPage + 1) * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobData.slice(indexOfFirstJob, indexOfLastJob);

  // Handle individual job selection
  const handleCheckboxChange = (jobId) => {
    setSelectedJobs((prevSelectedJobs) => {
      const newSelectedJobs = { ...prevSelectedJobs, [jobId]: !prevSelectedJobs[jobId] };
      
      // Check if all jobs are selected
      setAllChecked(Object.keys(newSelectedJobs).length === filteredJobData.length && 
                   Object.values(newSelectedJobs).every(val => val));

      return newSelectedJobs;
    });
  };

  // Toggle visibility of filters
  const toggleFiltersVisibility = () => {
    setFiltersVisible(prevState => !prevState);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />

      <Container fluid className="content">
        <Row className="g-4">
          <EmployerDashboardSideBar />

          <Col md={10} className="main-content">
            <Row>
              <Col md={12}>
                <h5 className="mb-3 fw-bold mt-5 ">Job Listing</h5>

                <div className="card border-0 rounded-0 mb-3 main-box">
                <Button onClick={toggleFiltersVisibility} className="mb-3 filter-toggle" style={{background:'#170F49',border:'none',width:'200px'}}>
                      {filtersVisible ? "Hide Filters" : "Show Filters"}
                  </Button>

                  <div className="card-body">
                    {/* Filter Toggle Button */}
                   

                    {/* Conditionally Render Filter Rows */}
                    {filtersVisible && (
                      <div>
                        <h6 className="fw-bold " style={{marginBottom:'30px'}}></h6>
                        <Row className="g-2" style={{ marginBottom: '20px' }}>
                          <Col md={3}>
                            <InputGroup className="filter-header-input">
                              <InputGroup.Text className="filter-header-highlighter">Job Title</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="jobTitle"
                                value={filters.jobTitle}
                                onChange={handleFilterChange}
                                placeholder="Search by title"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={3}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Job Category</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="jobCategory"
                                value={filters.jobCategory}
                                onChange={handleFilterChange}
                                placeholder="Search by category"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Experience</InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="totalExperience"
                                value={filters.totalExperience}
                                onChange={handleFilterChange}
                                placeholder="Min experience"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Salary</InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="salary"
                                value={filters.salary}
                                onChange={handleFilterChange}
                                placeholder="Min Salary"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Location</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="location"
                                value={filters.location}
                                onChange={handleFilterChange}
                                placeholder="Location"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <Table hover className="table" style={{marginTop:'30px'}}>
                      <thead className="theads">
                        <tr className="tablehead">
                          <th style={{ width: '80px' }}>
                            <input
                              type="checkbox"
                              checked={allChecked}
                              onChange={() => handleCheckboxChange()}
                              style={{ cursor: 'pointer', accentColor: 'blue' }}
                            />
                          </th>
                          <th>Job Title</th>
                          <th> Location</th>
                          <th>Industry</th>
                          <th>Experience</th>
                          <th>₹ Salary/Month</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentJobs.length > 0 ? (
                          currentJobs.map((job) => (
                            <tr key={job.id} className="tbodys">
                              <td>
                                <div className="check">
                                  <PiDotsSixBold style={{ fontSize: '30px' }} />
                                  <input
                                    type="checkbox"
                                    checked={selectedJobs[job.jobId] || false}
                                    onChange={() => handleCheckboxChange(job.jobId)}
                                    style={{ cursor: 'pointer', accentColor: selectedJobs[job.jobId] ? 'green' : 'red' }}
                                  />
                                </div>
                              </td>
                              <td>
                                <h6 className="fw-1000px" style={{color:'#170F49'}}>{job.jobTitle}</h6>
                              </td>
                              <td>{job.location}</td>
                              <td>{job.jobCategory}</td>
                              <td>{job.totalExperience} years</td>
                              <td>{job.salary ? `₹ ${job.salary}` : "-"}</td>
                              <td>{moment(job.createdAt).format('YYYY-MM-DD')}</td>
                              <td>
                                <Link to={`/viewdetails/${job.jobId}`} className="btn btn-info" style={{ background: 'none', border: 'none' }}>
                                  <FaEye style={{ color: '#170F49' }} />
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No jobs found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      pageCount={Math.ceil(filteredJobData.length / jobsPerPage)}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListPage;
