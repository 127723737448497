import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UuidProvider } from './context/UuidProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <UuidProvider>
    {/* Only render the components, no need to wrap them in BrowserRouter again */}
    <React.Fragment> {/* Instead of <React.StrictMode> */}
    <App />
  </React.Fragment>
   
    {/* <App /> */}
  </UuidProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
