import React, { useState, useEffect, useRef } from "react";
// import { FaGlobe } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import {
  Container,
  Col,
  Row,
  Table,
  Badge,
  Nav,
  Button,
  Navbar,
  Form,
  Modal,
  NavDropdown,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import {
  FaHome,
  FaBriefcase,
  FaUser,
  FaEnvelope,
  FaClipboardList,
  FaUsers,
  FaChartLine,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa"; // Importing phone icon
import logo from "../assets/logo.png";
// import admin from "../assets/admin.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import JobSeekerRegistration from "../components/JobseekerLogin";
import EmployerLogin from "../components/EmployerLogin";
import { useTranslation } from "react-i18next";
import { IoLogOutOutline } from "react-icons/io5";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { MdLeaderboard } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { FaMicroblog } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { capitalize } from "@mui/material";
import '../Styles/EmployerDashboardH.css'

import { IoIosVideocam } from "react-icons/io";

const EmployerDashboardHeader = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleDropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);
  const handleContactUsDropdownToggle = () =>
    setIsContactUsDropdownOpen(!isContactUsDropdownOpen);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showJobseekerPopup, setShowJobseekerPopup] = useState(false);
  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isContactUsDropdownOpen, setIsContactUsDropdownOpen] = useState(false);
  const role = sessionStorage.getItem("role");
  const userName = sessionStorage.getItem("userName");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const languages = [
    { value: "en", label: "ENGLISH" },
    { value: "ta", label: "தமிழ்" },
    { value: "ma", label: "മലയാളം" },
    { value: "kn", label: "ಕನ್ನಡ" },
    { value: "hi", label: "हिंदी" },
    { value: "te", label: "తెలుగు" },
  ];

  const handleSelect = (value) => {
    handleChange({ target: { value } }); // Simulate select's onChange event
    setIsOpen(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [userRoles, setUserRoles] = useState("");
  const Language = localStorage.getItem("lang");

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const contactdropdownRef = useRef(null);

  // // Toggle dropdown on button click
  // const handleDropdownToggle = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contactdropdownRef.current &&
        !contactdropdownRef.current.contains(event.target)
      ) {
        setIsContactUsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(e.target.value);
    // setSelectedLanguage("");

    // alert(e.target.value);
    // i18n.changeLanguage(lang); // Change the language in i18n
  };

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
      console.log("Token exists:", token);
    } else {
      setIsAuthenticated(false);
      console.log("Token does not exist");
    }
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const openJobseekerPopup = () => {
    setShowJobseekerPopup(true);
  };

  const closeJobseekerPopup = () => {
    setShowJobseekerPopup(false);
  };

  const openEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(true);
  };

  const closeEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
    setIsLoggedIn(false);
    setShowLogoutConfirmation(false);
    // props.handleLogout()
    window.location.href = "/";
  };

  const handleLogoutConfirmation = () => {
    setShowLogoutConfirmation(true);
  };

  const JobSeekerContactForm = () => {
    navigate("/contact", { state: { Roles: "jobseeker" } });
    setUserRoles("jobseeker");
  };

  const EmployerContactForm = () => {
    navigate("/contact", { state: { Roles: "employer" } });
    setUserRoles("employer");
  };

  // const JobSeekerContactForm =()=>{
  //     navigate('/contact',{state:{Roles:"jobseeker"}})
  //     setUserRoles("jobseeker")
  // }
  // const EmployerContactForm =()=>{
  //   navigate('/contact',{state:{Roles:"employer"}})
  //   setUserRoles("employer")

  // }

  return (
    <>
      <Navbar
        className={`custom-navbar ${isLoggedIn ? "shadow-navbar" : ""}`}
        sticky="top"
        expand="lg"
        bg="white"
        variant="light d-fixed"
      >
        {/* <Container fluid>
      <Navbar.Brand as={Link} to="/">
            <img src={logo} loading="lazy" alt="" width={200} />
          </Navbar.Brand>
          <>
             <a href="javascript:;" onClick={openJobseekerPopup} className="small-btn me-3">{t('Jobseeker Login')}</a>
             <JobSeekerRegistration showJobseekerPopup={showJobseekerPopup}
                                    hideJobseekerPopup={closeJobseekerPopup}/>
             <a href="javascript:;" onClick={openEmployerLoginPopup} className="small-btn fill me-3">{t('Employer Login')}</a>
             <EmployerLogin showEmployerLoginPopup={showEmployerLoginPopup}
                            closeEmployerLoginPopup={closeEmployerLoginPopup}/>


           </>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          



<Nav.Item className="custom-phone-wrapper">
  <div className="phone-contact">
    <a href="tel:+919868956895" style={{width:"155px",margin:"0px"}} className="custom-phone-contact">
      <FaPhoneAlt className="" />+91 9868956895
    </a>
    <span className="">/</span>
    <a href="tel:+918956758699" style={{width:"155px",margin:"0px"}}  className="custom-phone-contact">
    +91 8956758699
    </a>
  </div>
  <div className="phone-contact">
    <a href="tel:+919868956895" style={{width:"155px",margin:"0px"}}  className="custom-phone-contact">
      <FaWhatsapp className="me-2" />+91 9868956895
    </a>
    <span className="divider">/</span>
    <a href="tel:+918956758699" style={{width:"155px",margin:"0px"}}  className="custom-phone-contact">
      +91 8956758699
    </a>
  </div>
</Nav.Item>
          <Nav.Item>
            <a href="/Contact" className="custom-contact-link me-3">{t('Contact Us')}</a>
          </Nav.Item>
          

{!isLoggedIn ? (
             <>
             <a href="javascript:;" onClick={openJobseekerPopup} className="small-btn me-3">{t('Jobseeker Login')}</a>
             <JobSeekerRegistration showJobseekerPopup={showJobseekerPopup}
                                    hideJobseekerPopup={closeJobseekerPopup}/>
             <a href="javascript:;" onClick={openEmployerLoginPopup} className="small-btn fill me-3">{t('Employer Login')}</a>
             <EmployerLogin showEmployerLoginPopup={showEmployerLoginPopup}
                            closeEmployerLoginPopup={closeEmployerLoginPopup}/>


           </>
          ) :(<></>)}

           <div className="lg-dropdown" style={{ width: "100px", margin: "0 auto" }}>
             <select
               className="lg-select causes"
               id="select_lang"
               value={Language}
               onChange={handleChange}
               style={{
                 width: "100%",
                 padding: "8px",
                 borderRadius: "4px",
                 border: "1px solid #ccc",
                 backgroundColor: "#f9f9f9",
               }}
             >
               <option value="en">ENGLISH</option>
               <option value="ta">தமிழ்</option>
               <option value="ma">മലയാളം</option>
               <option value="kn">ಕನ್ನಡ</option>
               <option value="hi">हिंदी</option>
       
             </select>
           </div>

      //     {!isLoggedIn ? (
      //        <>
      //        <a href="javascript:;" onClick={openJobseekerPopup} className="small-btn me-3">{t('Jobseeker Login')}</a>
      //        <JobSeekerRegistration showJobseekerPopup={showJobseekerPopup}
      //                               hideJobseekerPopup={closeJobseekerPopup}/>
      //        <a href="javascript:;" onClick={openEmployerLoginPopup} className="small-btn fill me-3">{t('Employer Login')}</a>
      //        <EmployerLogin showEmployerLoginPopup={showEmployerLoginPopup}
      //                       closeEmployerLoginPopup={closeEmployerLoginPopup}/>


      //      </>
      //     ) : (
      //       <div className="dropdown" style={{ position: 'relative',marginLeft:"10px" }} ref={dropdownRef}>
      //         <button
      //           className="btn btn-link p-0 dropdown-toggle-btn"
      //           type="button"
      //           id="dropdownMenuButton"
      //           aria-expanded={isDropdownOpen ? 'true' : 'false'}
      //           onClick={handleDropdownToggle}
      //         >
      //           <img src={admin} loading="lazy" alt="" width={40} className="dropdown-img border rounded-circle" />
      //         </button>
      //         <ul
      //           className={`dropdown-menu dropdown-menu-end ${isDropdownOpen ? 'show' : ''}`}
      //           aria-labelledby="dropdownMenuButton"
      //           style={{
      //             position: 'absolute',
      //             top: '50px',
      //             right: '0',
      //             zIndex: '1000',
      //           }}
      //         >
      //           {role === 'employer' || role === 'employee' ? (
      //             <>
      //             <li>
      //               <a href="/employerProfileView" className="dropdown-item">
      //                 Profile
      //               </a>
      //             </li>
      //             <li>
      //               <a href="/dashboard" className="dropdown-item">
      //                 Dashboard
      //               </a>
      //             </li>
      //           </>
      //           ) : null}
      //           <li>
      //             <a
      //               href="javascript:;"
      //               onClick={handleLogoutConfirmation}
      //               className="dropdown-item"
      //             >
      //               {t('Logout')}
      //             </a>
      //           </li>
      //         </ul>
      //       </div>
      //     )}
      //   </Nav>
      // </Navbar.Collapse>
      
    </Container> */}
        <Container fluid className="bg-white">
          <Navbar.Brand as={Link} to="/">
            <img src={logo} loading="lazy" alt="" width={200} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
          <Nav
              className="me-auto mb-2 mb-lg-0"
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
              <Nav.Link as={Link} to="/" className="active" style={{ fontSize: "16px", marginLeft: "10px" }}>
                {t('Home')}
              </Nav.Link>

              <NavDropdown
                title="Company"
                id="company-nav-dropdown"
                style={{ fontSize: "16px", marginLeft: "10px" }}
             
              >
                <NavDropdown.Item href="/ledership" className="navdrop"  style={{ fontSize: "14px", color: "black",padding:'10px'}}>
                <MdLeaderboard />  Leadership    
                </NavDropdown.Item>  
                <NavDropdown.Item href="/aboutUs" className="navdrop"  style={{ fontSize: "14px" ,padding:'10px'}}>
                <FiUser />   About Us
                </NavDropdown.Item>
              </NavDropdown>
 
              <NavDropdown
                title="Resources"
                id="resources-nav-dropdown"
                style={{ fontSize: "16px", marginRight: "6px" }}
               
              >
                <NavDropdown.Item href="/BlogPage" style={{ fontSize: "14px",padding:'10px' }} className="navdrop">
                <FaMicroblog /> Blog
                </NavDropdown.Item>

                <NavDropdown.Item href="/VideoPage" style={{ fontSize: "14px",padding:'10px' }} className="navdrop">
                <IoIosVideocam /> Videos
                </NavDropdown.Item>

                <NavDropdown.Item href="#Faq" style={{ fontSize: "14px",padding:'10px' }} className="navdrop">
                <FaQuestionCircle /> FAQ
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>


          </Navbar.Collapse>
          {/* Login Buttons - Always Visible */}
          <div className="login-buttons-min">
            {/* <a
              href="javascript:;"
              onClick={openJobseekerPopup}
              className="small-btn me-3"
            >
              {t("Jobseeker Login")}
            </a> */}
            <JobSeekerRegistration
              showJobseekerPopup={showJobseekerPopup}
              hideJobseekerPopup={closeJobseekerPopup}
            />
            {/* <a
              href="javascript:;"
              onClick={openEmployerLoginPopup}
              className="small-btn fill me-3"
            >
              {t("Employer Login")}
            </a> */}
            <EmployerLogin
              showEmployerLoginPopup={showEmployerLoginPopup}
              closeEmployerLoginPopup={closeEmployerLoginPopup}
            />
          </div>

          <Navbar.Collapse className="justify-content-end">
            <Nav>
              {/* <Nav.Item className="custom-phone-wrapper"> */}
            
              <div
  className="contact-card"
  style={{
    textAlign: "center",
    display: "flex",
    flexDirection: "column", 
    padding: "15px", 
  }}
>
  {/* Display the Phone Number */}
  <p
    className="contact-number"
    style={{
      fontSize: "18px",
      fontWeight: "bold",
      color: "black",
      marginBottom: "3px",
    }}
  >
    9606997985
  </p>

  {/* Links for WhatsApp and Phone Call */}
  <div
    className="contact-actions"
    style={{
      alignItems: "center",
      gap: "0px",
    }}
  >

    <a
      href="https://wa.me/9606997985"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsappLink btn-link"
      style={{
        fontSize: "10px",
        color: "green",
        textDecoration: "none",
        fontWeight: "500", // Added for better appearance
      }}
    >
      <WhatsAppIcon
                  // style={{
                  //   fontSize: "1.5rem",
                  //   color: "black",
                  //   marginRight: "15px",
                  //   fontSize:'32px'
                  // }}
                  className="whatsappIcon"
                />
     
      WhatsApp/
    </a>
    <a
      href="tel:+919606997985"
      className="phoneLink btn-link"
      style={{
        fontSize: "10px",
        color: "blue",
        textDecoration: "none",
        fontWeight: "500", // Added for better appearance
      }}
    >
        <PhoneInTalkIcon
                  className="phoneCallIcon"
                  // style={{
                  //   fontSize: "1.5rem",
                  //   color: "black",
                  //   marginRight: "15px",
                  //   fontSize:'32px'
                  // }}
                />
      Call
    </a>
  </div>
</div>

<div
  className="contact-card"
  style={{
    textAlign: "center",
    display: "flex",
    flexDirection: "column", 
    padding: "15px", 
  }}
>
  {/* Display the Phone Number */}
  <p
    className="contact-number"
    style={{
      fontSize: "18px",
      fontWeight: "bold",
      color: "black",
      marginBottom: "3px",
    }}
  >  
    9606997986
  </p>

  {/* Links for WhatsApp and Phone Call */}
  <div
    className="contact-actions"
    style={{
      alignItems: "center",
      gap: "0px",
    }}
  >
     <WhatsAppIcon
                  // style={{ 
                  //   fontSize: "1.5rem",
                  //   color: "black",
                  //   marginRight: "15px",
                  //   fontSize:'32px'
                  // }}
                  className="whatsappIcon"
                />
    <a
      href="https://wa.me/9606997986"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsappLink btn-link"
      style={{
        fontSize: "10px",
        color: "green",
        textDecoration: "none",
        fontWeight: "500",
        maxWidth: "50px", // Added for better appearance
      }}
    >
      WhatsApp/
    </a>
    <a
      href="tel:+919606997986"
      className="phoneLink btn-link"
      style={{
        fontSize: "10px",
        color: "blue",
        textDecoration: "none",
        fontWeight: "500", // Added for better appearance
      }}
    >

      <PhoneInTalkIcon
                  className="phoneCallIcon"
                  // style={{
                  //   fontSize: "1.5rem",
                  //   color: "black",
                  //   marginRight: "15px",
                  //   fontSize:'32px'
                  // }}
                />
      Call
    </a>
  </div>
</div>


              {/* <div
                className="dropdown"
                style={{ position: "relative", marginLeft: "10px" }}
                ref={contactdropdownRef}
              >
                <button
                  className="btn-link btn p-0 dropdown-toggle-btn contact-btn"
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded={isContactUsDropdownOpen ? "true" : "false"}
                  onClick={handleContactUsDropdownToggle}
                >
                  {t("Contact Us")}
                </button>
                <ul
                  className={`dropdown-menu dropdown-menu-end ${
                    isContactUsDropdownOpen ? "show" : ""
                  }`}
                  aria-labelledby="dropdownMenuButton"
                  style={{
                    position: "absolute",
                    top: "50px",
                    right: "0",
                    zIndex: "1000",
                  }}
                >
                  <li>
                    <a
                      href=""
                      onClick={() => JobSeekerContactForm()}
                      className="dropdown-item"
                    >
                      Job Seeker
                    </a>
                  </li>


                  <li>
                    <a
                      href=""
                      onClick={() => EmployerContactForm()}
                      className="dropdown-item"
                    >
                      Employer
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* <Nav.Item>
                <a href="/Contact" className="custom-contact-link me-3">
                  {t("Contact Us")}
                </a>
              </Nav.Item> */}


              {isLoggedIn ? (
                <>
                  {/* <Nav.Item>
                    <div className="login-buttons">
                      <a
                        href="javascript:;"
                        onClick={handleLogoutConfirmation}
                        className="logout-btn small-btn  me-3"
                        style={{marginBottom:'5px'}}
                      >
                        <Button variant="danger"> 
                       {t("Logout")} 

                        </Button>
                      </a>
                    </div>
                  </Nav.Item> */}
                </>
              ) : (
                <>
                  <Nav.Item>
                    <div className="login-buttons" >
                      <a
                        href="javascript:;"
                        onClick={openJobseekerPopup}
                        className="small-btn me-3"
                      >
                        {t("Jobseeker Login")}
                      </a>
                      <JobSeekerRegistration
                        showJobseekerPopup={showJobseekerPopup}
                        hideJobseekerPopup={closeJobseekerPopup}
                      />
                      <a
                        href="javascript:;"
                        onClick={openEmployerLoginPopup}
                        className="small-btn fill me-3"
                      >
                        {t("Employer Login")}
                      </a>
                      <EmployerLogin
                        showEmployerLoginPopup={showEmployerLoginPopup}
                        closeEmployerLoginPopup={closeEmployerLoginPopup}
                      />
                    </div>
                  </Nav.Item>
                </>
              )}

<div className="" style={{ border: 'none',height:'60px'}}>
  {/* Dropdown Header */}
  <div 
    className="custom-dropdown-header"
    onClick={() => setIsOpen(!isOpen)}
  >  
    <span className="dropdown-icon-globe">
      <AiOutlineGlobal className="fs-1" />
    </span>
    <span className="dropdown-label">
      {languages.find((lang) => lang.value === Language)?.label || "Select Language"}
    </span>
    <span className={`dropdown-icon ${isOpen ? "open" : ""}`}>▼</span>
  </div>

  {isOpen && (
    <div className="custom-dropdown-menu">
      {languages.map((lang) => (
        <div
          key={lang.value}
          className={`custom-dropdown-item ${lang.value === Language ? "selected" : ""}`}
          onClick={() => handleSelect(lang.value)}
        >
          {lang.label}
        </div>
      ))}
    </div>
  )}
</div>


{!isLoggedIn ? (
                <></>
              ) : (
                <div style={{position:'relative',marginLeft:'40px',width:'140px',marginTop:'5px'}}>
                  
                  <div className='namedot'>
                            <h1 className=" fs-5" style={{textTransform:'uppercase' }}> {userName.slice(0,2)} </h1>  
                </div>
                <NavDropdown className="dropdowns namesfirst  fs-5"
                title={(userName)}
                id="company-nav-dropdown" 
                style={{ fontSize: "16px", marginLeft: "25px",marginTop:'7px'}}
              >   
              <div className="navdrop-item">
                <NavDropdown.Item href="/ledership" className="navdrop"  style={{ fontSize: "14px", color: "black",padding:'10px'}}>
                <MdLeaderboard /> <div className="drop-namedot" style={{display:'flex'}}> <div className='namedot'>
                <h1 className="jobnames fs-6  text-primary" style={{textTransform:'uppercase'}}> {userName.slice(0,2)} </h1>  
                </div> 
                <h5  style={{marginLeft:'10px',marginTop:'-10px'}} className="fs-5"> {capitalize(userName)}</h5>
                </div>
                </NavDropdown.Item> 
               
                <NavDropdown.Item href="/aboutUs" className="navdrop"  style={{ fontSize: "14px" ,padding:'10px',marginTop:'10px'}}>
                <IoMdSettings className="fs-5"/>   Settings
                </NavDropdown.Item>
                <NavDropdown.Item className="navdrop"  style={{ fontSize: "14px" ,padding:'10px'}}>                     
                <IoLogOutOutline className="fs-5" />

                      <a
                      href="javascript:;"
                       onClick={handleLogoutConfirmation}
                        className="fs-6"
                        style={{marginRight:'-10px'}}
                       >
                          {t("Logout")}
                       </a>
                              </NavDropdown.Item>
                </div>
              </NavDropdown>
              </div>
     )}    

              
              {!isLoggedIn ? (
                <></>
              ) : (
              
                <div
                  className="dropdown"
                  style={{ position: "relative", marginTop: "6px" }}
                  ref={dropdownRef}
                >
                  <button
                    className="btn btn-link p-0 dropdown-toggle-btn"
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded={isDropdownOpen ? "true" : "false"}
                    onClick={handleDropdownToggle}
                  >
                    {/* <img
                      src={admin}
                      loading="lazy"
                      alt=""
                      width={40}
                      className="dropdown-img border rounded-circle"
                    /> */}
                  </button>
                  <ul
                    className={`dropdown-menu dropdown-menu-end ${
                      isDropdownOpen ? "show" : ""
                    }`}
                    aria-labelledby="dropdownMenuButton"
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "0",
                      zIndex: "1000",
                    }}
                  >
                    {role === "employer" || role === "employee" ? (
                      <>
                        <li>
                          <a
                            href="/employerProfileView"
                            className="dropdown-item"
                          >
                            Profile
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard" className="dropdown-item">
                            Dashboard
                          </a>
                        </li>
                      </>
                    ) : null}
                    {role === "jobseeker" ? (
                      <>
                        <li>
                          <a href="/jobseekerprofile" className="dropdown-item">
                            Profile
                          </a>
                        </li>
                      </>
                    ) : null}

                    <li                       
                    >
                      <a
                        href="javascript:;"
                        onClick={handleLogoutConfirmation}
                        className="dropdown-item"
                      >
                        {t("Logout")}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={showLogoutConfirmation}
        onHide={() => setShowLogoutConfirmation(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirm Logout")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Are you sure you want to log out?")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowLogoutConfirmation(false)}
          >      
            {t("Cancel")}
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            {t("Logout")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployerDashboardHeader;








