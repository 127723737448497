import React from 'react'
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import { Height } from '@mui/icons-material';

const Faq = () => {
  return (
    
    <>
          <EmployerDashboardHeader />
          <h1 style={{ height: "100vh",textAlign: "center" }}>FAQ</h1>
          </>
  )
}

export default Faq