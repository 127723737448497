import React from 'react';
import { Box, Typography, Button, Grid, Container } from '@mui/material';
// import { makeStyles } from '@mui/styles';



const NewBlog = () => {
    const classes = {
        root: {
          backgroundColor: '#f4f4f4', // Light gray background
          padding: '4rem 0',
        },
        heroContainer: {
          textAlign: 'center',
          marginBottom: '4rem',
        },
        heroTitle: {
          fontSize: '3rem',
          fontWeight: 'bold',
          color: '#002147', // Dark blue color
        },
        heroSubtitle: {
          fontSize: '1.5rem',
          color: '#666',
          marginBottom: '2rem',
        },
        ctaButton: {
          backgroundColor: '#002147', // Dark blue button
          color: '#fff',
          padding: '1rem 2rem',
          borderRadius: '3rem',
          '&:hover': {
            backgroundColor: '#001a33', // Darker blue on hover
          },
        },
      };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.heroContainer}>
          <Typography variant="h2" className={classes.heroTitle}>
            Find Your Next Great Job with Workaroo
          </Typography>
          <Typography variant="h5" className={classes.heroSubtitle}>
            Connecting skilled tradespeople with rewarding opportunities.
          </Typography>
          <Button variant="contained" className={classes.ctaButton}>
            Find Work Now
          </Button>
        </Box>

        <Grid container spacing={4}>
          {/* Feature 1 */}
          <Grid item xs={12} md={4}>
            <Box textAlign="center" p={3} border={1} borderColor="lightgray" borderRadius={2}>
              <Typography variant="h6">Fast & Easy Application</Typography>
              <Typography variant="body1">
                Apply to jobs in minutes with our simple and intuitive platform.
              </Typography>
            </Box>
          </Grid>
          {/* Feature 2 */}
          <Grid item xs={12} md={4}>
            <Box textAlign="center" p={3} border={1} borderColor="lightgray" borderRadius={2}>
              <Typography variant="h6">Local Job Opportunities</Typography>
              <Typography variant="body1">
                Find jobs in your area and connect with local employers.
              </Typography>
            </Box>
          </Grid>
          {/* Feature 3 */}
          <Grid item xs={12} md={4}>
            <Box textAlign="center" p={3} border={1} borderColor="lightgray" borderRadius={2}>
              <Typography variant="h6">Support & Resources</Typography>
              <Typography variant="body1">
                Get help with your job search and career development.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NewBlog;