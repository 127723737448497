import React, { useState } from 'react';

 
const JobDescriptionForm = () => {
  const [formData, setFormData] = useState({
   
    responsibilities: '',
    skills: [],
    qualification: '',
    experienceFrom: '',
    experienceTo: '',
    currentCTC: '',
    expectedCTC: '',
    jobPosition: '',
    jobType: [],
    hoursPerWeek: ''
  });
  const [skillInput, setSkillInput] = useState('');
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
 
  const handleJobTypeChange = (type) => {
    setFormData((prevState) => ({
      ...prevState,
      jobType: prevState.jobType.includes(type)
        ? prevState.jobType.filter((t) => t !== type)
        : [...prevState.jobType, type]
    }));
  };
 
  const handleAddSkill = () => {
    if (skillInput && !formData.skills.includes(skillInput)) {
      setFormData((prev) => ({
        ...prev,
        skills: [...prev.skills, skillInput]
      }));
      setSkillInput('');
    }
  };
 
  const handleRemoveSkill = (skill) => {
    setFormData((prev) => ({
      ...prev,
      skills: prev.skills.filter((s) => s !== skill)
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };
 
  return (
 
    <form onSubmit={handleSubmit} style={styles.form}>
      <h2 style={styles.title}>Job Description Details</h2>
     
     
      {/* Job Description Details */}
      <section style={styles.section}>
        <h3 style={styles.sectionTitle}>Job Description Details</h3>
        <textarea
          style={{ ...styles.input, height: '80px' }}
          name="responsibilities"
          placeholder="Responsibilities and Duties"
          value={formData.responsibilities}
          onChange={handleChange}
        />
        <div style={styles.grid}>
          <input
            style={styles.input}
            type="text"
            placeholder="Add Skill"
            value={skillInput}
            onChange={(e) => setSkillInput(e.target.value)}
          />
          <button type="button" style={styles.addButton} onClick={handleAddSkill}>Add</button>
        </div>
        <div style={styles.skillsContainer}>
          {formData.skills.map(skill => (
            <span key={skill} style={styles.skillChip} onClick={() => handleRemoveSkill(skill)}>
              {skill} &times;
            </span>
          ))}
        </div>
        <textarea
          style={{ ...styles.input, height: '80px' }}
          name="qualification"
          placeholder="Qualification | Requirements"
          value={formData.qualification}
          onChange={handleChange}
        />
      </section>
 
      {/* Experience and Salary */}
      <section style={styles.section}>
        <div style={styles.grid}>
          <input
            style={styles.input}
            type="text"
            name="experienceFrom"
            placeholder="Experience From (Year)"
            value={formData.experienceFrom}
            onChange={handleChange}
          />
          <input
            style={styles.input}
            type="text"
            name="experienceTo"
            placeholder="Experience To (Year)"
            value={formData.experienceTo}
            onChange={handleChange}
          />
          <input
            style={styles.input}
            type="text"
            name="currentCTC"
            placeholder="Current CTC (PA)"
            value={formData.currentCTC}
            onChange={handleChange}
          />
          <input
            style={styles.input}
            type="text"
            name="expectedCTC"
            placeholder="Expected CTC (PA)"
            value={formData.expectedCTC}
            onChange={handleChange}
          />
        </div>
      </section>
 
 
 
 {/* Job Type and Hours */}
 
<section style={styles.section}>
  <select
    style={styles.input}
    name="jobPosition"
    value={formData.jobPosition}
    onChange={handleChange}
  >
    <option value="" disabled>Select Job Position</option>
    <option value="Plumber">Plumber</option>
    <option value="Welder">Welder</option>
    <option value="Electrician">Electrician</option>
    <option value="Mechanic">Mechanic</option>
    <option value="Add more Categories">Add more Categories</option>
    {/* Add more options as needed */}
  </select>
 
  <div style={styles.jobTypeContainer}>
    {['Full-time', 'Part-time', 'Intern', 'Remote'].map(type => (
      <label key={type} style={styles.checkboxLabel}>
        <input
          type="checkbox"
          placeholder='Job Type'
          checked={formData.jobType.includes(type)}
          onChange={() => handleJobTypeChange(type)}
        />
        {type}
      </label>
    ))}
  </div>
 
  <input
    style={styles.input}
    type="number"
    name="hoursPerWeek"
    placeholder="# of Hours (Hours per Week)"
    value={formData.hoursPerWeek}
    onChange={handleChange}
  />
</section>
 
     
      <div style={styles.buttonContainer}>
        <button type="button" style={styles.secondaryButton}>Add another job</button>
        <button type="submit" style={styles.button}>Save</button>
      </div>
    </form>
  );
};
 
const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '2rem',
    color: '#57BBE5',
    textAlign: 'center',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '0px',
  },
  sectionTitle: {
    fontSize: '1.25rem',
    color: '#57BBE5',
    marginBottom: '10px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    marginBottom: '15px',
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    width: '100%',
    marginBottom:'20px',
     color:'#333',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#57BBE5',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
    width: '20%',
    height:'40px',
  },
  skillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  skillChip: {
    backgroundColor: '#e38b6d',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  jobTypeContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px',
  },
  button: {
    padding: '10px',
    backgroundColor: '#57BBE5',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    fontSize: '1rem',
    cursor: 'pointer',  
  },
  secondaryButton: {
    padding: '10px',
    backgroundColor: '#ccc',
    color: '#333',
    borderRadius: '5px',
    border: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  '@media (min-width: 768px)': {
    grid: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
};
 
export default JobDescriptionForm;
 
 
 
 