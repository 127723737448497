// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footersection{
   
    background-color:#170F49  !important;
}
.footerul li a{
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Footer.css"],"names":[],"mappings":"AAAA;;IAEI,oCAAoC;AACxC;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".footersection{\r\n   \r\n    background-color:#170F49  !important;\r\n}\r\n.footerul li a{\r\n    color: white !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
