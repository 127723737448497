import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { Container, Row, Col, Form, Card } from "react-bootstrap";

function ApplyJob() {
  const [file, setFile] = useState(null); 
  const [preview, setPreview] = useState(null); 
  const [formData, setFormData] = useState({
    jobTitle: "",
    experience: "",
    currentCTC: "",
    skills: [],
    responsibilities: [],
    expectCTC: "",
    coverLetter: "",
  }); 

  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("accessToken");
  const selectedJobId = sessionStorage.getItem("JobId");
  const employerId = sessionStorage.getItem("Postedby");
  const userId = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result); 
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "skills") {
      const skillsArray = value.split(",").map((skill) => skill.trim());
      setFormData({ ...formData, [name]: skillsArray });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const id = sessionStorage.getItem("userId");
        const response = await axios.get(`${apiUrl}/user/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log(response);

        if (response.data && response.data.data) {
          const userData = response.data.data;
          setFormData({
            jobTitle: userData.jobTitle || "",
            experience: userData.totalExperience || "",
            skills: userData.skills || [],
            responsibilities: userData.responsibilities || "",
            currentCTC: userData.salary || "",
            expectCTC: userData.expectedSalary || "",
            coverLetter: "",
          });
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
      }
    };

    fetchProfile();
  }, [token, apiUrl]);

  const handleFileUpload = async () => {
    if (!file) return null;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(`${apiUrl}/shared/file-upload`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setIsUploading(false);

      if (response.status === 200) {
        return response.data.fileName; 
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      setIsUploading(false);
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "File upload failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return null;
    }
  };

  const handleUpdate = async () => {
    try {
      const uploadedFileName = await handleFileUpload();

      if (!uploadedFileName) {
        throw new Error("File upload failed. Please try again.");
      }

      const dataToPost = {
        ...formData,
        resume: uploadedFileName, 
        jobId: selectedJobId,
        jobSeekerId: userId,
        employerId:employerId,
        jobTitle: formData.jobTitle,
        responsibilities: formData.responsibilities,
      };

      const response = await axios.post(`${apiUrl}/applications/apply`, dataToPost, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Your application has been submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
          timer: 2000,
        });

        setTimeout(() => {
          navigate("/JobList");
        }, 2000);
      } else {
        throw new Error("Failed to submit application.");
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: err.response?.data?.message || err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <EmployerDashboardSideBar />
          <Col md={10} className="main-content">
            <Row>
              <Col md={12}>
                <h5 className="mb-3 fw-bold">Apply for Job</h5>
                <Card className="p-4 rounded-3">
                  <Row>
                    <Col md={6}>

                      <Form.Group controlId="jobTitle" className="mb-4">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="jobTitle"
                          placeholder="Job Title"
                          value={formData.jobTitle}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="experience" className="mb-4">
                        <Form.Label>Experience</Form.Label>
                        <Form.Control
                          type="text"
                          name="experience"
                          placeholder="Experience"
                          value={formData.experience}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="skills" className="mb-4">
                        <Form.Label>Skills</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="skills"
                          rows={4}
                          placeholder="Enter skills separated by commas"
                          value={formData.skills.join(", ")}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="responsibilities" className="mb-4">
                        <Form.Label>Responsibilities</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="responsibilities"
                          rows={4}
                          placeholder="Enter Job Responsibilities"
                          value={formData.responsibilities.join(", ")}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="currentCTC" className="mb-4">
                        <Form.Label>Current CTC</Form.Label>
                        <Form.Control
                          type="text"
                          name="currentCTC"
                          placeholder="Current CTC"
                          value={formData.currentCTC}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="expectCTC" className="mb-4">
                        <Form.Label>Expected CTC</Form.Label>
                        <Form.Control
                          type="text"
                          name="expectCTC"
                          placeholder="Expected CTC"
                          value={formData.expectCTC}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="coverLetter" className="mb-4">
                        <Form.Label>Cover Letter</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="coverLetter"
                          rows={6}
                          placeholder="Write your cover letter here..."
                          value={formData.coverLetter}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="resume" className="mb-4">
                        <Form.Label>Upload Resume</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleFileChange}
                        />
                        {preview && (
                          <div className="mt-3">
                            {file?.type.includes("image") ? (
                              <img src={preview} alt="Preview" style={{ width: "100%", height: "auto" }} />
                            ) : file?.type.includes("pdf") ? (
                              <iframe src={preview} title="PDF Preview" style={{ width: "100%", height: "200px" }}></iframe>
                            ) : (
                              <p>File preview not supported</p>
                            )}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Link
                    variant="primary"
                    size="md"
                    className="default-btn default-sub-btn btn-md"
                    onClick={handleUpdate}
                    disabled={isUploading}
                  >
                    {isUploading ? "Uploading..." : "Submit Application"}
                  </Link>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ApplyJob;
