import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Form, Spinner, Button, InputGroup, Image, Modal, Card } from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import toast from "react-hot-toast";

const EmployerProfileView = () => {
  const navigate = useNavigate();
  const Auth = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId")

  useEffect(() => {
    if (!Auth) {
      navigate("/"); 
    }
  }, [Auth, navigate]);

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [employerPackageData,setEmployerPackageData] = useState([])
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    city: "",
    state: "",
    firstName: "",
    lastName: "",
    surName: "",
    designation: "",
    department: "",
    cinNumber: "",
    gstIn: "",
    panNumber: "",
    industryCategory: "",
    industrySubCategory: "",
    branch: "",
    landmark: "",
    pincode: "",
    communicationAdress: "",
    country: "",
    image: "",
    otpCode: "",
    otpExpiryTime: "",
    emailAddress: "",
    address: "",
    pinCode: "",
    aadharNo: "",
    panNo: "",
    voterId: "",
    drivingLicenceNumber: "",
    profileTitle: "",
    totalExperience: "",
    salary: "",
    expectedSalary: "",
    miscDetails: "",
    skills: [],
    qualification: [],
    responsibilities: [],
    dob: "",
    resume:"",
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);  

  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const id = sessionStorage.getItem("userId");

        const response = await axios.get(`${apiUrl}/user/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.data && response.data.data) {
          setUserDetails(response.data.data);
          setFormData(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch profile.");
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError("An error occurred while fetching the profile.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token, apiUrl]);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${apiUrl}/subscription/getEmployerPlan/${userId}`, {
            headers: {
              Authorization: `${token}`,
            },
          });
          console.log(response);
          if (response.status) {
            
            console.log("Response Status:", response.status);
            if (response.data && response.data.data) {
            const userData = response.data.data;
            setEmployerPackageData(userData)
            console.log(userData);
            
            // setFormData({ 
            //   jobTitle: userData.jobTitle || "",
            //   experience: userData.totalExperience || "",
            //   skills: userData.skills || [],
            //   responsibilities: userData.responsibilities || "",
            //   currentCTC: userData.salary || "",
            //   expectCTC: userData.expectedSalary || "",
            //   coverLetter: "",
            // });
          } else {
            console.error("Invalid data format in response.");
          }
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
        } catch (err) {
            //  handleOpen()

          if (err.response) {
            const { status } = err.response;
            if (status === 400) {
              // toast.error("Bad Request: Please check the input data.");
              // alert(status?.data?.errorMsg)
              // toast.error(err?.response?.data?.errorMsg)
            } else if (status === 401) {
              toast.error("Unauthorized: Please check your authentication token.");
            } else if (status === 404) {
              toast.error("Not Found: Unable to find the requested resource.");
            } else {
              toast.error(`Error ${status}:`, err.response.data.message || err.message);
            }
          } else {
            toast.error("Network error or server not reachable:", err.message);
          }
        }
      };
  
      fetchProfile();

  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);  
    window.location.reload();  
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const mandatoryFields = [
    "userName", "email", "phoneNumber","firstName","lastName"
  ];

  const fieldsToRender = [
    { label: "User Name", key: "userName", mandatory: true },
    { label: "First Name", key: "firstName",mandatory: true  },
    { label: "Last Name", key: "lastName",mandatory: true  },
    { label: "Phone Number", key: "phoneNumber", mandatory: true },
    { label: "Email", key: "email", mandatory: true },
    // { label: "Role", key: "role" },
    { label: "Designation", key: "designation"},
    { label: "Department", key: "department" },
    { label: "Branch", key: "branch" },
    { label: "Company Name", key: "companyName" },
    { label: "CIN Number", key: "cinNumber"},
    { label: "GST In", key: "gstIn" },
    { label: "PAN Number", key: "panNumber" },
    { label: "Industry Category", key: "industryCategory"},
    { label: "Industry Sub Category", key: "industrySubCategory"},
    { label: "Company Address", key: "companyAddress"},
    { label: "City", key: "city" },
    { label: "State", key: "state"},
    { label: "Pincode", key: "pincode"},
    // { label: "Landmark", key: "landmark" },
    // { label: "Country", key: "country"},
    // { label: "DOB", key: "dob" },
    // { label: "Aadhar No", key: "aadharNo"},
    // { label: "Voter ID", key: "voterId" },
    // { label: "Driving Licence No", key: "drivingLicenceNumber" },
    // { label: "Profile Title", key: "profileTitle" },
    // { label: "Total Experience", key: "totalExperience"},
    // { label: "Current Salary", key: "salary"},
    // { label: "Expected Salary", key: "expectedSalary" },
    // { label: "Misc Details", key: "miscDetails"},
  ];

  const handleSaveClick = async () => {
    const incompleteMandatoryFields = mandatoryFields.filter(field => !formData[field]);

    if (incompleteMandatoryFields.length > 0) {
      setError(`Please fill out all mandatory fields: ${incompleteMandatoryFields.join(", ")}`);
      return;
    }

    const userId = sessionStorage.getItem("userId");
    const payload = { ...formData, userId };
    try {
      await axios.patch(`${apiUrl}/user`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile.");
    }
  };

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <EmployerDashboardSideBar />

          <Col md={10} className="main-content">
            <Card className="rounded-2 p-4">
              <Card.Body>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <div className="text-center mb-4">
                  <div
                    className="profile-image-wrapper mb-3"
                    onClick={handleImageClick}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      cursor: "pointer",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={formData.image || "https://via.placeholder.com/100"}
                      alt="Profile Picture"
                      roundedCircle
                      className="shadow-lg"
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        border: "4px solid #ffffff",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
                      }}
                    />
                  </div>
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }} 
                    onChange={handleImageChange}
                  />
                </div>

               <div style={{backgroundColor:'#ECF6FF'}}>
                <p className="fs-6" style={{color:'#170F49'}}>Plan Name: {employerPackageData.planName}</p>
                <p className="fs-6" style={{color:'#170F49'}}>Plan StartDate:{employerPackageData.planStartDate}</p>
                <p className="fs-6" style={{color:'#170F49'}}>planEndDate :{employerPackageData.planEndDate}</p>
                <p className="fs-6" style={{color:'#170F49'}}> Price :{employerPackageData.price}</p>
                <p className="fs-6" style={{color:'#170F49'}}> validityInDays :{employerPackageData.validityInDays}</p>
                <h5 className="text-center mb-4" style={{color:'white'}}>Profile Details</h5>
                </div>
                
                <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header style={{backgroundColor:"rgb(47 39 97)",color:"white"}}>Employer Details</Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender.slice(0, 9).map(({ label, key, mandatory }) => (
                            <Col md={6} key={key}>
                              <Form.Group controlId={key} className="mb-3">
                                <Form.Label>{label} {mandatory && <span className="text-danger">*</span>}</Form.Label>
                                <Form.Control
                                  type={key === "dob" ? "date" : "text"}
                                  name={key}
                                  value={formData[key]}
                                  onChange={handleChange}
                                  required={mandatory}
                                  className="rounded-3"
                                />
                              </Form.Group>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header style={{backgroundColor:"rgb(47 39 97)",color:"white"}}>Company Details</Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender.slice(10).map(({ label, key, mandatory }) => (
                            <Col md={6} key={key}>
                              <Form.Group controlId={key} className="mb-3">
                                <Form.Label>{label} {mandatory && <span className="text-danger">*</span>}</Form.Label>
                                <Form.Control
                                  type="text"
                                  name={key}
                                  value={formData[key]}
                                  onChange={handleChange}
                                  required={mandatory}
                                  className="rounded-3"
                                />
                              </Form.Group>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header className="bg-info text-white">Professional Details</Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender.slice(14).map(({ label, key, mandatory }) => (
                            <Col md={6} key={key}>
                              <Form.Group controlId={key} className="mb-3">
                                <Form.Label>{label} {mandatory && <span className="text-danger">*</span>}</Form.Label>
                                <Form.Control
                                  type="text"
                                  name={key}
                                  value={formData[key]}
                                  required={mandatory}
                                  onChange={handleChange}
                                  className="rounded-3"
                                />
                              </Form.Group>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row> */}

                <div className="text-center mt-4">
                  <Button
                    onClick={handleSaveClick}
                    className="default-btn px-5 py-2"
                    disabled={mandatoryFields.some(field => !formData[field])}
                  >
                    Save
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully updated!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmployerProfileView;
