import { Col, Nav } from "react-bootstrap";
import {useNavigate } from "react-router-dom"
import {
  FaHome,
  FaBriefcase,
  FaUser,
  FaEnvelope,
  FaUsers,
} from "react-icons/fa";

const AdminDashboard = () => {
  const userRole = sessionStorage.getItem("role");

  const navigate = useNavigate()

    const renderSidebarContent = () => {
      switch (userRole) {
        case "user":
          return (
            <Nav className="flex-column">
              <div className="sidebar-item">
              <Nav.Link href="/dashboard" className="sidebar-item">

                <FaHome size={20} /> Home
                </Nav.Link>
              </div>
              <div className="sidebar-item">
               <Nav.Link
                  href="/applicants" className="sidebar-item"
                >
                <FaUser size={20} /> Applicants
               </Nav.Link>
              </div>
              <div className="sidebar-item">
                <FaBriefcase size={20} /> Post Job
              </div>
              <div className="sidebar-item">
                <FaEnvelope size={20} /> Job Descriptions
              </div>
              <div className="sidebar-item">
                <FaUsers size={20} /> All Candidates
              </div>
              <div className="sidebar-item">
                <FaEnvelope size={20} /> Support
              </div>
            </Nav>
          );
        case "admin":
          return (
            <Nav className="flex-column">
              <div className="sidebar-item">
                <Nav.Link
                  href="/admin-dashboard"
                  className="text-decoration-none text-dark"
                >
                  <FaHome size={20} /> Dashboard
                </Nav.Link>
              </div>
              <div className="sidebar-item">
               <Nav.Link
                  href="/employerTable" 
                   className="text-decoration-none text-dark"
                >
                <FaUser size={20} /> Employer
               </Nav.Link>
              </div>
               {/* <div className="sidebar-item">
                <Nav.Link href="/employerCreateProfile" className="text-decoration-none text-dark">
                  <FaBriefcase size={20} /> Employer add
                </Nav.Link>
              </div> */}
              <div className="sidebar-item">
                <Nav.Link
                  href="/jobSeekerTable"
                  className="text-decoration-none text-dark"
                >
                  <FaUser size={20} /> Jobseeker
                </Nav.Link>
              </div>
              <div className="sidebar-item">
                <Nav.Link href="#" className="text-decoration-none text-dark">
                  <FaUser size={20} /> Reports
                </Nav.Link>
              </div>
              <div className="sidebar-item">
                <Nav.Link href="#" className="text-decoration-none text-dark">
                  <FaEnvelope size={20} /> Support
                </Nav.Link>
              </div>
            </Nav>
          );
        case "manager":
          return (
            <Nav className="flex-column">
              <div className="sidebar-item">
              <Nav.Link href="/" className="text-decoration-none text-dark">
                <FaHome size={20} /> Home
              </Nav.Link>  
              </div>
              <div className="sidebar-item">
                <FaUser size={20} /> Applicants
              </div>
              <div className="sidebar-item">
                <FaBriefcase size={20} /> Post Job
              </div>
              <div className="sidebar-item">
                <FaEnvelope size={20} /> Job Descriptions
              </div>
              <div className="sidebar-item">
                <FaUsers size={20} /> All Candidates
              </div>
              <div className="sidebar-item">
                <FaEnvelope size={20} /> Support
              </div>
            </Nav>
          );
          case "callcenter":
            return (
              <Nav className="flex-column">
              <div className="sidebar-item">
                <Nav.Link
                  href="/admin-dashboard"
                  className="text-decoration-none text-dark"
                >
                  <FaHome size={20} /> Dashboard
                </Nav.Link>
              </div>
              {/* <div className="sidebar-item">
               <Nav.Link
                  href="/employerTable" 
                   className="text-decoration-none text-dark"
                >
                <FaUser size={20} /> Employer
               </Nav.Link>
              </div> */}
              <div className="sidebar-item">
                <Nav.Link
                  href="/jobSeekerTable"
                  className="text-decoration-none text-dark"
                >
                  <FaUser size={20} /> Jobseeker
                </Nav.Link>
              </div>
              <div className="sidebar-item">
                <Nav.Link href="#" className="text-decoration-none text-dark">
                  <FaUser size={20} /> Jobs
                </Nav.Link>
              </div>
              <div className="sidebar-item">
                <Nav.Link href="#" className="text-decoration-none text-dark">
                  <FaUser size={20} /> Reports
                </Nav.Link>
              </div>
              <div className="sidebar-item">
                <Nav.Link href="#" className="text-decoration-none text-dark">
                  <FaEnvelope size={20} /> Support
                </Nav.Link>
              </div>
            </Nav>
            );  
        default:
          return (
            <Nav className="flex-column">
              <div className="sidebar-item">
                <FaHome size={20} /> Default Item
              </div>
            </Nav>
          );
      }
    };
  
  
  

  return (
    <>

     <Col md={2} className="sidebar">
        <div className="mb-4">
          <span className="category-name">Dashboard</span>
        </div>
        {renderSidebarContent()}
      </Col>
      {/* {userRole === "employer" ? (
        <Col md={2} className="sidebar">
          <div className="mb-4">
            <span className="category-name">Dashboard</span>
          </div>
          <Nav className="flex-column">
            <div className="sidebar-item">
              <FaHome size={20} /> Home
            </div>
            <div className="sidebar-item">
              <FaUser size={20} /> Applicants
            </div>
            <div className="sidebar-item">
              <FaBriefcase size={20} /> Post Job
            </div>
            <div className="sidebar-item">
              <FaEnvelope size={20} /> Job Descriptions
            </div>
            <div className="sidebar-item">
              <FaUsers size={20} /> All Candidates
            </div>
            <div className="sidebar-item">
              <FaEnvelope size={20} /> Support
            </div>
          </Nav>
        </Col>
      ) : (
        <Col md={2} className="sidebar">
          <div className="mb-4">
            <span className="category-name">Dashboard</span>
          </div>
          <Nav className="flex-column">
            <div className="sidebar-item">
              <Nav.Link href="/admin/dashboard" className="text-decoration-none text-dark">
                <FaHome size={20} /> Dashboard
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link
                href="/callcenter/jobseekerform"
                className="text-decoration-none text-dark"
              >
                <FaUser size={20} /> Add Job Seeker
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="#" className="text-decoration-none text-dark">
                <FaBriefcase size={20} /> Employer
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="#" className="text-decoration-none text-dark">
                <FaUser size={20} /> Reports
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="#" className="text-decoration-none text-dark">
                <FaEnvelope size={20} /> Support
              </Nav.Link>
            </div>
          </Nav>
        </Col>
      )} */}
    </>
  );
};

export default AdminDashboard;
