// import React from "react";
// import "./assets/styles/main.css";
// import Routing from "./routes/routing";
// import './i18n';

// const App = () => {
//   return <Routing />;
// };

// export default App;


import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from "./components/Breadcrumbs";
import ProtectedRoute from "./routes/ProductedRoutes";
import { publicRoutes } from "../src/routes/PublicRoutes";
import { adminRoutes } from "../src/routes/AdminRoutes";
import { employerRoutes } from "../src/routes/EmployerRoutes";
import { jobSeekerRoutes } from "../src/routes/JobSeekersRoutes";
import { superAdminRoutes } from "../src/routes/SuperAdminRoutes";
import { callCenterRoutes } from "../src/routes/CallCenterRoutes";
import EmployerDashboardHeader from "./components/EmployerDashboardHeader"
// import Navbar from "./components/Navbar"; // Import Navbar
import LoginPage from "./pages/LoginPage"; // Import LoginPage
import { Toaster } from "react-hot-toast";
import EmployerLogin from "./components/EmployerLogin";
import LoginModal from "./components/Login";
import './i18n';
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(""); // "admin", "employer", or "jobseeker"
  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleLogin = (userRole) => {
    setIsAuthenticated(true);
    setRole(userRole);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setRole("");
    alert("Logged out successfully.");
  };


  const showEmployerLoginPopupHandler = () => {
    setShowEmployerLoginPopup(true);
  };

  const closeEmployerLoginPopupHandler = () => {
    setShowEmployerLoginPopup(false);
  };

  const renderRoutes = (routes, allowedRoles) =>
    routes.map(({ path, element }, index) => (
      <Route
        key={index}
        path={path}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            role={role}
            allowedRoles={allowedRoles}
          >
            {element}
          </ProtectedRoute>
        }
      />
    ));

  return (
    <Router>
      {isAuthenticated && <EmployerDashboardHeader handleLogout={handleLogout} />}
      {/* <EmployerDashboardHeader handleLogout={handleLogout} /> */}
      <Breadcrumbs />
      <Toaster />
      {/* <Breadcrumbs />
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
        /> */}
      <Routes>
        <Route path="/login" element={<LoginPage handleLogin={handleLogin} />} />
        <Route
          path="/employer-login"
          element={
            <EmployerLogin
            onLogin={handleLogin}
              showEmployerLoginPopup={showEmployerLoginPopup}
              closeEmployerLoginPopup={closeEmployerLoginPopupHandler}
            />
          }
        />
        {/* <Route
          path="/employer-login"
          element={
            <LoginModal
              onLogin={handleLogin}
              isOpen={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
            />
          }
        /> */}
        <Route
          path="/employer-login"
          element={

         <LoginModal
        onLogin={handleLogin} 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)} 
      />}
      />
        
        {/* <Route path="/login" element={<EmployerLogin handleLogin={handleLogin} />} /> */}

        {/* Public Routes */}
        {publicRoutes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}

        {/* Role-Based Routes */}
        {renderRoutes(adminRoutes, ["admin"])}
        {renderRoutes(employerRoutes, ["employer"])}
        {renderRoutes(jobSeekerRoutes, ["jobseeker"])}
        {renderRoutes(superAdminRoutes, ["superadmin"])}
        {renderRoutes(callCenterRoutes, ["callcenter"])}
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;

