import HomePage from "../pages/HomePage";
import AboutUs from "../pages/Home/AboutUs";
import LoginPage from "../pages/LoginPage";
import NotFound from "../pages/NotFound";
// import Contact from "../components/Contact";
// import JobListPage from "../pages/JobseekerJobList";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import NotFound from "../pages/NotFound";
// import HomePage from "../pages/HomePage";
import ListPage from "../pages/ListPage";
// import LoginPage from "../pages/LoginPage";
import Postjob from "../pages/Postjob";
import Findjob from "../pages/Findjob";
// import AboutPage from "../pages/AboutPage";
import EmployerDashboard from "../pages/EmployerDashboad";
import Profile from "../pages/EmployerCreateProfile";
import JobseekerProfileForm from "../pages/JobseekerCreateProfile";
import Breadcrumbs from "../components/Breadcrumbs";
// import React, { useState } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import NotFound from "../pages/NotFound";
// import HomePage from "../pages/HomePage";
// import ListPage from "../pages/ListPage";
// import LoginPage from "../pages/LoginPage";
// import Postjob from "../pages/Postjob";
// import Findjob from "../pages/Findjob";
// import AboutPage from "../pages/AboutPage";
// import EmployerDashboard from "../pages/EmployerDashboad";
// import Profile from "../pages/EmployerCreateProfile";
// import JobseekerProfileForm from "../pages/JobseekerCreateProfile";
// import Breadcrumbs from "../components/Breadcrumbs";
import { Toaster } from "react-hot-toast";
// import ProtectedRoute from "./EmployerProtectedRoute";
// import { Toaster } from "react-hot-toast";
// import ProtectedRoute from "./ProtectedRoute";
// import NotFound from "../pages/NotFound";
// import HomePage from "../pages/HomePage";
// import ListPage from "../pages/ListPage";
// import LoginPage from "../pages/LoginPage";
// import Postjob from "../pages/Postjob";
// import Findjob from "../pages/Findjob";
import MainPage from "../pages/MainPage";
import Viewpost from "../pages/Viewpost";
import JobDetailPage from "../pages/JobDetailPage";
import Postjob1 from "../pages/Postjob1";
// import Profile from "../pages/EmployerCreateProfile";
import Jobstatus from "../components/jobstatus";
import Rating from "../components/Rating";
import Reviews from "../components/Reviews";
import ResumeDetails from "../components/Resumedetails";
import Contact from "../components/Contact";
import JobDescription from "../pages/JobDescription";
import EmployerProfileView from "../pages/EmployerProfileView";
// import EmployerDashboard from "../pages/EmployerDashboad";
// import JobseekerProfileForm from "../pages/JobseekerCreateProfile";
import JobListPage from "../pages/JobseekerJobList";
import ResumeBuilder from "../pages/ResumeBuilder";
import CreateProfile from "../pages/EmployerCreateProfile";
import ApplicantList from "../pages/AllCandidates";
// import Breadcrumbs from "../components/Breadcrumbs"; 
import JobSeekerProfileView from "../pages/JobseekerProfileView"
import ApplyJob from "../components/ApplyJob";
import Applicant from "../pages/Applicant";
import AppliedJob from "../pages/AppliedJob";
import Dashboard from "../pages/admin/dashboard";
import EmployerTable from "../pages/Employee/EmployerTable";
import JobSeekerTable from "../pages/Employee/JobSeekerTable";
// import AppliedJobsListPage from "../pages/Call_center/AppliedJobsListPage"
import ApplicantsListPage from "../pages/Call_center/ApplicantsListPage";
import SubscriptionPacks from "../pages/Employee/Subscription/SubscriptionPacks";
import ApplyJobAllJobSeeker from "../pages/Call_center/ApplyJobAllJobSeeker";
// import { Toaster } from "react-hot-toast";
import AllAdminList from "../pages/admin/AllAdminList";
import AllCallCenterList from "../pages/admin/AllCallCenterList";
// import AboutPage from "../pages/AboutPage";
// import AdminProtectedRoute from "./AdminProtectedRoute";
// import EmployerProtectedRoute from "./EmployerProtectedRoute";
import LoginModal from "../components/Login";
import EmployerLogin from "../components/EmployerLogin";
// import JobSeekerLogin from "../components/JobseekerLogin";
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import JobDescriptionForm from "../pages/Postjob";
import PrivacyPolicy from "../pages/Company/PrivacyAndPolicy";
import TermsAndConditions from "../pages/Company/TermsAndConditions";
import JobSearch from "../pages/JobSeeker/JobSearch";
import Leadership from "../pages/Home/Leadership";
import NewBlog from "../pages/Home/NewBlog";
import BlogPage from "../pages/Home/Blogs";
// import HomePage2 from "../pages/admin/HomePage2";
// import HomePageCopy from "../pages/HomePageCopy";
import Faq from "../pages/Home/Faq";
import VideoPage from "../pages/Home/video";

export const publicRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "/aboutUs", element: <AboutUs /> },
  { path: "/admin/login", element: <LoginPage /> },
  { path: "/contact", element: <Contact /> },
  { path: "*", element: <NotFound /> },
  { path: "/joblist", element: <JobListPage /> },
  { path: "/viewdetails/:id", element: <JobDetailPage /> },
  { path: "/jobstatus", element: <Jobstatus /> },
  { path: "/resumedetails", element: <ResumeDetails /> },
  { path: "/jobseeker/apply", element: <ApplyJob /> },
  { path: "/reviews", element: <Reviews /> },
  { path: "/contact", element: <Contact /> },
  { path: "/jobdescription/:id", element: <JobDescription /> },
  { path: "/employerCreateProfile", element: <CreateProfile /> },
  { path: "/employerProfileView", element: <EmployerProfileView /> },
  { path: "/dashboard", element: <EmployerDashboard /> },
  { path: "/jobseekerCreateProfile", element: <JobseekerProfileForm /> },
  { path: "/JobList", element: <JobListPage /> },
  { path: "/resumebuilder", element: <ResumeBuilder /> },
  { path: "/allCandidates", element: <ApplicantList /> },
  { path: "/jobseekerprofile", element: <JobSeekerProfileView /> },
  { path: "/applicants", element: <Applicant /> },
  { path: "/appliedjob", element: <AppliedJob /> },
  { path: "/admin-dashboard", element: <Dashboard /> },
  { path: "/employerTable", element: <EmployerTable /> },
  { path: "/jobSeekerTable", element: <JobSeekerTable /> },
  { path: "/appliedJobList", element: <ApplicantsListPage /> },
  { path: "/subscriptionPacks", element: <SubscriptionPacks /> },
  { path: "/applyJobAllJobSeeker", element: <ApplyJobAllJobSeeker /> },
  { path: "/allAdminList", element: <AllAdminList /> },
  { path: "/allCallCenterList", element: <AllCallCenterList /> },
  // { path: "/aboutPage", element: <AboutPage /> },
  { path: "/jobDescriptionForm", element: < JobDescriptionForm/> },
  { path: "/privacyAndPolicy", element: < PrivacyPolicy/> },
  { path: "/termsAndConditions", element: < TermsAndConditions/> },
  {path:"/jobSearch", element:<JobSearch />},
  {path:"/ledership", element:<Leadership />},
  {path:"/newBlog", element:<NewBlog/>},
  {path:"/blogPage", element:<BlogPage/>},
  {path:"/Faq", element:<Faq/>},
  {path:"/VideoPage", element:<VideoPage />},

  // {path:"/homepages", element:<HomePage2/>},
  // {path:"/homepageCopy", element:<HomePageCopy/>}

];
