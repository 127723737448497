import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import EmployerRegistration from "../components/EmployerRegistration";
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';



const EmployerLogin = ({ showEmployerLoginPopup, closeEmployerLoginPopup }) => {  
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [modalKey, setModalKey] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const resetForm = () => {
    setEmail("");
    setPassword("");
    setEmailErrorMessage("");
    setPasswordErrorMessage("");
    setLoginErrorMessage("");
    setSuccessMessage("");
  };

  useEffect(() => {
    if (showEmployerLoginPopup) {
      resetForm();
      setModalKey((prevKey) => prevKey + 1);
    }
  }, [showEmployerLoginPopup]);

  const handleEmployerLogin = async (e) => {
    e.preventDefault();
    let isValid = true;

    setEmailErrorMessage("");
    setPasswordErrorMessage("");
    setLoginErrorMessage("");

    if (!email) {
      setEmailErrorMessage("User Name is required.");
      isValid = false;
    }
    if (!password) {
      setPasswordErrorMessage("Password is required.");
      isValid = false;
    }

    // if (!username) {
    //   setPasswordErrorMessage("Username is required.");
    //   isValid = false;
    // }

    if (!isValid) return;

    // setSuccessMessage("Logging in...");
    try {
      const response = await axios.post(`${apiUrl}/user/admin/login`, {
          'username': email, 
          'password':password,
          'role': "employer"
        }
      );

      if (response.status) {
        toast.success(response.data.message);  
        const data = response.data;
        // handleLogin("employer")
        sessionStorage.setItem("accessToken", data.data.accessToken);
        sessionStorage.setItem("role",data.data.role);
        sessionStorage.setItem("userId",data.data.userId);
        sessionStorage.setItem("userName",data.data.userName);
        navigate("/dashboard");

      } else {
        toast.error(response?.data?.message || "Invalid Email or Password." ); 
        
        // setLoginErrorMessage(
        //   response.data.message || "Invalid Email or Password."
        // );
        // setSuccessMessage("");
      }
    } 
    catch (error) {
      toast.error(error?.response?.data?.message??"Error logging in. Please try again."); 
      // setLoginErrorMessage("Error logging in. Please try again.");
      // setSuccessMessage("");
    }

    // try {
    //   const response = await axios.post(`${apiUrl}/user/send-otp`, {
    //     password,
    //     role: 'employer',
    //   });
      
    //   if (response.status) {
    //     const data = response.data;
    //     console.log(response);
    //     console.log(data.data.accessToken);
    //     sessionStorage.setItem("accessToken", data.data.accessToken);
    //     sessionStorage.setItem("role",JSON.stringify(data.data.user.role));
    //     sessionStorage.setItem("userId",JSON.stringify(data.data.user.userId));
    //     navigate("/employerdashboard");
    //   } else {
    //     setLoginErrorMessage(
    //       response.data.message || "Invalid Email or Password."
    //     );
    //     setSuccessMessage("");
    //   }
    // } 
    // catch (error) {
    //   setLoginErrorMessage("Error logging in. Please try again.");
    //   setSuccessMessage("");
    // }
  };

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);

  // Speech Utterance Function
  const speak = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US";
    synth.speak(utterance);
  };

  return (
    <>
      {showEmployerLoginPopup && (
        <div
          key={modalKey}
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1050,
          }}
          onClick={closeEmployerLoginPopup}
        >
          <div
            className="card shadow p-4 position-relative"
            style={{
              width: "400px",
              borderRadius: "25px",
              background: "linear-gradient(to bottom, #ffffff, #f0f8ff)",
              border: "1px solid #d1d9e5",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 m-3"
              aria-label="Close"
              onClick={closeEmployerLoginPopup}
            ></button>

            <h4 className="fw-bold text-center">{t('Employer Login')}</h4>
            <br />
            <form onSubmit={handleEmployerLogin}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  placeholder= {t('Username')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => speak("Please enter your name")}
                  className="form-control"
                  style={{ borderRadius: "15px", borderColor: "#d1d3e2" }}
                />
                {emailErrorMessage && (
                  <div className="alert alert-danger mt-1">
                    {emailErrorMessage}
                  </div>
                )}
              </div>

              <div className="form-group mb-3">
                <input
                  type="password"
                  placeholder={t('Password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => speak("Please enter your password")}
                  className="form-control"
                  style={{ borderRadius: "15px", borderColor: "#d1d3e2" }}
                />
                {passwordErrorMessage && (
                  <div className="alert alert-danger mt-2">
                    {passwordErrorMessage}
                  </div>
                )}
              </div>
              <button className="default-btn w-100">{t('Login')}</button>
            </form>

            {/* {successMessage && (
              <p className="text-success small">{successMessage}</p>
            )}
            {loginErrorMessage && (
              <div className="alert alert-danger">{loginErrorMessage}</div>
            )} */}

            <div className="text-center mt-3">
              <small>
                {t("Don't have an account?")}
                <button
                  type="button"
                  className="btn p-0"
                  onClick={openPopup}
                  style={{ fontSize: "14px", color: "#014C92" }}
                >
                  {t('Sign Up')}
                </button>
                {showPopup && (
                  <EmployerRegistration
                    showPopup={showPopup}
                    closePopup={closePopup}
                  />
                )}
              </small>
            </div>
          </div>
        </div>
      )}

      
    </>
  );
};

export default EmployerLogin;
