import axios from "axios";
const apiUrl = process.env.REACT_APP_BASE_URL;

const API_URL = `${apiUrl}`;
// const API_URL = "https://workaroo-backend.arffy.com/";

export const apiCall = async (endpoint, method = "POST", data = null) => {
  try {
    const token = sessionStorage.getItem("accessToken");
    const config = {
      method,
      url: `${API_URL}${endpoint}`,
      data,
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    return { status: false, message: error.message };
  }
};
