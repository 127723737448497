import React from "react";
import img1 from '../assets/w-goa.jpg'
import Footer from "../components/Footer";

const ReviewsPage = () => {
  return (
    <div style={styles.container}>
      <main style={styles.main}>
        <section style={styles.companyInfo}>
          <img src={img1} alt="Company Logo" style={styles.companyLogo} />
        </section>

        <nav style={styles.tabNav}>
          <button style={styles.tabButton}>About</button>
          <button style={{ ...styles.tabButton, ...styles.activeTab }}>Reviews</button>
        </nav>

        <div style={styles.filterSection}>
          <div style={styles.filterGroup}>
            <span style={styles.filterText}>Filter reviews by</span>
            <select style={styles.select}>
              <option>★ Rating</option>
              <option>★5.0 Rating</option>
              <option>★4.0 Rating</option>
              <option>★3.0 Rating</option>
              <option>★2.0 Rating</option>
              <option>★1.0 Rating</option>
            </select>
          </div>
          <span>Updated 15 Nov, 2024</span>
        </div>

        <section style={styles.reviewsSection}>
          <div style={styles.reviewsHeader}>W Goa Reviews</div>
          <div style={styles.ratingsOverview}>
            <div style={styles.overallRating}>
              <div style={styles.ratingScore}>★3.9</div>
              <div>Overall Rating</div>
            </div>
            <div>
              {[5, 4, 3, 2, 1].map((star) => (
                <div key={star} style={styles.ratingRow}>
                  <input type="checkbox" />
                  <span>{star} ★</span>
                  <div style={styles.ratingBar}>
                    <div style={{ ...styles.ratingFill, width: `${star === 5 ? 50 : star * 10}%` }}></div>
                  </div>
                  <span>{star === 5 ? 18 : 3}</span>
                </div>
              ))}
            </div>
            <div style={styles.categoryRatings}>
            <div style={styles.categoryHeader}>Category Ratings</div>
            <div style={styles.categoryList}>
              {["4.0 Company Culture", "3.8 Skill Development", "3.8 Job Security", "3.8 Work satisfaction", "3.7 Promotion / Appraisal", "3.6 Salary & Benefits", "3.5 Work-Life Balance"].map((category, index) => (
                <div key={index} style={styles.categoryItem}>
                  <span style={styles.categoryStar}>★</span>
                  <span>{category}</span>
                </div>
              ))}
            </div>
          </div>
          </div>


          <div style={styles.review}>
            <h2>35 reviews found</h2>
            <div style={styles.reviewCard}>
              <h3>Waiter caption in Hyderabad / Secunderabad</h3>
              <div>Full Time: Operations Support Department</div>
              <div style={styles.starRating}>
                <span style={styles.starIcon}>★</span>
                <span>5.0</span>
              </div>
              <p><strong>Likes:</strong> All is well, Everything is well</p>
              <p><strong>Dislikes:</strong> Everything is well</p>
              <p><strong>Work Details:</strong> Alternate Saturday off (Strict Timings), This job involves Travel Within Country.</p>
              <div style={styles.reviewActions}>
                <button style={styles.actionButton}>Upvote</button>
                <button style={styles.actionButton}>Comment</button>
                <button style={styles.actionButton}>Share</button>
              </div>
            </div>
          </div>
        </section>

        <aside style={styles.sidebar}>
          <div style={styles.sidebarCard}>
            <p>Anonymously discuss salaries, work culture, and many more</p>
            <button style={styles.appButton}>Get Ambitionbox App</button>
          </div>

          <div style={styles.sidebarCard}>
            <h3>Gender based ratings at W Goa</h3>
            <div style={{...styles.genderRating,backgroundColor: "#FFDFFF" }}>
              <p>★ 3.4 Rated by 7 Women</p>
              <p>Rated 3.7 for work satisfaction and 3.7 for skill Development</p>
            </div>
            <div style={{ ...styles.genderRating, backgroundColor: "#e6f7ff" }}>
              <p>★ 4.3 Rated by 23 men</p>
              <p>Rated 4.4 for Skill development and 4.3 for Job Security</p>
            </div>
          </div>
        </aside>
      </main>
<Footer/>
     
    </div>
  );
};

const styles = {
  container: { fontFamily: "Arial, sans-serif", color: "#333" },
  header: { backgroundColor: "#e6f1fb", padding: "10px 20px" },
  headerContent: { display: "flex", alignItems: "center", justifyContent: "space-between" },
  logo: { fontSize: "24px", fontWeight: "bold" },
  nav: { display: "flex", gap: "20px" },
  navLink: { textDecoration: "none", color: "#333" },
  profileSection: { display: "flex", alignItems: "center", gap: "10px" },
  addPhotoButton: { backgroundColor: "#f1f1f1", border: "none", borderRadius: "5px", padding: "5px 10px" },
  profilePic: { borderRadius: "50%", overflow: "hidden", width: "35px", height: "35px" },
  profileImage: { width: "100%", height: "100%" },
  main: { padding: "20px", maxWidth: "800px", margin: "0 auto" },
  companyInfo: { display: "flex", alignItems: "center", gap: "20px" },
  companyLogo: { width: "100px", height: "50px", borderRadius: "5px" },
  companyName: { margin: 0 },
  ratingInfo: { display: "flex", alignItems: "center", gap: "5px" },
  starIcon: { fontSize: "1.5rem", color: "#ffd700" },
  ratingNumber: { fontSize: "1.5rem", fontWeight: "bold" },
  tabNav: { display: "flex", gap: "20px", marginTop: "10px" },
  tabButton: { border: "none", background: "none", fontSize: "1rem", fontWeight: "bold" },
  activeTab: { background: "#3498db" ,padding: '5px', borderRadius: '10px',color: '#ffffff',width: '120px', marginRight: '200px'},
  filterSection: { marginTop: "20px", display: "flex", justifyContent: "space-between" },
  filterGroup: { display: "flex", alignItems: "center", gap: "10px" },
  filterText: { fontWeight: "bold" },
  select: { padding: "5px", borderRadius: "5px", border: "1px solid #ddd" },
  reviewsSection: { marginTop: "20px" },
  reviewsHeader: { fontWeight: "bold", fontSize: "1.25rem" },
  ratingsOverview: { display: "flex", gap: "30px", marginTop: "10px" },
  overallRating: { textAlign: "center", marginTop: '20px', },
  ratingScore: { fontSize: "2rem", fontWeight: "bold", color: "#0073e6",padding: '20px' },
  ratingRow: { display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" },
  ratingBar: { width: "100px", height: "8px", background: "#e0e0e0", borderRadius: "5px" },
  ratingFill: { backgroundColor: "#0073e6", height: "100%", borderRadius: "5px" },
  categoryRatings: { marginLeft: "100px" },
  categoryHeader: { fontWeight: "bold" },
  categoryList: { display: "flex", flexDirection: "column", gap: "5px" },
  categoryItem: { display: "flex", alignItems: "center", gap: "5px" },
  categoryStar: { color: "#ffd700" },
  review: { marginTop: "20px" },
  reviewCard: { border: "1px solid #ddd", borderRadius: "5px", padding: "15px", marginBottom: "10px" },
  starRating: { display: "flex", alignItems: "center", gap: "5px" },
  reviewActions: { display: "flex", gap: "10px", marginTop: "10px" },
  actionButton: { border: "none", background: "#f0f0f0", padding: "5px 10px", borderRadius: "5px" },
  sidebar: { padding: "10px", marginTop: "20px" },
  sidebarCard: { border: "1px solid #ddd", borderRadius: "5px", padding: "10px", marginBottom: "10px" },
  appButton: { backgroundColor: "#210d25", color: "white", padding: "10px 20px", border: "none", borderRadius: "5px" },
  genderRating: { padding: "10px", backgroundColor: "#f9f9f9", borderRadius: "5px", marginBottom: "10px" },
  footer: { backgroundColor: "#e6f1fb", padding: "20px 0" },
  footerContent: { display: "flex", justifyContent: "space-between", maxWidth: "800px", margin: "0 auto" },
};

export default ReviewsPage;
