import React, { useState } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../utils/api";
import AdminDashboard from '.././components/AdminDashboard';
import { Container} from 'react-bootstrap';
import Header from '.././pages/admin/Header';

const JobSeekerForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    sureName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    aadharNo: "",
    panNo: "",
    drivingLicenceNumber: "",
    voterId: "",
    profileTitle: "",
    totalExperience: "",
    expectedSalary: "",
    skills: [],
    responsibilities: [],
    qualification: [],
    salary: "",
    miscDetails: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const inputStyle = {
    width: "100%",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #ddd",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (step === 1) {
      if (!formData.firstName.trim()) newErrors.firstName = "First Name is required";
      if (!formData.sureName.trim()) newErrors.sureName = "Sure Name is required";
      if (!formData.mobileNumber.trim()) newErrors.mobileNumber = "Mobile Number is required";
      if (!formData.emailAddress.trim()) newErrors.emailAddress = "Email Address is required";
      if (!formData.address.trim()) newErrors.address = "Address is required";
      if (!formData.city.trim()) newErrors.city = "City is required";
      if (!formData.state.trim()) newErrors.state = "State is required";
      if (!formData.pinCode.trim()) newErrors.pinCode = "Pin Code is required";
    }
    
    if (step === 3) {
      if (!formData.profileTitle.trim()) newErrors.profileTitle = "Profile Title is required";
      if (!formData.totalExperience.trim()) newErrors.totalExperience = "Total Experience is required";
      if (!formData.expectedSalary.trim()) newErrors.expectedSalary = "Expected Salary is required";
      if (!formData.skills.trim()) newErrors.skills = "Skills are required";
      if (!formData.responsibilities.trim()) newErrors.responsibilities = "Responsibilities are required";
      if (!formData.qualification.trim()) newErrors.qualification = "Qualification is required";
      if (!formData.salary.trim()) newErrors.salary = "Current Salary is required";
      if (!formData.miscDetails.trim()) newErrors.miscDetails = "Miscellaneous details are required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      setErrors({});
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    setErrors({});
    setStep((prevStep) => prevStep - 1);
  };


const id = sessionStorage.getItem("userId");
const role = sessionStorage.getItem("role")

const userId = id;
const payload = {
  userId,
  role,
  ...formData,
};
  const handleSubmit = async () => {
    if (validateForm()) {
      // try {
      //   const response = await axios.post(`https://localhost:3000/user/jobseekerProfile/${id}`, payload, {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   });
      //   setSuccessMessage("Form submitted successfully!");
      //   setErrorMessage("");
      //   console.log("responjee:", response);
      // } catch (error) {
      //   setErrorMessage("Error submitting form. Please try again.");
      //   setSuccessMessage("");
      //   console.error("Error:", error);
      // }
      try {
        const response = await apiCall(`user/jobseekerProfile/${id}`, "PATCH", payload);
        if (response?.data) {
          // navigate("/employerProfileView");
        }
      } catch (error) {
        console.error("Failed to create User profile:", error);
      }
    };
    }

  return (

    <div className="dashboard">
    <Header />
  {/* < EmployerDashboardHeader /> */}
  {/* <Header /> */}

  <Container fluid className="content">
    <Row className='g-4'>
      <AdminDashboard />
    <div
      style={{
        maxWidth: "800px",
        margin: "50px auto",
        padding: "20px",
        borderRadius: "12px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Form>
        <h3
          style={{
            textAlign: "center",
            color: "#007bff",
            marginBottom: "30px",
          }}
        >
          Step {step}:{" "}
          {step === 1
            ? "Personal Details"
            : step === 2
            ? "KYC Details"
            : "Professional Details"}
        </h3>

        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        {/* Step Content */}
        {step === 1 && (
          <>
          <Row>
            <Col md={6}>
              <Form.Group style={{ marginBottom: "20px" }}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  style={inputStyle}
                />
                {errors.firstName && (
                  <Alert
                    variant="danger"
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      border: "0",
                      padding: "0",
                      paddingLeft: "15px",
                    }}
                  >
                    {errors.firstName}
                  </Alert>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group style={{ marginBottom: "20px" }}>
                <Form.Label>Sure Name</Form.Label>
                <Form.Control
                  type="text"
                  name="sureName"
                  value={formData.sureName}
                  onChange={handleChange}
                  placeholder="Enter sure name"
                  style={inputStyle}
                />
                {errors.sureName && (
                  <Alert
                    variant="danger"
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      border: "0",
                      padding: "0",
                      paddingLeft: "15px",
                    }}
                  >
                    {errors.sureName}
                  </Alert>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group style={{ marginBottom: "20px" }}>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="text"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              placeholder="Enter mobile number"
              style={inputStyle}
            />
            {errors.mobileNumber && (
              <Alert
                variant="danger"
                style={{
                  color: "red",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  border: "0",
                  padding: "0",
                  paddingLeft: "15px",
                }}
              >
                {errors.mobileNumber}
              </Alert>
            )}
          </Form.Group>
          <Form.Group style={{ marginBottom: "20px" }}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              placeholder="Enter email address"
              style={inputStyle}
            />
            {errors.emailAddress && (
              <Alert
                variant="danger"
                style={{
                  color: "red",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  border: "0",
                  padding: "0",
                  paddingLeft: "15px",
                }}
              >
                {errors.emailAddress}
              </Alert>
            )}
          </Form.Group>
          <Form.Group style={{ marginBottom: "20px" }}>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter address"
              style={inputStyle}
            />
            {errors.address && (
              <Alert
                variant="danger"
                style={{
                  color: "red",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  border: "0",
                  padding: "0",
                  paddingLeft: "15px",
                }}
              >
                {errors.address}
              </Alert>
            )}
          </Form.Group>
          <Row>
            <Col md={4}>
              <Form.Group style={{ marginBottom: "20px" }}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Enter city"
                  style={inputStyle}
                />
                {errors.city && (
                  <Alert
                    variant="danger"
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      border: "0",
                      padding: "0",
                      paddingLeft: "15px",
                    }}
                  >
                    {errors.city}
                  </Alert>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group style={{ marginBottom: "20px" }}>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="Enter state"
                  style={inputStyle}
                />
                {errors.state && (
                  <Alert
                    variant="danger"
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      border: "0",
                      padding: "0",
                      paddingLeft: "15px",
                    }}
                  >
                    {errors.state}
                  </Alert>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group style={{ marginBottom: "20px" }}>
                <Form.Label>Pin Code</Form.Label>
                <Form.Control
                  type="text"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleChange}
                  placeholder="Enter pin code"
                  style={inputStyle}
                />
                {errors.pinCode && (
                  <Alert
                    variant="danger"
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      border: "0",
                      padding: "0",
                      paddingLeft: "15px",
                    }}
                  >
                    {errors.pinCode}
                  </Alert>
                )}
              </Form.Group>
            </Col>
          </Row>
        </>

        )}

        {step === 2 && (
            <>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Aadhar Number</Form.Label>
              <Form.Control
                type="text"
                name="aadharNo"
                value={formData.aadharNo}
                onChange={handleChange}
                placeholder="Enter Aadhar number"
                style={inputStyle}
              />

            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>PAN Number</Form.Label>
              <Form.Control
                type="text"
                name="panNo"
                value={formData.panNo}
                onChange={handleChange}
                placeholder="Enter PAN number"
                style={inputStyle}
              />

            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Driving Licence Number</Form.Label>
              <Form.Control
                type="text"
                name="drivingLicenceNumber"
                value={formData.drivingLicenceNumber}
                onChange={handleChange}
                placeholder="Enter driving licence number"
                style={inputStyle}
              />

            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Voter ID</Form.Label>
              <Form.Control
                type="text"
                name="voterId"
                value={formData.voterId}
                onChange={handleChange}
                placeholder="Enter voter ID"
                style={inputStyle}
              />
            </Form.Group>

          </>
        )}

        {step === 3 && (
          <>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Profile Title</Form.Label>
              <Form.Control
                type="text"
                name="profileTitle"
                value={formData.profileTitle}
                onChange={handleChange}
                placeholder="Enter profile title"
                style={inputStyle}
              />
              {errors.profileTitle && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.profileTitle}
                </Alert>
              )}
            </Form.Group>
            <Form.Group style={{ marginBottom: "0px" }}>
              <Form.Label>Total Experience</Form.Label>
              <Form.Control
                type="text"
                name="totalExperience"
                value={formData.totalExperience}
                onChange={handleChange}
                placeholder="Enter total experience"
                style={inputStyle}
              />
              {errors.totalExperience && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.totalExperience}
                </Alert>
              )}
            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Expected Salary</Form.Label>
              <Form.Control
                type="text"
                name="expectedSalary"
                value={formData.expectedSalary}
                onChange={handleChange}
                placeholder="Enter expected salary"
                style={inputStyle}
              />
              {errors.expectedSalary && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.expectedSalary}
                </Alert>
              )}
            </Form.Group>
              <Form.Group style={{ marginBottom: "20px" }}>
                <Form.Label>Skills</Form.Label>
                <Form.Control
                  type="text"
                  name="skills"
                  value={formData.skills}
                  onChange={handleChange}
                  placeholder="Enter skills (comma separated)"
                  style={inputStyle}
                />
                {errors.skills && (
                  <Alert
                    variant="danger"
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      border: "0",
                      padding: "0",
                      paddingLeft: "15px",
                    }}
                  >
                    {errors.skills}
                  </Alert>
                )}
              </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Responsibilities</Form.Label>
              <Form.Control
                type="text"
                name="responsibilities"
                value={formData.responsibilities}
                onChange={handleChange}
                placeholder="Enter responsibilities (comma separated)"
                style={inputStyle}
              />
              {errors.responsibilities && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.responsibilities}
                </Alert>
              )}
            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Qualification</Form.Label>
              <Form.Control
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                placeholder="Enter qualification (comma separated)"
                style={inputStyle}
              />
              {errors.qualification && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.qualification}
                </Alert>
              )}
            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Current Salary</Form.Label>
              <Form.Control
                type="text"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                placeholder="Enter current salary"
                style={inputStyle}
              />
              {errors.salary && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.salary}
                </Alert>
              )}
            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Label>Misc Details</Form.Label>
              <Form.Control
                type="text"
                name="miscDetails"
                value={formData.miscDetails}
                onChange={handleChange}
                placeholder="Enter miscellaneous details"
                style={inputStyle}
              />
              {errors.miscDetails && (
                <Alert
                  variant="danger"
                  style={{
                    color: "red",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    border: "0",
                    padding: "0",
                    paddingLeft: "15px",
                  }}
                >
                  {errors.miscDetails}
                </Alert>
              )}
            </Form.Group>

          </>
        )}

        <div style={{ textAlign: "center", marginTop: "30px" }}>
          {step > 1 && (
            <Button variant="secondary" onClick={handlePrevious} style={{ marginRight: "10px" }}>
              Previous
            </Button>
          )}
          {step < 3 ? (
            <Button variant="primary" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button variant="success" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </div>
      </Form>
    </div>
    </Row>
      </Container>
    </div>
  );
};

export default JobSeekerForm;
