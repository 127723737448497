import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../EmployerDashboard.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import AdminDashboard from '../../components/AdminDashboard';
import Header from '../../pages/admin/Header';
import EmployerRegistration from "../../components/EmployerRegistration";
import CreateProfile from "../../pages/EmployerCreateProfile";
import { ToastContainer, toast } from 'react-toastify';
import { FaEye } from 'react-icons/fa';
import { FaTrash, FaEdit } from 'react-icons/fa';
import {  Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import SubscriptionPacks from './Subscription/SubscriptionPacks';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';


const EmployerTable = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const role = sessionStorage.getItem("role")
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);


  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/user/roles/employer`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data.data)) {
          setApplicants(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, []);

  const openPopup = () => {
  // setShowPopup(true);
  // toast("Hello World")
  // toast("Wow so easy!")
  navigate("/employerCreateProfile")
    
  }
  const closePopup = () => setShowPopup(false);

  const handleViewClick = (applicant) => {
    setSelectedApplicant(applicant);
    setShowDetailsModal(true);
  };

  const handleDeleteClick = async (employers) =>{
    // const deleteJob = async (jobDescriptionId) => {
      const confirmed = window.confirm('Are you sure you want to delete this Employee?');
      if (!confirmed) return;
   
      try {
        const response = await fetch(`${apiUrl}/admin/employers/${employers.userId}`, {
          method: 'DELETE',
        });
   
        if (response.ok) {
          // setJobs((prevJobs) => prevJobs.filter((job) => job.jobDescriptionId !== jobDescriptionId));
          alert('Job deleted successfully.');
        } else {
          alert('Failed to delete the employer. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting job:', error);
        alert('An error occurred while deleting the job.');
      }
    }
  

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div className="dashboard">

       < EmployerDashboardHeader /> 
       {/* <Header /> */}
         <Container fluid className="content">
              <Row className="">
              <EmployerDashboardSideBar/>
              {/* <AdminDashboard /> */}
                <Col md={10} className="main-content">
                      <h5 className='mb-3 fw-bold'>All Employer</h5>
                      {/* <div><Toaster/></div> */}
                      {/* <ToastContainer /> */}
          {/* testing for subscription todo */}
                      <div>
                         {/* Button to Open Modal */}
                         {/* <Button variant="contained" color="primary" onClick={handleOpen}>
                           Subscription
                         </Button> */}
                   
                         {/* Dialog to Display MyComponent */}
                         <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" style={{borderRadius:"10px"}}>
                           {/* <DialogTitle style={{backgroundColor:"white"}}>
                             <span style={{ display: "flex", justifyContent: "space-between",fontSize:"20px", alignItems: "center" }}>
                               <span>Subscription Modal</span>
                          
                               <IconButton onClick={handleClose} size="small">
                                 <CloseIcon />
                               </IconButton>
                             </span>
                           </DialogTitle> */}
                           <DialogContent style={{backgroundColor:"#f5f6fa"}}>
                           <IconButton onClick={handleClose} size="small"
                             style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              zIndex: 10,
                            }}>
                                 <CloseIcon />
                               </IconButton>
                             <SubscriptionPacks /> {/* Render the component inside the popup */}
                           </DialogContent>
                           {/* <DialogActions>
                             <Button onClick={handleClose} color="secondary">
                               Close
                             </Button>
                           </DialogActions> */}
                         </Dialog>
                       </div>

                       <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                         <Modal.Header closeButton>
                           <Modal.Title>Employer Details</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                           {selectedApplicant && (
                             <div>
                               <p><strong>Name:</strong> {selectedApplicant.userName} {selectedApplicant.lastName}</p>
                               <p><strong>Email:</strong> {selectedApplicant.emailAddress}</p>
                               <p><strong>Phone:</strong> {selectedApplicant.phoneNumber}</p>
                               <p><strong>Address:</strong> {selectedApplicant.address}</p>
                               <p><strong>Location:</strong> {selectedApplicant.location}</p>
                
                               <p><strong>Experience:</strong> {selectedApplicant.experience}</p>
                               <p><strong>Status:</strong> {selectedApplicant.status}</p>
                             </div>
                           )}
                         </Modal.Body>
                         <Modal.Footer>
                           <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                             Close
                           </Button>
                         </Modal.Footer>
                       </Modal>
                      <Button 
                      onClick={openPopup}
                      className='Add-button'>

                                          Add
                                        </Button>
                {showPopup && (
                  <EmployerRegistration
                    showPopup={showPopup}
                    closePopup={closePopup}
                  />
                )}
                      <div className='card border-0 rounded-0'>
                        <div className='card-body table-responsive'>
                          
                          <Table hover striped variant='light'  className="table">
                          <thead>
                              <tr className='bg-light'>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                                {applicants.map((applicant) => (
                                  <tr key={applicant.id} className="table-row">
                                    <td>{applicant.userName} {applicant.lastName}</td>
                                    <td>{applicant.phoneNumber}</td>
                                    <td>{applicant.emailAddress}</td>
      
                                    <td>
                                      {/* <Link onClick={() => handleViewClick(applicant)} style={{marginRight:"4px"}} className="text-info">
                                          <FaEye size={18} /> 
                                      <Button   variant="outlined" startIcon={<FaEye />}>
                                        view
                                      </Button>
                                      </Link> */}

                                          <Link  className="text-info me-2">
                                                                          <Button onClick={() => handleViewClick(applicant)} variant="info">
                                                                            View
                                                                          </Button>
                                                                        </Link>

                                      {/* <Link onClick={() => handleEditClick(applicant)} style={{marginRight:"4px"}} className="text-warning">
                                          <FaEdit size={17} /> 
                                      </Link> */}
                                      {role == 'admin' ?
                                      <Link onClick={() => handleDeleteClick(applicant)} style={{marginRight:"4px"}} className="text-danger">
                                      <FaTrash size={16}/> 
                                     </Link>
                                      : 
                                     <></> }
                                      
                                      {/* <Link onClick={() => handleViewClick(applicant)} className="btn btn-info btn-sm">
                                                                        View
                                                                      </Link> */}


                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
              </Row>
      
            </Container>
    </div>
  );
};

export default EmployerTable;
