// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutul{
    list-style-type: none;
    margin-top: 50px !important;
    font-size: 16px !important;
    margin-bottom: 5px;
  }
  .aboutul li{
    margin-bottom: 7px;
  }
  .about{
    margin-top: 50px !important;
    font-size: 14px !important;
  }
  .abouthead{
    margin-top: 0px !important;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/Aboutus.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,0BAA0B;IAC1B,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,2BAA2B;IAC3B,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B","sourcesContent":[".aboutul{\r\n    list-style-type: none;\r\n    margin-top: 50px !important;\r\n    font-size: 16px !important;\r\n    margin-bottom: 5px;\r\n  }\r\n  .aboutul li{\r\n    margin-bottom: 7px;\r\n  }\r\n  .about{\r\n    margin-top: 50px !important;\r\n    font-size: 14px !important;\r\n  }\r\n  .abouthead{\r\n    margin-top: 0px !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
