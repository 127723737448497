import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import img from "../assets/rb_802.jpg";
import { apiCall } from "../utils/api";
import AdminDashboard from '.././components/AdminDashboard';
import { Container, Row} from 'react-bootstrap';
import Header from '.././pages/admin/Header';

const CreateProfile = () => {
  const navigate = useNavigate();
  // const id = sessionStorage.getItem("userId");

  const [formData, setFormData] = useState({
    firstName: "",
    surName: "",
    phoneNumber: "",
    email: "",
    department: "",
    designation: "",
    companyName: "",
    cinNumber: "",
    panNumber: "",
    gstIn: "",
    industryCategory: "",
    industrySubCategory: "",
    communicationAdress: "",
    branch: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    password: "",
    role: "employer",
    isDeleted: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    image: "",
  });

  const [imagePreview, setImagePreview] = useState(img);
  const [uploadFile, setUploadFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setUploadFile(file);
    }
  };

  const validatePersonalDetails = () => {
    const requiredFields = ["firstName", "surName", "phoneNumber", "email"];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    if (
      formData.phoneNumber &&
      !/^\d{10}$/.test(formData.phoneNumber)
    ) {
      newErrors.phoneNumber = "Phone number must be 10 digits";
    }
    if (
      formData.email &&
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Email is invalid";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateCompanyDetails = () => {
    const requiredFields = [
      "companyName",
      "gstIn",
      "industryCategory",
      "industrySubCategory",
      "communicationAdress",
    ];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validatePersonalDetails()) {
      setShowCompanyDetails(true);
    }
  };

  const handlePrevious = () => {
    setShowCompanyDetails(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateCompanyDetails()) {
      return;
    }

    const id = sessionStorage.getItem("userId");
    const userId = id;
    const payload = {
      userId,
      ...formData,
      image: uploadFile ? uploadFile.name : "",
    };

    try {
      const response = await apiCall(`user/employerProfile/${id}`, "PATCH", payload);
      if (response?.data) {
        navigate("/employerProfileView");
      }
    } catch (error) {
      console.error("Failed to create employer profile:", error);
    }
  };

  return (

        // <div className="col-md-7 p-4 d-flex align-items-center justify-content-center">
        //   <form onSubmit={handleSubmit} className="w-100 " style={{height:'100vh'}}>
        //     <div className="card p-4 w-100" style={{ objectFit: "cover" }}>
        //       <h2 className="text-center mb-2" style={{ color: "#3498db" }}>
        //         Employer Details
        //       </h2>

        //       {!showCompanyDetails && (
        //         <div style={{height:'67vh'}}>
        //           <h4 className="text mb-2" style={{ color: "#014C9" }}>
        //             Personal Details
        //           </h4>
        //           <div className="row">
        //             {[
        //               { label: "First Name", name: "firstName", type: "text" },
        //               { label: "SurName", name: "surName", type: "text" },
        //               { label: "Phone Number", name: "phoneNumber", type: "text" },
        //               { label: "Email", name: "email", type: "email" },
        //               { label: "Designation", name: "designation", type: "text" },
        //               { label: "Department", name: "department", type: "text" },
        //             ].map((field, index) => (
        //               <div className="col-md-6 mb-3" key={index}>
        //                  {field.label}
        //                 <input
        //                   type={field.type}
        //                   name={field.name}
        //                   value={formData[field.name]}
        //                   onChange={handleInputChange}
        //                   className={`form-control ${
        //                     errors[field.name] ? "is-invalid" : ""
        //                   }`}
        //                   // placeholder={` Enter your ${field.label} `}
        //                 />
        //                 {errors[field.name] && (
        //                   <div className="invalid-feedback">
        //                     {errors[field.name]}
        //                   </div>
        //                 )}
        //               </div>
        //             ))}
        //           </div>
        //           <div className="text-center">
        //             <button
        //               type="button"
        //               className="default-btn"
        //               onClick={handleNext}
        //             >
        //               Next
        //             </button>
        //           </div>
        //         </div>
        //       )}

        //       {showCompanyDetails && (
        //         <div style={{}}>
        //           <h4 className="text mb-3" style={{ color: "#014C9" }}>
        //             Company Details
        //           </h4>
        //           <div className="row">
        //             {[
        //               { label: "Company Name", name: "companyName", type: "text" },
        //               { label: "CIN Number", name: "cinNumber", type: "text" },
        //               { label: "PAN Number", name: "panNumber", type: "text" },
        //               { label: "GSTIN", name: "gstIn", type: "text" },
        //               { label: "Industry Category", name: "industryCategory", type: "text" },
        //               { label: "Industry Sub Category", name: "industrySubCategory", type: "text" },
        //               { label: "Communication Address", name: "communicationAdress", type: "text" },
        //               { label: "Pin Code", name: "pincode", type: "text" },
        //               { label: "City", name: "city", type: "text" },
        //               { label: "State", name: "state", type: "text" },
        //             ].map((field, index) => (
        //               <div className="col-md-6 mb-3" key={index}>
        //                  {field.label}
        //                 <input
        //                   type={field.type}
        //                   name={field.name}
        //                   value={formData[field.name]}
        //                   onChange={handleInputChange}
        //                   className={`form-control ${
        //                     errors[field.name] ? "is-invalid" : ""
        //                   }`}
        //                   // placeholder={`Enter your ${field.label} `}
        //                 />
        //                 {errors[field.name] && (
        //                   <div className="invalid-feedback">
        //                     {errors[field.name]}
        //                   </div>
        //                 )}
        //               </div>
        //             ))}
        //           </div>
        //           <div className="d-flex justify-content-between mt-0">
        //             <button
        //               type="button"
        //               className="default-btn"
        //               onClick={handlePrevious}
        //             >
        //               Previous
        //             </button>
        //             <button type="submit" className="default-btn">
        //               Submit
        //             </button>
        //           </div>
        //         </div>
        //       )}
        //     </div>
        //   </form>
        // </div>

          <div className="dashboard">
                <Header />
              {/* < EmployerDashboardHeader /> */}
              {/* <Header /> */}
            
              <Container fluid className="content">
                <Row className='g-4'>
                  <AdminDashboard />

        <div className="col-md-7 p-4 d-flex  align-items-center justify-content-center" style={{margin:"50px auto"}}>
  <form onSubmit={handleSubmit} className="w-100" style={{ height: '100vh' }}>
    <div className="card p-4 w-100" style={{ objectFit: "cover" }}>
      <h2 className="text-center mb-3" style={{ color: "#3498db", fontWeight: 'bold' }}>
        Employer Details
      </h2>

      {!showCompanyDetails && (
        <div style={{ height: '67vh' }}>
          <h4 className="text mb-3" style={{ color: "#014C9", fontWeight: '500' }}>
            Personal Details
          </h4>
          <div className="row">
            {[
              { label: "First Name", name: "firstName", type: "text" },
              { label: "SurName", name: "surName", type: "text" },
              { label: "Phone Number", name: "phoneNumber", type: "text" },
              { label: "Email", name: "email", type: "email" },
              { label: "Designation", name: "designation", type: "text" },
              { label: "Department", name: "department", type: "text" },
            ].map((field, index) => (
              <div className="col-md-6 mb-3" key={index}>
                <label htmlFor={field.name} className="form-label">{field.label}</label>
                <input
                  id={field.name}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
                  aria-label={`Enter your ${field.label}`}
                />
                {errors[field.name] && (
                  <div className="invalid-feedback">{errors[field.name]}</div>
                )}
              </div>
            ))}
          </div>
          <div className="text-center">
            <button
              type="button"
              className="default-btn"
              onClick={handleNext}
              style={{ backgroundColor: "#3498db", color: "#fff", borderRadius: '5px', padding: '10px 20px' }}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {showCompanyDetails && (
        <div style={{}}>
          <h4 className="text mb-3" style={{ color: "#014C9", fontWeight: '500' }}>
            Company Details
          </h4>
          <div className="row">
            {[
              { label: "Company Name", name: "companyName", type: "text" },
              { label: "CIN Number", name: "cinNumber", type: "text" },
              { label: "PAN Number", name: "panNumber", type: "text" },
              { label: "GSTIN", name: "gstIn", type: "text" },
              { label: "Industry Category", name: "industryCategory", type: "text" },
              { label: "Industry Sub Category", name: "industrySubCategory", type: "text" },
              { label: "Communication Address", name: "communicationAdress", type: "text" },
              { label: "Pin Code", name: "pincode", type: "text" },
              { label: "City", name: "city", type: "text" },
              { label: "State", name: "state", type: "text" },
            ].map((field, index) => (
              <div className="col-md-6 mb-3" key={index}>
                <label htmlFor={field.name} className="form-label">{field.label}</label>
                <input
                  id={field.name}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
                  aria-label={`Enter your ${field.label}`}
                />
                {errors[field.name] && (
                  <div className="invalid-feedback">{errors[field.name]}</div>
                )}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button
              type="button"
              className="default-btn"
              onClick={handlePrevious}
              style={{ backgroundColor: "#e74c3c", color: "#fff", borderRadius: '5px', padding: '10px 20px' }}
            >
              Previous
            </button>
            <button
              type="submit"
              className="default-btn"
              style={{ backgroundColor: "#3498db", color: "#fff", borderRadius: '5px', padding: '10px 20px' }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  </form>
</div>
  </Row>
       </Container>
     </div>

  );
};

export default CreateProfile;
