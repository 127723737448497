import React, { useState, useEffect } from 'react';
import "../../ApplyJobAllJobSeeker.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import AdminDashboard from '.././components/AdminDashboard';
import { Container, Col, Row, Table, Button, Modal, Spinner } from 'react-bootstrap';
// import Header from '.././pages/admin/Header';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';


const ApplyJobAllJobSeeker = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const[tableData,setTableData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data.data)) {
          setTableData(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, []);

  const filteredData = tableData.filter((row) => {
    return (
      row?.userName?.toLowerCase().includes(searchText.toLowerCase()) 
      && (filterStatus === "" || row?.status === filterStatus)
    );
  });

  const toggleRowSelection = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const ApplyJob = () => {
    navigate("/Joblist", { state: { selectedIds: selectedRows } });
  };

  return (
    <div className="dashboard">

    < EmployerDashboardHeader /> 
      <Container fluid className="content">
           <Row className="">
           <EmployerDashboardSideBar/>
             <Col md={10} className="main-content">
    <div className="table-container">
      <h2 style={{display:"flex"}}>Job Seekers list</h2>
      <div className="controls">
        <input
          type="text"
          placeholder="Search by name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="input"
        />
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="select"
        >
          <option value="">All</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
        <button
          onClick={ApplyJob}
          disabled={selectedRows.length === 0}
          className="action-button"
        >
          Apply Job
        </button>
      </div>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row) => (
            <tr key={row.userId}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(row.userId)}
                  onChange={() => toggleRowSelection(row.userId)}
                />
              </td>
              <td>{row.userName}</td>
              <td>{row.email}</td>
              <td>{row.phoneNumber}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </Col>
    </Row>
    </Container>
    </div>
  );
};

export default ApplyJobAllJobSeeker;
