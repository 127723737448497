import React, { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function ResumeDetailsPopup() {
  const [isOpen, setIsOpen] = useState(true);
  const [file, setFile] = useState(null); // State to store the selected file
  const [formData, setFormData] = useState({
    experience: "",
    skills: "",
    coverLetter: "",
  }); // State for form fields
  const navigate = useNavigate(); // Navigation hook

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Handle form field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const selectedJobId = sessionStorage.getItem("selectedJobId");
  const userId = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("accessToken");
  // Convert file to Base64
  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
    const apiUrl = process.env.REACT_APP_BASE_URL;
  // Handle form submission
  const handleUpdate = async () => {
    try {
      let base64File = null;
      if (file) {
        base64File = await convertFileToBase64(file);
      }

      const dataToPost = {
        ...formData,
        resume: base64File,
        jobId: selectedJobId,
        jobSeekerId: userId,
      };

      console.log("Data to post:", dataToPost);

      // Post data to API
      const response = await fetch(`${apiUrl}/applications/apply`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify(dataToPost),
      });

      if (response.ok) {
        // Show success message
        Swal.fire({
          title: "Success!",
          text: "Your details have been updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
          timer: 2000, // Automatically closes after 2 seconds
        });

        // Navigate to another page after SweetAlert closes
        setTimeout(() => {
          navigate("/JobList");
        }, 2000);
      } else {
        throw new Error("Failed to update details.");
      }
    } catch (err) {
      
      Swal.fire({
        title: "Success!",
        text: "Your details have been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
        timer: 2000, // Automatically closes after 2 seconds
      });
    }
  };

  const closePopup = () => setIsOpen(false);

  return (
    <div>
      {isOpen && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <div style={styles.popupHeader}>
              <h2 style={styles.title}>Resume Details</h2>
              <button style={styles.closeButton} onClick={closePopup}>
                X
              </button>
            </div>

            <div style={styles.form}>
              <input
                style={styles.input}
                type="text"
                name="experience"
                placeholder="Experience"
                value={formData.experience}
                onChange={handleInputChange}
              />
              <input
                style={styles.input}
                type="text"
                name="currentCTC"
                placeholder="currentCTC"
                value={formData.currentCTC}
                onChange={handleInputChange}
              />
              
              <input
                style={styles.input}
                type="text"
                name="skills"
                placeholder="Skills"
                value={formData.skills}
                onChange={handleInputChange}
              />

              <input
                style={styles.input}
                type="text"
                name="expectCTC"
                placeholder="expectCTC"
                value={formData.expectCTC}
                onChange={handleInputChange}
              />
            </div>

            <div style={styles.uploadSection}>
              <input
                type="file"
                style={styles.fileInput}
                onChange={handleFileChange}
              />
            </div>

            <div style={styles.coverLetterSection}>
              <label style={styles.coverLetterLabel}>Cover Letter</label>
              <textarea
                style={styles.coverLetterTextarea}
                name="coverLetter"
                placeholder="Write your cover letter here..."
                rows="8"
                value={formData.coverLetter}
                onChange={handleInputChange}
              />
            </div>

            <button style={styles.updateButton} onClick={handleUpdate}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
const styles = {
  openButton: {
    fontSize: "16px",
    padding: "10px 20px",
    backgroundColor: "#61b2db",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "20px",
    width: "600px",
    maxHeight: "90vh",
    overflowY: "auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative",
  },
  popupHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  closeButton: {
    background: "none",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
    color: "#999",
  },
  title: {
    fontSize: "24px",
    textAlign: "center",
    color: "#61b2db",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "15px",
  },
  input: {
    padding: "10px",
    border: "2px solid #61b2db",
    borderRadius: "5px",
    width: "100%",
  },
  uploadSection: {
    textAlign: "center",
    marginBottom: "20px",
    border: "2px dashed #61b2db",
    borderRadius: "10px",
    padding: "20px",
  },
  uploadButton: {
    fontSize: "18px",
    color: "#777",
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  updateButton: {
    display: "block",
    width: "50%",
    margin: "0 auto",
    padding: "10px",
    fontSize: "18px",
    backgroundColor: "#61b2db",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },

  
};

export default ResumeDetailsPopup;
