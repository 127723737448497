import React, { useState, useEffect } from "react";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import privacyimage from '../../assets/privacyandpolicy.avif'



const PrivacyAndPolicy = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const colWidth = isLoggedIn ? "col-10" : "col-12";

  return (
    <>
      <EmployerDashboardHeader />
      <div className="container">
        <div className="row">
          {isLoggedIn && <EmployerDashboardSideBar />}
           

           {/* <div className="col-6">
               <img height={'300px'} width={'300px'} src={privacyimage}/>
           </div> */}


          <div className={colWidth} col-6>
          <h1>Privacy Policy</h1>
            <ul className="text-unordered privacy-policy" style={{listStyle:'none'}}>
              <li>
                PANSKILL CONNECT (PASKCO) may collect the following information
                from users of our site: first name, last name, street address,
                area, city, phone, contact numbers, e-mail address, GPS location
                (mobile site), and other professional & skilled details
                (collectively, "Personally Identifiable Information" or "PII").
              </li>
              <li>
                PASKCO also uses web analytics software to track and analyze
                traffic on the site in connection with PASKCO advertising and
                promotion of PASKCO services. PASKCO may publish these
                statistics or share them with third parties without including
                PII.
              </li>
              <li>
                We and our third-party service providers may also use cookies,
                pixel tags, web beacons, and other similar technologies to
                better serve you with more tailored information and facilitate
                your ongoing use of our Site. If you do not want information
                collected through the use of cookies, there is a simple
                procedure in most browsers that allows you to decline the use of
                cookies. To learn more about cookies, please visit{" "}
                <a
                  href="http://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.allaboutcookies.org/
                </a>.
              </li>
              <li>
                IP Addresses are the Internet Protocol addresses of the
                computers that you are using. Your IP Address is automatically
                assigned to the computer that you are using by your Internet
                Service Provider (ISP). This number is identified and logged
                automatically in our server log files whenever users visit the
                site, along with the time of such visit and the page that was
                visited. Collecting IP Addresses is standard practice on the
                Internet and is done automatically by many websites.
              </li>
              <li>
                PASKCO uses PII to create user accounts, communicate with users
                about services, offer additional promotions, and manage purchases.
                Users may opt out of promotional communications by updating
                their account preferences.
              </li>
              <li>
                PASKCO uses cookies to enhance user experience. Cookies help
                identify users and provide user-specific information. PASKCO does
                not sell the information it collects and only shares it with
                vendors to deliver services.
              </li>
              <li>
                To register for an account, we require an email address. This
                information is used to facilitate communication, improve services,
                and comply with legal requirements. We do not sell or disclose
                personal information without user consent unless legally required.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyAndPolicy;