import React from "react";
import { FaFacebookF, FaLinkedinIn, FaGoogle, FaInstagram, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import workaroo_qr from "../assets/Workaroo-logos/workaroo-QR.png";
import '../Styles/Footer.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

const Footer = () => {
  const navigate = useNavigate();
  const handleContactForm = (role) => {
    navigate("/contact", { state: { Roles: role } });
  };


  return (
    <footer className="text-white pt-5 pb-3 footersection" style={{backgroundColor:'rgb(173, 160, 255);',color:'#170F49'}}>
      <div className="container">
        <div className="row" style={{margin:'10px auto'}}>
          <div className="col-md-5 mb-4">
            <img src={logo} alt="Apana Logo" width={200} className="mb-3" />
            <p>Follow us on social media</p>
            <div className="footer-social mt-3"  style={{color:'white'}}>
              <a style={{color:'white'}}
                href="https://www.facebook.com/workaroo"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3 "
              >
                <FaFacebookF />
              </a>
              <a style={{color:'white'}}
                href="https://www.linkedin.com/company/workaroo-ai"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3 "
              >
                <FaLinkedinIn />
              </a>
              <a style={{color:'white'}}
                href="https://www.google.com/search?q=Workaroo.in"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <FaGoogle />
              </a>
              <a  style={{color:'white'}}
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <FaInstagram />
              </a>
              <a   style={{color:'white'}}
                href="https://www.youtube.com/@workaroo4751"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <FaYoutube />
              </a>

             <div style={{borderBottom:'1px solid grey',width:'80%',}} className="mt-4"></div>

              <ul className="footer-links d-flex footerul" style={{color:'white'}}>
                <li style={{color:'#170F49'}} >
                  <a     href="privacyAndPolicy">Privacy Policy</a>
                  
                </li>
                <li style={{marginLeft:'30px',color:'white'}}>
                  <a    href="termsAndConditions">Terms & Conditions</a>
                </li>
                <li style={{marginLeft:'30px',color:'white'}}>
                  <a  href="aboutUs">About Us</a>
                </li>

                <li style={{marginLeft:'30px',color:'white'}}>
                  <a  href="/BlogPage">Blog</a>
                </li>
              </ul>
            </div>
          </div>
         
         
          <div className="col-md-4">
            <h5 className="mb-3 fs-5" style={{color:'white'}} >Connect with Us</h5>
            <h6 className="footer-heading" style={{color:'white'}} >ADDRESS</h6>
              <p className="fw-bold"  style={{color:'white'}} >PAN SKILL CONNECT PRIVATE LIMITED</p>
              <p className="fw-bold"  style={{color:'white'}} >Corporate Office</p>
              <p  style={{color:'white'}} >Room # 314, CoSpace Connect, # 579, 32nd D Cross, 10th Main Rd, 4th Block,
                Jayanagar, Bengaluru, Karnataka 560011
              </p   >
              <p className="fw-bold"  style={{color:'white'}} > Registered Office</p>
              <p  style={{color:'white'}} >
              HRIDYAM, NANGARATH PEEDIKA,, PO TEMPLE GATE, THALASSERY, KANNUR, Kannur, Kerala, India, 670102
              </p>
             </div>
             <div className="col-md-3">
               
             <img
                src={workaroo_qr}
                loading="lazy"
                alt="Workaroo QR Code"
                style={{ margin: '6px 37px', padding: 'inherit' }}
                 height={'100px'}
                 width={'150px'}
              />
                    <a
                   href="https://www.google.com/search?q=Workaroo.in&stick=H4sIAAAAAAAA_-NgU1I1qDBOSkw1NDVIMzA1STIxTEqyMqhISUlJTk0zTDJPNDU3SkozWcTKHZ5flJ1YlJ-vl5kHALFvf683AAAA&hl=en-GB&mat=CQoeg5wbhV3lElcBqiIDwmPnuh0nx2_BoNvziuZcIZg8rKmy_2BcYTtTvZnlF94vWAph17B4v9tEeBDJ45DinFW46TD0YMeqc57d-gRJMgesFDoDgZyTGPLLBQ_lQ1_w8HQ&authuser=0&ved=2ahUKEwjrzoOu97eKAxXzV2wGHcG9OSMQ-MgIegQIFxA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGoogle />
                </a>
             </div>
          </div>
        </div>

        <hr className="border-light" />
        <div className="text-center">
          <p className="mb-0">
          <p>© 2024 Workaroo. All Rights Reserved.</p>
          </p>
        </div>
      
    </footer>
  );
};

export default Footer;
