import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Badge, Form, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaBriefcase, FaClipboardList, FaUsers, FaChartLine } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
 
const EmployerDashboard = () => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("accessToken");
  const employerid = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
 
 
  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }
 
       // const response = await axios.get(`${apiUrl}/job/employer/${employerid}`, {
          const response = await axios.get(`${apiUrl}/job/employer/postedById`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });
 
        if (Array.isArray(response.data.data)) {
          setJobData(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
 
    // fetchApplicants();
  }, []);
 
  const getJobStats = () => {
    const activeJobs = jobData.filter(job => job.status === 'Active').length;
    const inactiveJobs = jobData.filter(job => job.status === 'Inactive').length;
    const totalJobs = jobData.length;
    const totalApplications = jobData.reduce((acc, job) => acc + job.applications, 0);
 
    return { activeJobs, inactiveJobs, totalJobs, totalApplications };
  };
 
  const handleStatusChange = (id) => {
    setJobData(prevJobs =>
      prevJobs.map(job =>
        job.id === id ? { ...job, status: job.status === 'Active' ? 'Inactive' : 'Active' } : job
      )
    );
  };
 
  const stats = getJobStats();
 
  return (
    <div className="dashboard">
 
      < EmployerDashboardHeader />
 
 
 
      <Container fluid className="content">
        <Row className='g-4'>
          <EmployerDashboardSideBar />
 
          <Col md={10} className="main-content">
            <Row className='g-4'>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Total Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{stats.totalJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-violet avatar-rounded"><FaClipboardList size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Active Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{stats.activeJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-ora avatar-rounded"><FaBriefcase size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Inactive Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{stats.inactiveJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-green avatar-rounded"><FaUsers size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Total Applications</span>
                        <h3 class="fw-semibold lh-1 mb-0">{stats.totalApplications}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-blue avatar-rounded"><FaChartLine size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
 
            <Row>
              <Col md={12}>
                <h5 className='mb-3 fw-bold'>Job Listing</h5>
                <div className='card border-0 rounded-0'>
                  <div className='card-body table-responsive'>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Category</th>
                          <th>₹ Salary/Month</th>
                          <th>Applications</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobData.map((job) => (
                          <tr key={job.id} className="table-row">
                            <td>{job.jobTitle}</td>
                            <td>{job.jobType}</td>
                            <td>{job.salary}</td>
                            <td>{job.applications}</td>
                            <td>
                              <Badge
                                bg={job.isDeleted === false && job.status === 'Active' ? 'success' : 'success'}
                              >
                                {job.isDeleted === false ? 'Active' : 'Deleted'}
                              </Badge>
                            </td>
                            <td>
                              <Form.Check
                                type="switch"
                                id={`edit-switch-${job.id}`}
                                checked={job.status === 'Active' && job.isDeleted === false}
                                onChange={() => handleStatusChange(job.id)}
                                style={{ color: "#014C9" }}
                                disabled={job.isDeleted === true}  // Disable the switch if deleted
                              />
                            </td>
 
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmployerDashboard;
