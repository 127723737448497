import React from "react";
import { Navigate } from "react-router-dom";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";

const ProtectedRoute = ({ isAuthenticated, role, allowedRoles, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
