import React, { useState } from "react";
import axios from "axios";
// import logo from "../assets/workaroo_logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
// import CreatePasswordPopup from "./EmployerPassword";
import { useNavigate } from "react-router-dom";
// import { Formik } from 'formik';
import { useFormik } from "formik";
import { TextField, Button } from "@mui/material";
// import { makeStyles } from "@material-ui/core/styles";
import '../../EmployerDashboard.css';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import EmployerLogin from "../components/EmployerLogin";
import { toast } from 'react-hot-toast';


const AdminRegistration = ({ showAdminPopup, closeAdminPopup }) => {
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState("");
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
  const [role, setRole] = useState('');
  
  // const [showPassword, setShowPassword] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate()
//   const handleChange = (event) => {
//     setRole(event.target.value);
//   };
  
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber:"",
      role:"",
      password:"",
      confirmPassword:"",
    },
    onSubmit: async (values) => {
        console.log(values);
        

      try {
        const response = await axios.post(`${apiUrl}/user/signup`,
          {
            "userName":values.name,
            "email":values.email,
            "phoneNumber":values.phoneNumber,
            "password":values.password,
            "otpCode": otp,
            "role": values.role,
          }
        );
  
        if (response.data.status == true) {
          // setSuccessMessage("Registered successfully!");
          const Data = response.data.data;
          sessionStorage.setItem("accessToken", Data.data.accessToken);
          sessionStorage.setItem("role",Data.data.role);
          sessionStorage.setItem("userId",Data.data.userId);
          sessionStorage.setItem("userName",Data.data.userName);
          navigate("/dashboard");
          toast.success(response?.data?.message);  

        } else {
          // setRegistrationErrorMessage(
          //   response.data.message || "Failed to send OTP."
          // );
          // setSuccessMessage("");
        toast.error(response?.data?.message); 

        }
      } catch (error) {
        // setRegistrationErrorMessage("Error while sending OTP. Please try again.");
        // setSuccessMessage("");
        toast.error(error?.response?.data?.message??"Error in signup. Please try again.");
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values.name) {
        errors.name = "This is a required field";
      }
      if (!values.email) {
        errors.email = "This is a required field";
      }else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.email)){
        errors.email = "Enter Valid Email";
      }
      if(!values.phoneNumber){
        errors.phoneNumber = "This is a required field";
      }else if (!/^\d{10}$/.test(values.phoneNumber)) {
        errors.phoneNumber = "Enter Valid Phone Number";
      }  
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 8) {
        errors.password = "Password must be at least 8 characters";
      }
    
      
      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is required";
      } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Passwords do not match";
      }
      return errors;
    }
  });


  const handleSendOtp = async () => {
    if (!userName) {
      setRegistrationErrorMessage("Username is required.");
      return;
    }
    if (!phoneNumber) {
      setRegistrationErrorMessage("Phone number is required.");
      return;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setRegistrationErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    // if (!password) {
    //   setRegistrationErrorMessage("Username is required.");
    //   return;

    setRegistrationErrorMessage("");
    setSuccessMessage("Sending OTP...");

    try {
      const response = await axios.post(
        `${apiUrl}/user/send-otp`,
        {
          userName,
          phoneNumber,
          role: "employer",
          // otpCode:"125467",
          // password:"user@123"
        }
      );

      if (response.data.status) {
        setSuccessMessage("OTP sent successfully!");
        setShowOtpModal(true);

        console.log(response)
      } else {
        setRegistrationErrorMessage(
          response.data.message || "Failed to send OTP."
        );
        setSuccessMessage("");
      }
    } catch (error) {
      setRegistrationErrorMessage("Error while sending OTP. Please try again.");
      setSuccessMessage("");
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpErrorMessage("Please enter the OTP.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/user/verify-otp`,
        {
          userName,
          phoneNumber,
          // password,
          otpCode: otp,
          role: "employer"
        }
      );

      if (response.data.status) {
        const data = response.data;
        console.log(response)
        console.log(data.data.accessToken)

        sessionStorage.setItem("role",data.data.role);
        sessionStorage.setItem("accessToken", data.data.accessToken);
        sessionStorage.setItem("userId", data.data.userId);
        sessionStorage.setItem("userDetails", JSON.stringify(data));
        sessionStorage.setItem("userName",data.data.userName);

        console.log("empid", data.data.userId)

        
        setShowOtpModal(false);
        navigate("/dashboard");
      } else {
        
        setOtpErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);

      
      setOtpErrorMessage(
        error.response?.data?.message || "Error verifying OTP. Please try again."
      );
    }
  };

  return (
    <>
      {showAdminPopup && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ background: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
          onClick={closeAdminPopup}
        >
          <div
            className="card shadow p-4 position-relative"
            style={{
              width: "400px",
              borderRadius: "25px",
              background: "linear-gradient(to bottom, #ffffff, #f0f8ff)",
              border: "1px solid #d1d9e5",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 m-3"
              aria-label="Close"
              onClick={closeAdminPopup}
            ></button>
            {/* <img src={logo} alt="Logo" className="mb-4" style={{ width: "90px" }} /> */}
            <h4 className="fw-bold" style={{ color: "#000000", textAlign: 'center' }}>
             Registration 
            </h4>
            <br />
            <p className="text-muted" style={{ textAlign: 'center' }}>Sign in to post and manage your jobs easily.</p>
             <form onSubmit={formik.handleSubmit}>
                <div className=" mb-3">
                <FormControl sx={{ m: 0, width: '39ch' }} variant="outlined">
                <TextField   
                  style={{background: "linear-gradient(rgb(255, 255, 255), rgb(240, 248, 255))"}}  
                   className="form-control"
                   variant="outlined"
                   label="User Name"
                  //  placeholder=""
                   margin="dense"
                   name="name"
                   value={formik.values.name}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                 />
                 <div>
                   {formik.touched.name && formik.errors.name ? (
                     <div className="input-error">{formik.errors.name}</div>
                   ) : null}
                 </div>
                 </FormControl>
               </div>
               <div className=" mb-3">
               <FormControl sx={{ m: 0, width: '39ch' }} variant="outlined">
                 <TextField
                   style={{background: "linear-gradient(rgb(255, 255, 255), rgb(240, 248, 255))"}}  
                   className="form-control"
                   variant="outlined"
                   label="Email"
                   margin="dense"
                   name="email"
                   value={formik.values.email}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                 />
                 <div>
                   {formik.touched.email && formik.errors.email ? (
                     <div className="input-error">{formik.errors.email}</div>
                   ) : null}
                 </div>
                 </FormControl>
               </div>
               <div className=" mb-3">
                <FormControl sx={{ m: 0, width: '39ch' }} variant="outlined">
                <TextField   
                  style={{background: "linear-gradient(rgb(255, 255, 255), rgb(240, 248, 255))"}}  
                   className="form-control"
                   variant="outlined"
                   label="Phone Number"
                   margin="dense"
                   name="phoneNumber"
                   value={formik.values.phoneNumber}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                 />
                 <div>
                   {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                     <div className="input-error">{formik.errors.phoneNumber}</div>
                   ) : null}
                 </div>
                 </FormControl>
               </div>
               <div className=" mb-3">
                  <FormControl sx={{ m: 0, width: '39ch' }}>
                   <InputLabel >Role</InputLabel>
                   <Select
                     name="role"
                     label="Role"
                     value={formik.values.role}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                    //  onChange={handleChange}
                   >
                     {/* <MenuItem value={"superadmin"}>Super Admin</MenuItem> */}
                     <MenuItem value={"admin"}>Admin</MenuItem>
                     <MenuItem value={"callcenter"}>Call Center</MenuItem>
                   </Select>
                  </FormControl>
                  </div>
               <div className=" mb-3">
                  <FormControl sx={{ m: 0, width: '39ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formik.values.password}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            // onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                 <div>
                   {formik.touched.password && formik.errors.password ? (
                     <div className="input-error">{formik.errors.password}</div>
                   ) : null}
                 </div>
               </div>
               <div className="mb-3">
                 <FormControl sx={{ m: 0, width: '39ch' }} variant="outlined">
                   <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                   <OutlinedInput
                     id="outlined-adornment-password"
                     type={showConfirmPassword ? 'text' : 'password'}
                     name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                     endAdornment={
                       <InputAdornment position="end">
                         <IconButton
                           aria-label={
                             showConfirmPassword ? 'hide the password' : 'display the password'
                           }
                           onClick={handleClickShowConfirmPassword}
                           edge="end"
                         >
                           {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                         </IconButton>
                       </InputAdornment>
                     }
                     label="Confirm Password"
                   />
                 </FormControl>
                  
                 <div>
                   {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                     <div className="input-error">{formik.errors.confirmPassword}</div>
                   ) : null}
                 </div>
               </div>
               <div>
              <button type="submit" className="default-btn w-100">submit</button>

                 {/* <Button
                  className="default-btn w-100"
                   variant="contained"
                   className="default-btn"
                   color="primary"
                   size="small"
                   type="submit"
                 >
                   submit
                 </Button> */}
               </div>
             </form>
            {successMessage && <p className="text-success small">{successMessage}</p>}
            {registrationErrorMessage && (
              <div className="alert alert-danger">{registrationErrorMessage}</div>
            )}
          </div>
        </div>
      )}

      {showOtpModal && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ background: "rgba(0, 0, 0, 0.5)", zIndex: 1051 }}
          onClick={closeAdminPopup}
        >
          <div
            className="card p-4"
            style={{
              width: "350px",
              borderRadius: "20px",
              background: "linear-gradient(to bottom, #ffffff, #f0f8ff)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h5 className="text-center mb-3" style={{ color: "#000000" }}>
              Verify OTP
            </h5>
            <div className="form-group mb-3">
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="form-control"
                style={{ borderRadius: "15px", borderColor: "#d1d3e2" }}
              />
            </div>
            {/* <button
              type="button"
              onClick={handleVerifyOtp}
              className="btn btn-dark w-100"
              style={{ borderRadius: "15px" }}
            >
              Verify OTP
            </button> */}
            <button type="button" onClick={handleVerifyOtp} className="default-btn w-100">Verify Otp</button>
            {otpErrorMessage && (
              <div className="alert alert-danger mt-3">{otpErrorMessage}</div>
            )}
          </div>
        </div>
      )}

      {/* <CreatePasswordPopup
        showPopup={showPasswordPopup}
        closePopup={() => setShowPasswordPopup(false)}
      /> */}
    </>
  );
};

export default AdminRegistration;
