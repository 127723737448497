import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import { FaBriefcase, FaMapMarkerAlt, FaEye, FaRupeeSign } from "react-icons/fa";
import { formatDistanceToNow, parseISO } from "date-fns";
import moment from 'moment';


const JobDetailPage = () => {
  const [jobDetails, setJobDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [joblist, setJoblist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("role");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const navigate = useNavigate();

  const requiredFields = ["firstName", "email", "totalExperience", "salary", "expectedSalary", "skills"];

  const toggleResumePopup = () => {
    const missingFields = requiredFields.filter(field => !userDetails?.[field]);

    if (missingFields.length > 0) {
      setShowValidationError(true);
    } else {
      sessionStorage.setItem("JobId", id)
      sessionStorage.setItem("Postedby", jobDetails.postedById)
      navigate("/jobseeker/apply");
    }
  };

  const closeValidationErrorPopup = () => setShowValidationError(false);


  const getFormattedTime = (createdAt) => {
    if (!createdAt) {
      return 'Invalid date'; 
    }

    try {
      const parsedDate = parseISO(createdAt); 
      if (isNaN(parsedDate)) {
        return 'Invalid date'; 
      }
      return formatDistanceToNow(parsedDate, { addSuffix: true }); 
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid date'; 
    }
  };

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setJobDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching job data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [id, token, apiUrl]);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });
        setJoblist(response.data.data);
      } catch (error) {
        console.error("Error fetching job data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [token, apiUrl]);

  useEffect(() => {
   
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setUserDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if(userId){
      fetchUserData();

    }
  }, [userId, token, apiUrl]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <p className="text-center text-danger">
        Error fetching job details. Please try again later.
      </p>
    );
  }

  if (!jobDetails) {
    return <p className="text-center">No job details available.</p>;
  }

  
  const filteredJobs = joblist?.filter(job => job.jobId !== jobDetails.jobId).slice(0, 5); 

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />

      <Container fluid className="content">
        <Row className="g-4">
          <EmployerDashboardSideBar />

          <Col md={10} className="main-content">
            <Row>
              <Col md={12}>
                <h5 className="mb-3 fw-bold">Job Details</h5>
                <div className="d-flex">
                  <div className="job-detail-container" style={{ width: "55%", marginRight: "4rem" }}>
                    <div className="job-card p-4 rounded-3" style={{ backgroundColor: "#fff" }}>
                      <h5 className="card-title">{jobDetails?.jobTitle}</h5>
                      {/* <h5 className="card-title">{jobDetails?.jobTitle}</h5> */}
                      <h6 className="card-subtitle mb-2 mt-1 text-muted">{jobDetails?.jobCategory}</h6>

                      <div className="d-flex mb-3" style={{ gap: "4rem" }}>
                        <div className="card-text">
                          <FaBriefcase className="me-2" />
                          {jobDetails?.totalExperience + " Years "}
                        </div>
                        <div className="card-text">
                          <FaRupeeSign className="me-2" />
                          {jobDetails?.salary ? jobDetails?.salary : "Not disclosed"}
                        </div>
                        <div className="card-text">
                        <FaMapMarkerAlt className="me-2" />
                        {jobDetails?.location}
                      </div>
                        
                      </div>
                      {/* <p className="card-text m-0">
                          Total Application:20 
                        </p> */}
                   

                      <div className="d-flex justify-content-between mt-lg-5 align-items-center">
                        <p className="card-text m-0">
                          Posted: {getFormattedTime(jobDetails?.createdAt)} 
                        </p>
                        
                        <div>
                          {userRole !== "employer" && (
                            <>
                              <button className="save-button-1">Save</button>
                              <button className="apply-button-1" onClick={toggleResumePopup}>
                                Apply
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="job-description-card mt-4 p-4" style={{ boxShadow: "0px 0px 17.6px 10px #ABDDF240" }}>
                      <div>{jobDetails?.description}</div>
                      <div className="mt-3">{jobDetails?.responsibilitiesAndDuties}</div>
                      
                        <div className="d-flex justify-content-between mt-lg-5 align-items-center">
                        <p className="card-text m-0">
                          Total Application:20 
                        </p>
                        <p className="card-text m-0">
                          created at: {moment(jobDetails?.createdAt).format('MMMM Do YYYY')} 
                        </p>
                        <p className="card-text m-0">
                          Expiry at: {moment(jobDetails?.expiryDate).format('MMMM Do YYYY')} 
                        </p>
                        </div>
                    </div>
                  </div>

                  <div className="right-sidebar" style={{ width: "35%" }}>
                    <div className="card-2 p-4" style={{ backgroundColor: "rgb(171, 221, 242)" }}>
                      <h3 className="card-text" style={{ fontSize: "1rem" }}>
                        Jobs you might be interested in
                      </h3>
                      {filteredJobs?.map(job => (
                        <div key={job.id} className="job-item my-3">
                          <h4 className="card-title">
                            <Link to={`/viewdetails/${job.jobId}`}>
                              {job.jobTitle}
                            </Link>
                          </h4>
                          <p className="card-text">{job.companyName}</p>
                          <p className="card-text">{job.location}</p>
                          <p className="card-text" style={{ fontSize: "0.9em" }}>Posted {getFormattedTime(job.createdAt)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={showValidationError} onHide={closeValidationErrorPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Incomplete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Some required fields are missing in your profile. Please complete your profile to apply.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeValidationErrorPopup}>
            Close
          </Button>
          <Button variant="primary" onClick={() => navigate("/jobseekerprofile")}>
            Complete Profile
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobDetailPage;
