import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import admin from '../../assets/admin.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
   return (
      <>
         <Navbar className="custom-navbar shadow-navbar" sticky="top" expand="lg" bg="white" variant="light">
            <Container fluid>
               {/* Logo */}
               <Navbar.Brand>
                  <img src={logo} loading="lazy" alt="Company Logo" width={200} />
               </Navbar.Brand>
               
               {/* Static Navbar Content */}
               <div className="d-flex align-items-center">
                  {/* Static Text */}
                  <span className="me-3">Welcome, User</span>
                  {/* Static Profile Image */}
                  <img
                     src={admin}
                     loading="lazy"
                     alt="Admin Profile"
                     width={40}
                     className="border rounded-circle"
                  />
               </div>
            </Container>
         </Navbar>
      </>
   );
};

export default Header;
