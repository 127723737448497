import { Col, Nav } from 'react-bootstrap';
import { FaHome, FaBriefcase, FaUser, FaEnvelope, FaClipboardList, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoHome } from "react-icons/io5";
import '../Styles/EmployerDashboardSidebar.css'
import home from '../assets/sidebaricons/home.avif'
import postedjobs from '../assets/sidebaricons/postedjobs.jpg'
import post1jobs from '../assets/sidebaricons/post1jobs.avif'
import findjobs from '../assets/sidebaricons/findjob.avif'
import alljobs from '../assets/sidebaricons/alljobs.avif'

const EmployerDashboardSideBar = () => {
  const userRole = sessionStorage.getItem('role');
  const navigate = useNavigate()


  const renderSidebarContent = () => {
    switch (userRole) {
      case "employer":
        return (
          // <Col md={2} className="sidebar">
          // {/* <div className="mb-4">
          //   <span className="category-name">Dashboard</span>
          // </div> */}
          
          <Nav className="flex-column">
          <div className="sidebar-item sidebar-itemss">
          <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/" className="nav text-decoration-none jobtypes-side namesjobseeker ">
      Home
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/applicants" className="nav text-decoration-none jobtypes-side namesjobseeker">
      Posted Jobs
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={post1jobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/jobDescriptionForm" className="nav text-decoration-none jobtypes-side namesjobseeker">
      Post Job
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/Joblist" className="nav text-decoration-none jobtypes-side namesjobseeker">
      All Jobs
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/AllCandidates" className="nav text-decoration-none jobtypes-side namesjobseeker">
      All Jobseekers
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="#" onClick={() => handleNavClick()} className="nav text-decoration-none jobtypes-side namesjobseeker">
      Contact
    </Nav.Link>
  </li>

  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/employerProfileView" onClick={() => handleNavClick()} className="nav text-decoration-none jobtypes-side namesjobseeker">
      Profile
    </Nav.Link>
  </li>


</ul>

     </div>

          </Nav>
        // </Col>
        );
      case "superadmin":
        return (
          <Nav className="flex-column">
            <div className="sidebar-item">
              <Nav.Link
                href="/admin-dashboard"
                className="nav text-decoration-none text-dark"
              >
                <FaHome size={20} /> Dashboard
              </Nav.Link>
            </div>
            <div className="sidebar-item">
             <Nav.Link
                href="/employerTable" 
                 className="nav text-decoration-none text-dark"
              >
              <FaUser size={20} /> Employer
             </Nav.Link>
            </div>
             {/* <div className="sidebar-item">
              <Nav.Link href="/employerCreateProfile" className="text-decoration-none text-dark">
                <FaBriefcase size={20} /> Employer add
              </Nav.Link>
            </div> */}
            <div className="sidebar-item">
              <Nav.Link
                href="/jobSeekerTable"
                className="nav text-decoration-none text-dark"
              >
                <FaUser size={20} /> Jobseeker
              </Nav.Link>
            </div>
            <div className="sidebar-item">
            <Nav.Link href="/Joblist" className="nav text-decoration-none text-dark">
              <FaBriefcase size={20} /> Jobs
            </Nav.Link>
            </div>
            <div className="sidebar-item">
            <Nav.Link href="/jobDescriptionForm" className="nav text-decoration-none text-dark">
              <FaBriefcase size={20} /> Post Job
            </Nav.Link>
            </div>
          {/* <div className="sidebar-item">
            <Nav.Link href="/allAdminList" className="nav text-decoration-none text-dark">
              <FaBriefcase size={20} /> All Admins
            </Nav.Link>
            </div>
            <div className="sidebar-item">
            <Nav.Link href="/allCallCenterList" className="nav text-decoration-none text-dark">
              <FaBriefcase size={20} /> All CallCenter
            </Nav.Link>
            </div> */}
            <div className="sidebar-item">
              <Nav.Link href="#" className="nav text-decoration-none text-dark">
                <FaUser size={20} /> Reports
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="#" onClick={()=>handleNavClick} className="nav text-decoration-none text-dark">
                <FaEnvelope size={20} /> Contact
              </Nav.Link>
            </div>
          </Nav>
        );
      case "admin":
        return (
          <Nav className="flex-column">
            <div className="sidebar-item">
            <Nav.Link href="/" className="nav text-decoration-none text-dark">
              <FaHome size={20} /> Home
            </Nav.Link>  
            </div>
            <div className="sidebar-item">
              <Nav.Link
                href="/admin-dashboard"
                className="nav text-decoration-none text-dark"
              >
                <FaHome size={20} /> Dashboard
              </Nav.Link>
            </div>
            <div className="sidebar-item">
            <Nav.Link href="/applicants" className="nav text-decoration-none text-dark">
              <FaUser size={20} /> Applicants
              </Nav.Link>
            </div>
            <div className="sidebar-item">
            <Nav.Link href="/jobDescriptionForm" className="nav text-decoration-none text-dark">
              <FaBriefcase size={20} /> Post Job
              </Nav.Link>
            </div>
            <div className="sidebar-item">
            <Nav.Link href="/Joblist" className="nav text-decoration-none text-dark">

              <FaEnvelope size={20} />All Jobs
              </Nav.Link>

            </div>
            <div className="sidebar-item">
              <Nav.Link href="/applyJobAllJobSeeker" className="text-decoration-none text-dark">
               <FaUser size={20} /> Apply jobs
              </Nav.Link>
            </div>  
            <div className="sidebar-item">
            <Nav.Link href="/AllCandidates" className="nav text-decoration-none text-dark">
              <FaUsers size={20} /> All Jobseeker
              </Nav.Link>

            </div>
            <div className="sidebar-item">
            <Nav.Link href="#" onClick={()=>handleNavClick} className="nav text-decoration-none text-dark">
              <FaEnvelope size={20} /> Contact
              </Nav.Link>

            </div>
          </Nav>
        );
        case "callcenter":
          return (
            <Nav className="flex-column">
            <div className="sidebar-item">
              <Nav.Link
                href="/admin-dashboard"
                className="nav text-decoration-none text-dark"
              >
                <FaHome size={20} /> Dashboard
              </Nav.Link>
            </div>
            {/* <div className="sidebar-item">
             <Nav.Link
                href="/employerTable" 
                 className="text-decoration-none text-dark"
              >
              <FaUser size={20} /> Employer
             </Nav.Link>
            </div> */}
            <div className="sidebar-item">
              <Nav.Link
                href="/jobSeekerTable"
                className="nav text-decoration-none text-dark"
              >
                <FaUser size={20} /> Jobseeker
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="/Joblist" className="nav text-decoration-none text-dark">
              <FaBriefcase size={20} /> Jobs
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="/applyJobAllJobSeeker" className="text-decoration-none text-dark">
               <FaUser size={20} /> Apply jobs
              </Nav.Link>
            </div>  
            <div className="sidebar-item">
              <Nav.Link href="#" className="nav text-decoration-none text-dark">
                <FaUser size={20} /> Reports
              </Nav.Link>
            </div>
            <div className="sidebar-item">
              <Nav.Link href="#" onClick={()=>handleNavClick} className="nav text-decoration-none text-dark">
                <FaEnvelope size={20} /> Contact
              </Nav.Link>
            </div>
          </Nav>
          );  
      default:
        return (
           
          <Nav>
          <div className="sidebar-item sidebar-itemss">
          <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/" className="nav text-decoration-none jobtypes-side namesjobseeker ">
      Home
    </Nav.Link>
  </li>
 
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/appliedjob" className="nav text-decoration-none jobtypes-side namesjobseeker">
      Applied Jobs
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/Joblist" className="nav text-decoration-none jobtypes-side namesjobseeker">
      All Jobs
    </Nav.Link>
  </li>
 
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="/jobseekerprofile" className="nav text-decoration-none jobtypes-side namesjobseeker">
      Profile
    </Nav.Link>
  </li>
  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
  <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
    <Nav.Link href="#" onClick={() => handleNavClick()} className="nav text-decoration-none jobtypes-side namesjobseeker">
      Contact
    </Nav.Link>
  </li>
</ul>

     </div>

        </Nav>
        );
    }
  };


  const handleNavClick=()=>{
    navigate("/contact", { state: { Roles:userRole } });
  }

  return (
    <>

     <Col md={2} className="sidebar">
        {/* <div className="mb-4">
          <span className="category-name">Dashboard</span>
        </div> */}
        {renderSidebarContent()}
      </Col>
     
      {/* {userRole === 'employer' ? (
        <Col md={2} className="sidebar">
          <div className="mb-4">
            <span className="category-name">Dashboard</span>
          </div>
          <Nav className="flex-column">
            <Nav.Link href="/dashboard" className="sidebar-item">
              <FaHome size={20} /> Home
            </Nav.Link>
            <Nav.Link href="/applicants" className="sidebar-item">
              <FaUser size={20} /> Applicants
            </Nav.Link>
            <Nav.Link href="/jobDescriptionForm" className="sidebar-item">
              <FaBriefcase size={20} /> Post Job
            </Nav.Link>
            <Nav.Link href="/Joblist" className="sidebar-item">
              <FaEnvelope size={20} /> All Jobs
            </Nav.Link>
            <Nav.Link href="/AllCandidates" className="sidebar-item">
              <FaUsers size={20} /> All Candidates
            </Nav.Link>
            <Nav.Link href="/Contact" className="sidebar-item">
              <FaEnvelope size={20} /> Contact
            </Nav.Link>
          </Nav>
        </Col>
      ) : (
        <Col md={2} className="sidebar">
          <div className="mb-4">
            <span className="category-name">Dashboard</span>
          </div>
          <Nav className="flex-column">
            <Nav.Link href="/" className="sidebar-item">
              <FaHome size={20} /> Home
            </Nav.Link>
            <Nav.Link href="/appliedjob" className="sidebar-item">
              <FaUser size={20} /> Applied Job
            </Nav.Link>
            <Nav.Link href="/Joblist" className="sidebar-item">
              <FaBriefcase size={20} /> Jobs
            </Nav.Link>
            <Nav.Link href="/jobseekerprofile" className="sidebar-item">
              <FaUser size={20} /> Profile
            </Nav.Link>
            <Nav.Link href="/Contact" className="sidebar-item">
              <FaEnvelope size={20} /> Contact
            </Nav.Link>
          </Nav>
        </Col>
      )} */}
    </>
  );
};

export default EmployerDashboardSideBar;
