import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const JobDescription = () => {
  const { id } = useParams(); // Get the job description ID from the URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    jobTitle: '',
    skills: '',
    qualification: '',
    workLocation: '',
    totalExperience: '',
    responsibilities: '',
    salary: '',
    benefitDetails: '',
    shifts: '',
    workWeek: '',
    miscDetails: '',
    positions: '',
    submissionDate: '',
    expiryDate: '',
    termsAndConditions: '',
  });
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch job description data
  useEffect(() => {
    const fetchJobDescription = async () => {
      const token = sessionStorage.getItem('accessToken');
      try {
        const response = await axios.get(
          `${apiUrl}/employer/jobdescription/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        if (response.status === 200 && response.data.status) {
          const jobData = response.data.data[0]; // Access the first element in the data array
          setFormData({
            jobTitle: jobData.jobTitle || '',
            skills: jobData.skill || '',
            qualification: jobData.qualification || '',
            workLocation: jobData.workLocation || '',
            totalExperience: jobData.totalExperience || '',
            responsibilities: jobData.responsibilities || '',
            salary: jobData.salary || '',
            benefitDetails: jobData.benefitDetails || '',
            shifts: jobData.shift || '',
            workWeek: jobData.workWeek || '',
            miscDetails: jobData.miscDetails || '',
            positions: jobData.noOfPosition || '',
            submissionDate: jobData.submissionDate
              ? jobData.submissionDate.split('T')[0]
              : '',
            expiryDate: jobData.expiryDate
              ? jobData.expiryDate.split('T')[0]
              : '',
            termsAndConditions: jobData.termsAndcondition || '',
          });
          setError(null);
        } else {
          setError('Failed to fetch job description.');
        }
      } catch (error) {
        console.error('Error fetching job description:', error);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchJobDescription();
    }
  }, [id]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.put(
        `${apiUrl}/employer/job-descriptions/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        alert('Job description updated successfully!');
        navigate('/job-listings'); // Redirect to the listings page after update
      } else {
        alert('Failed to update job description.');
      }
    } catch (error) {
      console.error('Error updating job description:', error);
      alert('An error occurred while updating the job description.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <>
      <h2 className="text-center mb-4">Edit Job Description</h2>
      <div
        className="container-fluid my-5"
        style={{
          maxWidth: '1200px',
        }}
      >
        <form
          className="p-4 mx-auto"
          style={{
            width: '90%',
            maxWidth: '80%',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
          onSubmit={handleSubmit}
        >
          {Object.keys(formData).map((field, index) => {
            const isTextarea = ['skills', 'responsibilities', 'termsAndConditions', 'qualification', 'miscDetails'].includes(field);
            const isDate = ['submissionDate', 'expiryDate'].includes(field);
            const isNumber = ['positions', 'totalExperience', 'salary'].includes(field);

            return (
              <div className="mb-3" key={index}>
                <label className="form-label">
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}*
                </label>
                {isTextarea ? (
                  <textarea
                    required
                    className="form-control"
                    rows="2"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    placeholder={`Enter the ${field}...`}
                  ></textarea>
                ) : isDate ? (
                  <input
                    required
                    type="date"
                    className="form-control"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                  />
                ) : (
                  <input
                    required
                    type={isNumber ? 'number' : 'text'}
                    className="form-control"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    placeholder={`Enter the ${field}...`}
                  />
                )}
              </div>
            );
          })}
          <div className="text-center mt-4">
         
            <button
              type="submit"
              className="btn btn-primary btn-lg w-20"
              style={{ backgroundColor: '#4CAF50', borderColor: '#4CAF50' }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default JobDescription;
