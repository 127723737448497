import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, ListGroup, Navbar, Nav } from 'react-bootstrap';
import svgImage from '../assets/workaroo_logo.png';
import { useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";
import  Header from "../components/Header";
const JobListPage = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    jobTitle: '',
    categories: [],
    location: '',
    active: null,
  });
  const apiUrl = process.env.REACT_APP_BASE_URL;
 
  const categories = ['software', 'Welder', 'Machine Operator', 'Electrician', 'Machine_operator'];
 
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${apiUrl}/employer/job-descriptions`);
        const data = await response.json();
 
        if (data && data.status && data.data) {
          const processedJobs = data.data.map((job) => {
            const isExpired = new Date(job.expiryDate) < new Date();
            return {
              ...job,
              active: !isExpired,
            };
          });
 
          setJobs(processedJobs);
        }
      } catch (error) {
        console.error('Error fetching job descriptions:', error);
      }
    };
 
    fetchJobs();
  }, []);
 
  const deleteJob = async (jobDescriptionId) => {
    const confirmed = window.confirm('Are you sure you want to delete this job?');
    if (!confirmed) return;
 
    try {
      const response = await fetch(`${apiUrl}/employer/job-descriptions/${jobDescriptionId}`, {
        method: 'DELETE',
      });
 
      if (response.ok) {
        setJobs((prevJobs) => prevJobs.filter((job) => job.jobDescriptionId !== jobDescriptionId));
        alert('Job deleted successfully.');
      } else {
        alert('Failed to delete the job. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting job:', error);
      alert('An error occurred while deleting the job.');
    }
  };
 
  const handleFilterChange = (e, filterType) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
 
    setFilters((prevFilters) => {
      const updatedFilter = isChecked
        ? [...prevFilters[filterType], value]
        : prevFilters[filterType].filter((item) => item !== value);
 
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  };
 
  const handleJobTitleChange = (e) => {
    setFilters({ ...filters, jobTitle: e.target.value.toLowerCase() });
  };
 
  const handleActiveChange = (e) => {
    setFilters({ ...filters, active: e.target.value === 'true' });
  };
 
  const handleLocationChange = (e) => {
    setFilters({ ...filters, location: e.target.value.toLowerCase() });
  };
 
  const filteredJobs = jobs.filter((job) => {
    const matchesTitle = filters.jobTitle
      ? job.jobTitle.toLowerCase().includes(filters.jobTitle)
      : true;
 
    const matchesCategory = filters.categories.length
      ? filters.categories.includes(job.category.categoryName)
      : true;
 
    const matchesLocation = filters.location
      ? job.location && job.location.toLowerCase().includes(filters.location)
      : true;
 
    const matchesActive =
      filters.active !== null
        ? filters.active
          ? job.active
          : !job.active
        : true;
 
    return matchesTitle && matchesCategory && matchesLocation && matchesActive;
  });
 
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar bg="white" variant="light" expand="lg" className="shadow-sm">
        <Container>
          <Navbar.Brand href="#">Workaroo</Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link href="employerdashboard">Home</Nav.Link>
            <Nav.Link href="/Contact">Contact</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
 
      <Container fluid className="py-4">
        <Row>
          <Col md={3} className="bg-light p-4 border-end rounded-3">
            <h4 className="mb-4">Filters</h4>
            <Form.Group className="mb-3">
              <Form.Label>Search by Job Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Job title or description"
                value={filters.jobTitle}
                onChange={handleJobTitleChange}
              />
            </Form.Group>
 
            <Form.Label>Category</Form.Label>
            <ListGroup className="mb-3">
              {categories.map((category) => (
                <ListGroup.Item key={category} className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label={category}
                    value={category}
                    onChange={(e) => handleFilterChange(e, 'categories')}
                    checked={filters.categories.includes(category)}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
 
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search by location"
                value={filters.location}
                onChange={handleLocationChange}
              />
            </Form.Group>
 
            <Form.Group className="mb-3">
              <Form.Label>Active Status</Form.Label>
              <Form.Select onChange={handleActiveChange}>
                <option value="">All</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Col>
 
          <Col md={9}>
            <h2 className="mb-4">Job Listings</h2>
            {filteredJobs.length > 0 ? (
              filteredJobs.map((job, index) => (
                <Card className="mb-4 job-card shadow-sm border-0 position-relative" key={index}>
                  <Card.Body>
                    <Row>
                      <Col md={2} className="d-flex align-items-center justify-content-center">
                        <img
                          src={svgImage}
                          alt="Company Logo"
                          style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                        />
                      </Col>
                      <Col md={8}>
                        <h5>{job.jobTitle}</h5>
                        <p className="mb-1"><strong>Location:</strong> {job.workLocation}</p>
                        <p className="mb-1"><strong>Category:</strong> {job.category.categoryName}</p>
                        <p className="mb-1"><strong>Subcategory:</strong> {job.subcategory.subcategoryName}</p>
                        <p className="mb-0" style={{ fontWeight: 'bold', color: '#555' }}>
                          Expiry Date: {new Date(job.expiryDate).toLocaleDateString()}
                        </p>
                      </Col>
                      <Col md={2} className="text-end" style={{ marginTop: '35px' }}>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="mb-2 w-100"
                          onClick={() => navigate(`/jobdescription/${job.jobDescriptionId}`)}
                        >
                          Edit
                        </Button>
 
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="w-100"
                          onClick={() => deleteJob(job.jobDescriptionId)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
 
                  <div
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '20px',
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: job.active ? '#28a745' : '#dc3545',
                    }}
                  ></div>
                </Card>
              ))
            ) : (
              <p>No jobs match the selected filters.</p>
            )}
          </Col>
        </Row>
      </Container>
 
      <footer className="bg-dark text-white py-3 mt-auto">
        <Container>
          <p className="mb-0 text-center">© 2024 Workaroo. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
};

export default JobListPage; 
