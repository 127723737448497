import React, { createContext, useContext } from 'react';
import { generateId } from '../utils/uuidGenerator';

const UuidContext = createContext();

export const UuidProvider = ({ children }) => {
    const generateId = (type) => {
      const validTypes = ['employer', 'company', 'job', 'user']; // Add valid types
      if (!validTypes.includes(type)) {
        throw new Error('Invalid type specified for ID generation.');
      }
      return `${type}_${Math.random().toString(36).substr(2, 9)}`;
    };
  
    return (
      <UuidContext.Provider value={{ generateId }}>
        {children}
      </UuidContext.Provider>
    );
  };

export const useUuid = () => useContext(UuidContext);
