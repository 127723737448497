import React, { useState } from "react";

const ResumeBuilder = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    phoneNumber: "",
    address: "",
    shortBio: "",
    degree: "",
    degreeCGPA: "",
    school: "",
    schoolPercentage: "",
    branch: "",
    skills: "",
    experience: [],
    isFresher: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRadioChange = (e) => {
    const isFresher = e.target.value === "fresher";
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      phoneNumber: "",
      address: "",
      shortBio: "",
      degree: "",
      degreeCGPA: "",
      school: "",
      schoolPercentage: "",
      branch: "",
      skills: "",
      experience: [],
      isFresher,
    });
  };

  const addExperience = () => {
    setFormData((prevData) => ({
      ...prevData,
      experience: [...prevData.experience, { jobTitle: "", company: "", duration: "", location: "" }],
    }));
  };

  const removeExperience = (index) => {
    const updatedExperience = formData.experience.filter((_, i) => i !== index);
    setFormData({ ...formData, experience: updatedExperience });
  };

  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedExperience = [...formData.experience];
    updatedExperience[index] = { ...updatedExperience[index], [name]: value };
    setFormData({ ...formData, experience: updatedExperience });
  };

  const renderSectionLine = () => {
    return <hr className="section-line" />;
  };

  return (
    <div className="resume-builder">
      {/* Left Section: Input Form */}
      <div className="form-section">
        <h2>Your Details</h2>

        {/* Radio Buttons: Fresher or Experienced */}
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="status"
              value="fresher"
              checked={formData.isFresher}
              onChange={handleRadioChange}
            />
            Fresher
          </label>
          <label>
            <input
              type="radio"
              name="status"
              value="experienced"
              checked={!formData.isFresher}
              onChange={handleRadioChange}
            />
            Experienced
          </label>
        </div>

        {/* Personal Details Section */}
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="Enter first name"
            required
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Enter last name"
            required
          />
        </div>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter email address"
            required
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder="Enter phone number"
          />
        </div>
        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            placeholder="Enter address"
          />
        </div>

        {/* Job Title Dropdown Section */}
        <div className="form-group">
          <label>Job Title</label>
          <select
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Job Title</option>
            <option value="Service Designer">Service Designer</option>
            <option value="Web Developer">Web Developer</option>
            <option value="UI/UX Designer">UI/UX Designer</option>
            <option value="Project Manager">Project Manager</option>
            <option value="Software Engineer">Software Engineer</option>
            <option value="Data Scientist">Data Scientist</option>
            <option value="Business Analyst">Business Analyst</option>
          </select>
        </div>

        {/* Skills Section */}
        <div className="form-group">
          <label>Skills</label>
          <input
            type="text"
            name="skills"
            value={formData.skills}
            onChange={handleInputChange}
            placeholder="Enter skills"
          />
        </div>

        {/* Education Section */}
        <div className="form-group">
          <label>School</label>
          <input
            type="text"
            name="school"
            value={formData.school}
            onChange={handleInputChange}
            placeholder="Enter your school"
            required
          />
        </div>
        <div className="form-group">
          <label>School Percentage</label>
          <input
            type="text"
            name="schoolPercentage"
            value={formData.schoolPercentage}
            onChange={handleInputChange}
            placeholder="Enter your school percentage"
            required
          />
        </div>
        <div className="form-group">
          <label>Degree</label>
          <select name="degree" value={formData.degree} onChange={handleInputChange} required>
            <option value="">Select Degree</option>
            <option value="B.Tech">B.Tech</option>
            <option value="M.Tech">M.Tech</option>
          </select>
        </div>
        <div className="form-group">
          <label>Degree CGPA</label>
          <input
            type="text"
            name="degreeCGPA"
            value={formData.degreeCGPA}
            onChange={handleInputChange}
            placeholder="Enter your CGPA"
          />
        </div>
        <div className="form-group">
          <label>Branch</label>
          <select name="branch" value={formData.branch} onChange={handleInputChange} required>
            <option value="">Select Branch</option>
            <option value="Computer Science">Computer Science</option>
            <option value="Mechanical">Mechanical</option>
            <option value="Electrical">Electrical</option>
          </select>
        </div>

        {/* Experience Section */}
        {!formData.isFresher && (
          <>
            <h3>Experience</h3>
            {formData.experience.map((exp, index) => (
              <div key={index} className="form-group">
                <label>Job Title</label>
                <input
                  type="text"
                  name="jobTitle"
                  value={exp.jobTitle}
                  onChange={(e) => handleExperienceChange(e, index)}
                  placeholder="Enter job title"
                  required
                />
                <label>Company</label>
                <input
                  type="text"
                  name="company"
                  value={exp.company}
                  onChange={(e) => handleExperienceChange(e, index)}
                  placeholder="Enter company name"
                  required
                />
                <label>Duration</label>
                <input
                  type="text"
                  name="duration"
                  value={exp.duration}
                  onChange={(e) => handleExperienceChange(e, index)}
                  placeholder="Enter job duration"
                  required
                />
                <label>Location</label>
                <input
                  type="text"
                  name="location"
                  value={exp.location}
                  onChange={(e) => handleExperienceChange(e, index)}
                  placeholder="Enter job location"
                />
                <div className="remove-experience-btn-container">
                  <button type="button" className="remove-experience-btn" onClick={() => removeExperience(index)}>
                    Remove Experience
                  </button>
                </div>
              </div>
            ))}
            <div className="add-experience-btn-container">
              <button type="button" className="add-experience-btn" onClick={addExperience}>
                Add Experience
              </button>
            </div>
          </>
        )}
      </div>

      {/* Right Section: Resume Preview */}
      <div className="resume-preview">
        <div className="resume-header">
          <h1>{formData.firstName} {formData.lastName}</h1>
          <p>{formData.jobTitle}</p>
        </div>

        {/* Personal Details */}
        <div className="personal-details">
          <p>{formData.email}</p>
          {formData.phoneNumber && <p>{formData.phoneNumber}</p>}
          {formData.address && <p>{formData.address}</p>}
          {formData.shortBio && <p>{formData.shortBio}</p>}
        </div>

        {renderSectionLine()}

        {/* Education Section */}
        <div className="education">
          <h3>Education</h3>
          <p>{formData.school}, {formData.schoolPercentage}%</p>
          <p>{formData.degree}, {formData.degreeCGPA}</p>
          <p>{formData.branch}</p>
        </div>

        {renderSectionLine()}

        {/* Skills Section */}
        {formData.skills && (
          <div className="skills-section">
            <h3>Skills</h3>
            <p>{formData.skills}</p>
          </div>
        )}

        {renderSectionLine()}

        {/* Experience Section */}
        {formData.experience.length > 0 && (
          <div className="experience">
            <h3>Experience</h3>
            {formData.experience.map((exp, index) => (
              <div key={index}>
                <p>{exp.jobTitle}</p>
                <p>{exp.company}</p>
                <p>{exp.duration}</p>
                <p>{exp.location}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeBuilder;
