import React from "react";
import workaroo from '../assets/workaroo_logo.png';
import { CiEdit } from "react-icons/ci";
import { MdDeleteForever } from "react-icons/md";
 
 
const JobDescriptionPage = () => {
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f4f4f9",
    padding: "20px",
  };
 
  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#57BBE5",
    padding: "15px 30px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
   
  };
 
  const navStyle = {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  };
 
  const navLinkStyle = {
    textDecoration: "none",
    color: "#ffffff",
    fontWeight: "bold",
  };
 
  const buttonStyle = {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    color: "#210d25",
    cursor: "pointer",
    fontWeight: "bold",
    marginLeft: "10px",
  };
 
  const buttonHoverStyle = {
    backgroundColor: "#ffffff",
  };
 
  const imageStyle = {
    width: "150px",
  };
 
  const sidebarStyle = {
    width: "20%",
    padding: "20px",
    backgroundColor: "#57BBE5",
    borderRight: "1px solid #ddd",
    height: "100vh",
    overflowY: "auto",
  };
 
  const contentStyle = {
    width: "80%",
    padding: "20px",
  };
 
  const cardStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: "15px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    transition: "box-shadow 0.3s ease, transform 0.3s ease",
  };
 
  const cardHoverStyle = {
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    transform: "translateY(-5px)",
  };
 
  const jobCodeStyle = {
    backgroundColor: "#57BBE5",
    height: "100px",
    padding: "10px",
    borderRadius: "5px",
    fontWeight: "bold",
    textAlign: "center",
    alignItems:"center",
    color: "#ffffff",
  };
 
  const jobInfoStyle = {
    flex: 1,
    padding: "0 15px",
  };
 
  const actionIconsStyle = {
    display: "flex",
    gap: "10px",
  };
 
  const actionButtonStyle = {
    backgroundColor: "#57BBE5",
    border: "1px solid #ddd",
    borderRadius: "5px",
    cursor: "pointer",
    padding: "10px",
    transition: "background-color 0.3s ease",
  };
 
  const actionButtonHoverStyle = {
    backgroundColor: "#57BBE5",
  };
 
  const filterStyle = {
    marginBottom: "15px",
    width:"150px",
    height:"50px",
    borderRadius:"5px",
    border:"none",
  };
 
  const checkboxStyle = {
    marginRight: "10px",
    border:"none",
  };
  const iconStyle = {
    color: "#ffffff",
  }
 
  return (
    <div style={containerStyle}>
      {/* Header */}
      <header style={headerStyle}>
        <img src={workaroo} alt="Workaroo Logo" style={imageStyle} />
        <nav style={navStyle}>
          <a href="#" style={navLinkStyle}>
            Find Jobs
          </a>
          <a href="#" style={navLinkStyle}>
            Call Support
          </a>
          <a href="#" style={navLinkStyle}>
            Contact
          </a>
          <button
            style={{ ...buttonStyle, ...buttonHoverStyle }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#57BBE5")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#57BBE5")}
          >
            Employer Login
          </button>
          <button
            style={{ ...buttonStyle, ...buttonHoverStyle }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#57BBE5")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#57BBE5")}
          >
            Jobseeker Login
          </button>
        </nav>
      </header>
 
      {/* Main Content */}
      <div style={{ display: "flex", marginTop: "20px" }}>
        {/* Sidebar */}
        <aside style={sidebarStyle}>
          <h4>Search by Job Title</h4>
          <input type="text" placeholder="Job title or company" style={filterStyle} />
 
          <h4>Category</h4>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Plumber
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Welder
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Electrician
            </label>
          </div>
 
          <h4>Job Type</h4>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Full Time
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Part Time
            </label>
          </div>
 
          <h4>Experience Level</h4>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> No Experience
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Fresher
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Intermediate
            </label>
          </div>
 
          <h4>Location</h4>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Bangalore
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" style={checkboxStyle} /> Delhi
            </label>
          </div>
        </aside>
 
        {/* Main Content */}
        <section style={contentStyle}>
          <h3>Showing employer job description details</h3>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              style={{ ...cardStyle }}
              onMouseEnter={(e) =>
                Object.assign(e.currentTarget.style, cardHoverStyle)
              }
              onMouseLeave={(e) =>
                Object.assign(e.currentTarget.style, { boxShadow: "none", transform: "none" })
              }
            >
              <div style={jobCodeStyle}>
                Job Code: <br /> ARFBANPB011
              </div>
              <div style={jobInfoStyle}>
                <p>
                  <strong>Job Description:</strong> Plumber
                </p>
                <p>
                  <strong>Job Categories:</strong> Pipeline plumber
                </p>
                <p>
                  <strong>Job SubCategories:</strong> Tubing Fittings
                </p>
              </div>
              <div style={jobInfoStyle}>
                <p>
                  <strong>Job Location:</strong> Bangalore
                </p>
                <p>
                  <strong>Job Active:</strong>
                </p>
                <p>
                  <strong>Job Expired:</strong> 1 Days Ago
                </p>
              </div>
              <div style={actionIconsStyle}>
                <button
                  style={actionButtonStyle}
                  onMouseEnter={(e) =>
                    Object.assign(e.target.style, actionButtonHoverStyle)
                  }
                  onMouseLeave={(e) =>
                    Object.assign(e.target.style, { backgroundColor: "#57BBE5" })
                  }
                >
                  <CiEdit style={iconStyle} />
                </button>
                <button
                  style={actionButtonStyle}
                  onMouseEnter={(e) =>
                    Object.assign(e.target.style, actionButtonHoverStyle)
                  }
                  onMouseLeave={(e) =>
                    Object.assign(e.target.style, { backgroundColor: "#57BBE5" })
                  }
                >
                  <MdDeleteForever style={iconStyle} />
                </button>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};
 
export default JobDescriptionPage;