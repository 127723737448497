import React from "react";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import aboutimg from "../../assets/Aboutus.jpeg";
import aboutimg2 from "../../assets/Aboutus1.jpeg";
import  aboutVideo  from "../../assets/Aboutvideo.mp4"
import '../../Styles/Aboutus.css'
 
const AboutUs = () => {
    return (
        <>
            <EmployerDashboardHeader />
            <div className="container">
                <div className="row">
                     <h6 className="text-black fs-1 text-center my-2">About Us</h6>
                
                    <div className="col-lg-6 col-md-12">
                        <div>
                            <ul className="text-muted aboutul" style={{ listStyleType: "none" }}>
                                <li>
                                At Workaroo, we are dedicated to transforming the blue-collar recruitment
                        landscape through innovation and technology. Our AI-powered platform
                        connects skilled workers with employers in industries where finding the
                        right talent is crucial to business success. Founded with the mission to
                        bridge the gap between employment opportunities and the skilled workforce,
                        Workaroo ensures that businesses can access pre-vetted, qualified
                        candidates efficiently and that workers find jobs aligned with their skills
                        and career goals. We believe that blue-collar workers are the backbone of
                        industry, and our goal is to empower them by providing access to meaningful
                        employment opportunities, skill development resources, and career growth
                        pathways. By leveraging cutting-edge technology and a human-first
                        approach, Workaroo is committed to creating a more efficient, inclusive,
                        and fair job market.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
  <div style={{ marginTop: "70px" }}>
    <video
      height={"350px"}
      width={"100%"}
      style={{ borderRadius: "20px" }}
      controls
    >
      <source src={aboutVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</div>

                    {/* Image and Content Section */}
                    <div className="col-lg-6 col-md-12">
                        <div style={{ marginTop: "70px" }}>
                            <img
                                height={"350px"}
                                width={"100%"}
                                src={aboutimg}
                                style={{ borderRadius: "20px" }}
                                alt="About Us"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div>
                            <ul className="text-muted aboutul" style={{ listStyleType: "none" }}>
                                <h1 className="text-black fs-2">What We Do</h1>
                                <li>
                                    At Workaroo, we address one of the most critical challenges
                                    faced by both employers and blue-collar workers—finding the
                                    right fit in the job market. Our AI-powered platform streamlines
                                    the recruitment process by intelligently matching employers with
                                    skilled candidates, ensuring businesses quickly find the talent
                                    they need, and workers are placed in jobs that align with their
                                    abilities.
                                </li>
                                <li>
                                    AI-Powered Matching: Our advanced algorithms analyze worker
                                    profiles, including skills and experience, and match them with
                                    job opportunities that suit their qualifications and location
                                    preferences.
                                </li>
                                <li>
                                AI-Based Interviews: We conduct AI-driven interviews that evaluate candidates' responses, offering employers deeper insights into their suitability for the job. These AI-based interviews save time by pre-screening candidates, allowing businesses to focus on the most qualified individuals.
                                </li>
                                {/* <li>
                                Multilingual Support: We offer a multilingual interface to ensure the platform is accessible to workers from diverse backgrounds, breaking down language barriers and enhancing inclusivity.
                                Video and Audio Profiles: Our platform allows workers to showcase their skills and personalities through video and audio profiles, providing employers with a more holistic view of each candidate.
                                </li>
                                <li>
                                Skill Development: Beyond job placement, we focus on long-term employability by offering resources and tools for skill enhancement, ensuring workers stay relevant in a competitive job market.
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* Second Section */}
                    <div className="col-lg-6 col-md-12">
                        <div>
                            <ul className="text-muted aboutul" style={{ listStyleType: "none" }}>
                                <h6 className="text-black fs-2">Whom We Serve</h6>
                                {/* <h6>Workaroo proudly serves both Employers and Jobseekers.</h6>
                                <h6>Employers:</h6> */}
                                <li>
                                We cater to businesses across a wide range of industries, including construction, manufacturing, logistics, hospitality, agriculture, and more. Our platform is designed to meet the needs of both small and medium enterprises (SMEs) and large enterprises that rely on blue-collar talent. Whether you're looking for skilled labor to fill temporary, seasonal, or long-term roles, Workaroo simplifies recruitment by connecting you with pre-vetted candidates who match your requirements.
                                </li>
 
                                <h6>Blue-Collar Workers:</h6>
                                <li>
                                We serve the skilled and semi-skilled workers who form the backbone of industries. From electricians, welders, and plumbers to drivers, machine operators, and construction laborers, Workaroo provides access to job opportunities that match your skills, experience, and career aspirations. Our platform also offers resources to help you develop new skills and advance in your career, ensuring long-term employability.
                                </li>
                                {/* <li>
                                By focusing on both ends of the employment spectrum, Workaroo creates a mutually beneficial ecosystem where businesses find the right talent, and workers find the right opportunities.
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 my-3">
                        <div style={{ marginTop: "40px" }}>
                            <img
                                height={"350px"}
                                width={"100%"}
                                src={aboutimg2}
                                style={{ borderRadius: "20px" }}
                                alt="Who We Serve"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Responsive Styles */}
            <style jsx>{`
                @media (max-width: 768px) {
                    .abouthead {
                        font-size: 14px;
                    }    
                    .aboutul h1,
                    .aboutul h6 {
                        font-size: 18px;
                    }  
                    img {
                        height: auto;
                        width: 100%;
                    }    
                }
            `}</style>
        </>
    );
};
 
export default AboutUs;