import JobList from "../pages/JobseekerJobList";
import Profile from "../pages/EmployerCreateProfile";
import Contact from "../components/Contact";
import JobListPage from "../pages/JobseekerJobList";


export const jobSeekerRoutes = [
  { path: "/jobseeker/jobs", element: <JobList /> },
  { path: "/jobseeker/profile", element: <Profile /> },
  { path: "/contact", element: <Contact /> },
  { path: "/joblist", element: <JobListPage /> },
  // { path: "/joblist", element: <JobListPage /> },
  // { path: "/joblist", element: <JobListPage /> },
  // { path: "/joblist", element: <JobListPage /> },
  // { path: "/joblist", element: <JobListPage /> },
];
