import React, { useState } from 'react';
import { Navbar, Container, Nav, Modal, Button } from 'react-bootstrap';
import '../assets/styles/main.css';
import logo from '../assets/workaroo_logo.png';
import profile from '../assets/Frame2.png';
const Header = ({
  role,
  admin,
  handleLogoutConfirmation,
  handleLogout,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return (
    <Navbar className={`custom-navbar ${isLoggedIn ? 'shadow-navbar' : 'shadow-navbar'}`} sticky="top" expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand>
          <img src={logo} alt="Logo" width={200} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-lg-5 gap-5">
            <Nav.Link href="/Contact">Call Support</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
              <div className="dropdown">
                <button
                  onClick={handleDropdownToggle}
                  className="btn btn-link p-0 dropdown-toggle-btn"
                  aria-expanded={isDropdownOpen}
                >
                  <img src={profile} alt="Admin" width={40} className="border rounded-circle" />
                </button>
                {isDropdownOpen && (
                  <div className="dropdown-menu dropdown-menu-end show">
                    {role === '"employer"' || role === 'employee' ? (
                      <a href="/employerdashboard" className="dropdown-item">Dashboard</a>
                    ) : (
                      <a href="/jobseekerCreateProfile" className="dropdown-item">Profile</a>
                    )}
                    <a href="#" onClick={handleLogoutConfirmation} className="dropdown-item">Logout</a>
                  </div>
                )}
              </div>
          </Nav>
        </Navbar.Collapse>
      </Container>

      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutConfirmation} onHide={() => setShowLogoutConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutConfirmation(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleLogout}>Logout</Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};

export default Header;
