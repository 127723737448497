// import { v4 as uuidv4 } from 'uuid';

// export const generateId = (type) => {
//   const id = uuidv4();
//   switch (type) {
//     case 'employer':
//       return `EMP-${id}`;
//     case 'job':
//       return `JOB-${id}`;
//     case 'user':
//       return `USR-${id}`;
//     default:
//       throw new Error('Invalid type specified for ID generation.');
//   }
// };