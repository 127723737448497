import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; 
import login from '../assets/login.jpg';
import AdminRegistration from './admin/AdminRegistration';
import axios from "axios";
import EmployerRegistration from '../components/EmployerRegistration';
import { toast } from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For visibility icons


const LoginPage = ({  }) => {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('admin');
  const [error, setError] = useState('');
  const [showAdminPopup, setShowAdminPopup] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const openPopup = () => {
    setShowAdminPopup(true);
    // navigate("/admin/registration")
  }
  const closePopup = () => setShowAdminPopup(false);

  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   if (email === 'admin@admin.com' && password === 'admin123' && role === 'admin') {
  //     navigate('/admin-dashboard');
  //   } else if (email === 'manager@manager.com' && password === 'manager123' && role === 'manager') {
  //     navigate('/admin-dashboard');
  //   } else if (email === 'callcenter@callcenter.com' && password === 'callcenter123' && role === 'callcenter') {
     
  //     navigate('/admin-dashboard');
  //   } else {
  //     setError('Invalid credentials or role.');
  //   }
  //   sessionStorage.setItem("role",role);
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    let isValid = true;

    // setEmailErrorMessage("");
    // setPasswordErrorMessage("");
    // setLoginErrorMessage("");

    // if (!email) {
    //   // setEmailErrorMessage("Email is required.");
    //   isValid = false;
    // }


    // if (!password) {
    //   // setPasswordErrorMessage("Password is required.");
    //   isValid = false;
    // }

    // if (!username) {
    //   setPasswordErrorMessage("Username is required.");
    //   isValid = false;
    // }

    if (!isValid) return;

    // setSuccessMessage("Logging in...");
    try {
      const response = await axios.post(`${apiUrl}/user/admin/login`, {
          'username': userName, 
          'password':password,
          'role': role
        }
      );

      if (response.status) {
        // handleLogin()
        const data = response.data;
        sessionStorage.setItem("accessToken", data.data.accessToken);
        sessionStorage.setItem("role",data.data.role);
        sessionStorage.setItem("userId",data.data.userId);
        sessionStorage.setItem("userName",data.data.userName);
        // onLogin(data?.data?.role)

        navigate("/admin-dashboard");
        toast.success("Login successful");  
      } else {
        toast.error(response?.data?.message);  
        
        // setLoginErrorMessage(
        //   response.data.message || "Invalid Email or Password."
        // );
        // setSuccessMessage("");
      }
    } 
    catch (error) {
      toast.error(error?.response?.data?.message);  

      
      // setLoginErrorMessage("Error logging in. Please try again.");
      // setSuccessMessage("");
    }
  };

  return (
    <Container fluid className="vh-100">
      <Row className="h-100">
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center text-white p-5"
          style={{
            background: `url(${login}) no-repeat center center/cover`,
            backgroundSize: 'cover',
          }}
        >
        </Col>

        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center bg-light p-5">
          <div className="w-100" style={{ maxWidth: '400px' }}>
            <div className="text-center mb-4">
              <img src={logo} alt="Logo" style={{ width: '200px', height: 'auto' }} />
            </div>

            <Form onSubmit={handleLogin} className="shadow-lg p-4 rounded bg-white">
              {error && <div className="alert alert-danger">{error}</div>}

              {/* <Form.Group controlId="email" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoFocus
                />
              </Form.Group> */}

              <Form.Group controlId="username" className="mb-3">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                  autoFocus
                />
              </Form.Group>

              {/* <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group> */}
              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={isPasswordVisible ? 'text' : 'password'} // Toggle between text and password
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="position-absolute eye-button"
                  >
                    {isPasswordVisible ?   <FaEye />:<FaEyeSlash />}
                  </button>
                </div>
              </Form.Group>

              <Form.Group controlId="role" className="mb-4">
                <Form.Label>Select Role</Form.Label>
                <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)}>
                  <option value="superadmin">Super Admin</option>
                  <option value="admin">Admin</option>
                  <option value="callcenter">Call Center</option>
                </Form.Control>
              </Form.Group>

              <Button type="submit" className="default-btn w-100">
                Login
              </Button>
            </Form>

            <div className="text-center mt-3">
              <small>
                Don't have an account?{" "}
                <button
                  type="button"
                  className="btn p-0"
                  onClick={openPopup}
                  style={{ fontSize: "14px", color: "#014C92" }}
                >
                  Sign Up
                </button>
                {showAdminPopup && (
                  <AdminRegistration
                    showAdminPopup={showAdminPopup}
                    closeAdminPopup={closePopup}
                  />
                  // <EmployerRegistration
                  //   showPopup={showPopup}
                  //   closePopup={closePopup}
                  // />
                )}
              </small>
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
