import React from "react";
import img1 from '../assets/Location.png'
import img2 from '../assets/Bag.png'

const Jobstatus = () => {
    const jobData = [
        {
            title: "Plumber",
            company: "W GOA",
            rating: "3.5",
            reviews: "783 Reviews",
            experience: "1-4 Yrs",
            salary: "₹ Not disclosed",
            location: "Bangalore",
            posted: "30 days",
            openings: 1,
            applicants: 696,
        },
        {
            title: "Electrician / Plumber Licensed",
            company: "Leams Education",
            rating: "3.53",
            reviews: "383 Reviews",
            experience: "1-4 Yrs",
            salary: "₹ Not disclosed",
            location: "Bangalore",
            posted: "10 days",
            openings: 20,
            applicants: 390,
        },
        {
            title: "Technician / Mechanic",
            company: "Bright 4 wheel",
            rating: "3.5",
            reviews: "150 Reviews",
            experience: "1-6 Yrs",
            salary: "₹ Not disclosed",
            location: "Tenkasi",
            posted: "10 days",
            openings: 20,
            applicants: 390,
        },
    ];

    const jobCardStyle = {
        backgroundColor: "#b3e3ff",
        margin: "20px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        fontSize: "14px",
    };

    const jobTitleStyle = {
        fontSize: "20px",
        margin: "0",
        color: "#333",
    };

    const companyStyle = {
        fontSize: "16px",
        color: "#555",
        marginTop: "5px",
    };

    const detailsStyle = {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        color: "#333",
    };

    const detailItemStyle = {
        marginRight: "15px",
        display: "flex",
        alignItems: "center",
    };

    const ratingStyle = {
        color: "#f5c518",
        fontWeight: "bold",
    };

    const footerStyle = {
        fontSize: "13px",
        marginTop: "15px",
        color: "#333",
    };

    const moreOptionsStyle = {
        float: "right",
        fontSize: "50px",
        color: "#666",
        position: 'relative',
        bottom: '60px',
        right: '40px',
    };

    return (
        <div>

            {jobData.map((job, index) => (
                <div key={index} style={jobCardStyle}>
                    <h2 style={jobTitleStyle}>{job.title}</h2>
                    <div style={companyStyle}>
                        {job.company} <span style={ratingStyle}>★ {job.rating} - {job.reviews}</span>
                    </div>
                    <div style={detailsStyle}>
                        <span style={detailItemStyle}>
                            <img src={img2} alt="Experience" style={{ marginRight: "5px", width: "15px" }} />
                            {job.experience}
                        </span>
                        <span style={detailItemStyle}>
                            <img style={{ marginRight: "5px" }} />
                            {job.salary}
                        </span>
                        <span style={detailItemStyle}>
                            <img src={img1} style={{ marginRight: "5px", width: "15px"}} />
                            {job.location}
                        </span>
                    </div>
                    <div style={footerStyle}>
                        Posted: {job.posted} &nbsp; | &nbsp; Opening: {job.openings} &nbsp; | &nbsp; Applicants: {job.applicants}
                    </div>
                    <div style={moreOptionsStyle}>⋮</div>
                </div>
            ))}
        </div>
    );
};

export default Jobstatus;
