import PostJob from "../pages/Postjob";
import Profile from "../pages/EmployerCreateProfile";
import EmployerDashboard from "../pages/EmployerDashboad";
import AppliedJob from "../pages/AppliedJob";
import EmployerProfileView from "../pages/EmployerProfileView";
import JobDescriptionForm from "../pages/Postjob";



export const employerRoutes = [
  { path: "/employer/dashboard", element: <EmployerDashboard /> },
  { path: "/employer/post-job", element: <PostJob /> },
  { path: "/employer/profile", element: <Profile /> },
  { path: "/appliedjob", element: < AppliedJob/> },
  { path: "/employerProfileView", element: < EmployerProfileView/> },
  { path: "/jobDescriptionForm", element: < JobDescriptionForm/> },
];
