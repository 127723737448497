import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Table, Form, Spinner, InputGroup } from 'react-bootstrap'; 
import { FaEye } from 'react-icons/fa';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';

const ApplicantsListPage = () => {
  const [jobData, setJobData] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    jobTitle: "",
    jobCategory: "",
    totalExperience: "",
    salary: "",
    location: "" 
  });

  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setJobData(response.data.data);
        setFilteredJobData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setError("Failed to fetch job data.");
        setLoading(false);
      }
    };

    fetchJobs();
  }, [token, apiUrl]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };
      filterData(updatedFilters);
      return updatedFilters;
    });
  };

 
  const filterData = (filters) => {
    let filteredData = jobData;

    if (filters.jobTitle) {
      filteredData = filteredData.filter((job) =>
        job.jobTitle?.toLowerCase().includes(filters.jobTitle.toLowerCase())
      );
    }

    if (filters.jobCategory) {
      filteredData = filteredData.filter((job) =>
        job.jobCategory?.toLowerCase().includes(filters.jobCategory.toLowerCase())
      );
    }

    if (filters.totalExperience) {
      filteredData = filteredData.filter((job) =>
        job.totalExperience >= filters.totalExperience
      );
    }

    if (filters.salary) {
      filteredData = filteredData.filter((job) =>
        job.salary >= filters.salary
      );
    }

    if (filters.location) {
      filteredData = filteredData.filter((job) =>
        job.location?.toLowerCase().includes(filters.location.toLowerCase())
      );
    }

    setFilteredJobData(filteredData);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  
  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="dashboard">
      < EmployerDashboardHeader />

      <Container fluid className="content">
        <Row className="g-4">
        <EmployerDashboardSideBar/>

          <Col md={10} className="main-content">
            <Row>
              <Col md={12}>
                <h5 className="mb-3 fw-bold">Applicant Listing</h5>
                <div className="card border-0 rounded-0">
                  <div className="card-body table-responsive">
                    <div className="filters mb-3">
                      <Row className="g-2">
                        <Col md={3}>
                          <InputGroup>
                            <InputGroup.Text>Title</InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="jobTitle"
                              value={filters.jobTitle}
                              onChange={handleFilterChange}
                              placeholder="Search by title"
                            />
                          </InputGroup>
                        </Col>
                        <Col md={2}>
                          <InputGroup>
                            <InputGroup.Text>Category</InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="jobCategory"
                              value={filters.jobCategory}
                              onChange={handleFilterChange}
                              placeholder="Search by category"
                            />
                          </InputGroup>
                        </Col>
                        <Col md={2}>
                          <InputGroup>
                            <InputGroup.Text>Experience</InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="totalExperience"
                              value={filters.totalExperience}
                              onChange={handleFilterChange}
                              placeholder="Min experience"
                            />
                          </InputGroup>
                        </Col>
                        <Col md={2}>
                          <InputGroup>
                            <InputGroup.Text>Salary</InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="salary"
                              value={filters.salary}
                              onChange={handleFilterChange}
                              placeholder="Min Salary"
                            />
                          </InputGroup>
                        </Col>
                        <Col md={2}>
                          <InputGroup>
                            <InputGroup.Text>Location</InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="location"
                              value={filters.location}
                              onChange={handleFilterChange}
                              placeholder="Search by location"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>

                    <Table className="table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Category</th>
                          <th>Experience</th>
                          <th>₹ Salary/Month</th>
                          <th>Location</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredJobData.length > 0 ? (
                          filteredJobData.map((job) => (
                            <tr key={job.id}>
                              <td>{job.jobTitle}</td>
                              <td>{job.jobCategory}</td>
                              <td>{job.totalExperience}</td>
                              <td>{job.salary}</td>
                              <td>{job.location}</td>
                              <td>
                                <Link to={`/viewdetails/${job.jobId}`} className="text-info">
                                  <FaEye size={20} /> 
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No jobs found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      
    </div>
    
  );
};

export default ApplicantsListPage;
