import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner } from 'react-bootstrap';
import { Card, Form} from 'react-bootstrap'; // Add Card here
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../EmployerDashboard.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import moment from 'moment';
import { toast } from 'react-hot-toast';


const Applicant = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const employerid = sessionStorage.getItem("userId");


  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
const [filter, setFilter] = useState({
      name: '',
      location: '',
    });
      const [filteredApplicants, setFilteredApplicants] = useState([]);
    useEffect(() => {
        setFilteredApplicants(applicants); // Initialize filteredApplicants with all applicants initially
      }, [applicants]);
      
      const applyFilters = () => {
        const filtered = applicants.filter((applicant) => {
          return (
            (filter.jobTitle ? (applicant.jobTitle && applicant.jobTitle.toLowerCase().includes(filter.jobTitle.toLowerCase())) : true) &&
            (filter.location ? (applicant.location && applicant.location.toLowerCase().includes(filter.location.toLowerCase())) : true)
          );
        });
        setFilteredApplicants(filtered); // Update filteredApplicants with filtered results
      };
      
      const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({
          ...filter,
          [name]: value,
        });
      };
    

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        // const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
        //   headers: {
        //     'Authorization': `${token}`,
        //     'Content-Type': 'application/json',
        //   },
        // });
        const response = await axios.get(`${apiUrl}/job/posted/${employerid}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response?.data?.data)) {
          setApplicants(response?.data?.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, []);


  const handleViewClick = async (applicant) => {
    // setSelectedApplicant(applicant);
    
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }
        // 
        const response = await axios.get(`${apiUrl}/applications/job/${applicant?.jobId}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response?.data?.data)) {
          // setApplicants(response.data.data);
          setSelectedApplicant(response?.data?.data);
         setShowDetailsModal(true);


          // setFilteredApplicants(response.data.data); 
        } else {
          // throw new Error('Expected data to be an array');
          toast.error('Expected data to be an array')
        }
      } catch (err) {
        // setError(err.message);
        toast.error(err?.response?.data?.error)


      } finally {
        setLoading(false);
      }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div className="dashboard">

       < EmployerDashboardHeader /> 
         <Container fluid className="content">
              <Row className="">
              <EmployerDashboardSideBar/>
                <Col md={10} className="main-content">
                      <h5 className='mb-3 fw-bold'>Posted Jobs</h5>






                      <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Form>
                  <Row className="justify-content-between">
                    <Col sm={3}>
                      <Form.Group controlId="jobTitle">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="jobTitle"
                          value={filter?.jobTitle}
                          onChange={handleFilterChange}
                          placeholder="Job Title"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="location">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type="text"
                          name="location"
                          value={filter?.location}
                          onChange={handleFilterChange}
                          placeholder="Location"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2} className="d-flex align-items-end" style={{marginTop:"27px"}}>
                      <Button style={{backgroundColor:" rgb(47 39 97)"}}  
                      onClick={applyFilters} 
                      className="btn w-100">
                        Apply Filters
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>











                      <div className='card border-0 rounded-0'>
                        <div className='card-body table-responsive'>
                          <Table hover striped  className="table">
                          <thead>
                              <tr className="bg-light">
                                <th>Job Title</th>
                                <th>Start Date</th>
                                <th>Expiry Date</th>
                                <th>Experience</th>
                                <th>₹ Salary/Month</th>
                                <th>Loaction</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                            {filteredApplicants.length === 0 && !loading ? (
  <tr>
    <td colSpan="7" className="text-center">
      No matching jobs found
    </td>
  </tr>
) : (
  filteredApplicants.map((applicant) => (
    <tr key={applicant?.id} className="table-row">
      <td>{applicant?.jobTitle}</td>
      <td>{moment(applicant?.startDate).format('MMMM Do YYYY')}</td>
      <td>{moment(applicant?.expiryDate).format('MMMM Do YYYY')}</td>
      <td>{applicant?.totalExperience}</td>
      <td>{applicant?.salary}</td>
      <td>{applicant?.location}</td>

      <td>
        <Link onClick={() => handleViewClick(applicant)} className="btn btn-info btn-sm">
          View
        </Link>
      </td>
    </tr>
  ))
)}
                              </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
              </Row>
      
            </Container>
            <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Applicant Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* {selectedApplicant && (
                        <div>
                          <p><strong>Name:</strong> {selectedApplicant.firstName} {selectedApplicant.lastName}</p>
                          <p><strong>Email:</strong> {selectedApplicant.emailAddress}</p>
                          <p><strong>Phone:</strong> {selectedApplicant.phoneNumber}</p>
                          <p><strong>Address:</strong> {selectedApplicant.address}</p>
                          <p><strong>Location:</strong> {selectedApplicant.location}</p>
            
                          <p><strong>Experience:</strong> {selectedApplicant.experience}</p>
                          <p><strong>Status:</strong> {selectedApplicant.status}</p>
                        </div>
                      )} */}
                      {/* {selectedApplicant && (
                       <div>
                         {Object.entries(selectedApplicant)
                           .filter(([key]) => ['firstName', 'lastName', 'emailAddress', 'phoneNumber'].includes(key))
                           .map(([key, value]) => {
                             let label = key === 'firstName' || key === 'lastName' ? 'Name' : key === 'emailAddress' ? 'Email' : 'Phone';
                             return (
                               <p key={key}>
                                 <strong>{label}:</strong> {key === 'firstName' ? `${value} ${selectedApplicant.lastName}` : value}
                               </p>
                             );
                           })}
                       </div>
                     )} */}

                     {selectedApplicant?.map((app, index) => (
                           <p key={index}>
                             <strong>Application ID:</strong> {app?.applicationId} - {app?.jobSeeker?.userName}
                           </p>                      
                         ))}

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
    </div>
  );
};

export default Applicant;
