import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jobImage from '../assets/rb_802.png';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { Container, Col, Row, Table, Badge, Nav, Button, Navbar, Form, Modal, Card } from 'react-bootstrap';
import { FaHome, FaBriefcase, FaUser, FaEnvelope, FaClipboardList, FaUsers, FaChartLine } from 'react-icons/fa';
import Swal from 'sweetalert2';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { useNavigate } from 'react-router-dom';
import EmployerRegistration from '../components/EmployerRegistration';
import SubscriptionPacks from './Employee/Subscription/SubscriptionPacks';
import {  Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from 'react-hot-toast';
import '../Styles/jobseekerjoblist.css'


  const JobDescriptionForm = () => {

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const token = sessionStorage.getItem('accessToken');
    const [open, setOpen] = useState(false);
    const userId = sessionStorage.getItem("userId");

        
        
    const handleOpen = () => {
      setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };


    useEffect(() => {
      const token = sessionStorage.getItem('accessToken');
      if (token) {
        setIsLoggedIn(true);
      }
        const fetchProfile = async () => {
          try {
            const response = await axios.get(`${apiUrl}/subscription/getPlanDetailsForUser/${userId}`, {
              headers: {
                Authorization: `${token}`,
              },
            });
            console.log(response);
            if (response.status === 200 || response.status === 201) {
              
              console.log("Response Status:", response.status);
              if (response.data && response.data.data) {
              const userData = response.data.data;
              console.log(userData);
              
              
            } else {
              console.error("Invalid data format in response.");
            }
          } else {
            console.error(`Unexpected status code: ${response.status}`);
          }
          } catch (err) {
              handleOpen()

            if (err.response) {
              const { status } = err.response;
              if (status === 400) {
              
              } else if (status === 401) {
                toast.error("Unauthorized: Please check your authentication token.");
              } else if (status === 404) {
                toast.error("Not Found: Unable to find the requested resource.");
              } else {
                toast.error(`Error ${status}:`, err.response.data.message || err.message);
              }
            } else {
              toast.error("Network error or server not reachable:", err.message);
            }
          }
        };
    
        fetchProfile();

    }, []);

//     const [formData, setFormData] = useState({
//       jobTitle: '',
//       totalExperience: '',
//       jobLocation: '',
//       salary: '',
//       companyName: '',
//       jobType: [],
//       category_id: '',
//       categoryName: '',
//       subcategory_id: '674af135e938a95a587969e8',
//       industryId: '674b02b57a85d68f70dce160',
//       qualifications: '',
//       responsibilities: [],
//       skills: [],
//       totalExperience: '',
//       salary: '',
//       hoursPerWeek: '',
//       jobPosition: '',
//       miscDetails: '',
//       termsAndConditions: '',
//       specialRequirements: '',
//       noOfPosition: 1,
//       submissionDate: '',
//       expiryDate: '',
//       benefitsDetails: '',
//       shift: '',
//       workWeek: 'y',
//     });

// const win =window.localStorage;


const [formData, setFormData] = useState({
  jobTitle: '',
  totalExperience: '',
  jobLocation: '',
  salary: '',
  companyName: '',
  jobType: [],
  category_id: '',
  categoryName: '',
  subcategory_id: '674af135e938a95a587969e8',
  industryId: '674b02b57a85d68f70dce160',
  qualifications: '',
  responsibilities: [],
  skills: [],
  hoursPerWeek: '',
  jobPosition: '',
  miscDetails: '',
  termsAndConditions: '',
  specialRequirements: '',
  noOfPosition: 1,
  submissionDate: '',
  expiryDate: '',
  benefitsDetails: '',
  shift: '',
  workWeek: 'y',
});

const win = window.localStorage;

useEffect(() => {
  // Load form data from localStorage when the component mounts
  const savedFormData = {
    jobTitle: win.getItem('jobTitle') || '',
    totalExperience: win.getItem('totalExperience') || '',
    jobLocation: win.getItem('jobLocation') || '',
    salary: win.getItem('salary') || '',
    companyName: win.getItem('companyName') || '',
    category_id: win.getItem('category_id') || '',
    subcategory_id: win.getItem('subcategory_id') || '674af135e938a95a587969e8',
    industryId: win.getItem('industryId') || '674b02b57a85d68f70dce160',
    qualifications: win.getItem('qualifications') || '',
    responsibilities: JSON.parse(win.getItem('responsibilities')) || [],
    skills: JSON.parse(win.getItem('skills')) || [],
    hoursPerWeek: win.getItem('hoursPerWeek') || '',
    jobPosition: win.getItem('jobPosition') || '',
    miscDetails: win.getItem('miscDetails') || '',
    termsAndConditions: win.getItem('termsAndConditions') || '',
    specialRequirements: win.getItem('specialRequirements') || '',
    noOfPosition: win.getItem('noOfPosition') || 1,
    submissionDate: win.getItem('submissionDate') || '',
    expiryDate: win.getItem('expiryDate') || '',
    benefitsDetails: win.getItem('benefitsDetails') || '',
    shift: win.getItem('shift') || '',
    workWeek: win.getItem('workWeek') || 'y',
  };

  setFormData(savedFormData);
}, []); // Empty dependency array, runs once when the component mounts

const handleChange = async (e) => {
  const { name, value } = e.target;

  // Update the form data state
  setFormData({ ...formData, [name]: value });

  // Save the updated value to localStorage
  win.setItem(name, value);

  // For skills and responsibilities arrays, store them as strings
  if (name === 'skills' || name === 'responsibilities') {
    win.setItem(name, JSON.stringify(value.split(',').map(val => val.trim())));
  }

  // Fetch subcategories when a category is selected
  if (name === 'category_id') {
    if (value) {
      fetchSubcategories(value); // Fetch subcategories based on selected category
    } else {
      setSubcategories([]); // Clear subcategories when no category is selected
    }
  }
};


    const [errors, setErrors] = useState({});
    const [categories, setCategories] = useState([]);



    // const handleChange = async (e) => {
    //   const { name, value } = e.target;
    //   setFormData({ ...formData, [name]: value });

    //   // Fetch subcategories when a category is selected
    //   if (name === 'category_id') {
    //     if (value) {
    //       try {
    //         const fetchedSubcategories = await fetchSubcategories(value); // Pass category ID to fetch function
    //         setSubcategories(fetchedSubcategories);
    //       } catch (error) {
    //         console.error('Error fetching subcategories:', error);
    //         setSubcategories([]); // Clear subcategories if error occurs
    //       }
    //     } else {
    //       setSubcategories([]); // Clear subcategories if no category is selected
    //     }
    //   }
    // };
    useEffect(() => {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(`${apiUrl}/category`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCategories(response.data.data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

      fetchCategories();
    }, []);

  const [industries, setIndustries] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

    useEffect(() => {
      const fetchIndustries = async () => {
        try {
          const response = await axios.get(`${apiUrl}/industries`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setIndustries(response.data.data);
        } catch (error) {
          console.error('Error fetching industries:', error);
        }
      };
    
      fetchIndustries();
    }, []);
    
    // useEffect(() => {
      const fetchSubcategories = async (categoryId) => {
        try {
          const response = await axios.get(`${apiUrl}/sub-category/category/${categoryId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data.data;  // Assuming `data` holds the subcategories
        } catch (error) {
          console.error('Error fetching subcategories:', error);
          return [];  // Return an empty array if there’s an error
        }
      };
      
    
    
    const handleIndustryChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        industryId: value,
      });
    };
    
    const handleSubcategoryChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        subcategory_id: value,
      });
    };
    

    const handleNextStep = () => {
      if (!validateStep(currentStep)) return;
      setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
      setCurrentStep(currentStep - 1);
    };

    const apiUrl = process.env.REACT_APP_BASE_URL;

    const validateStep = (step) => {
      const newErrors = {};
    
      if (step === 1) {
        if (!formData.jobTitle) newErrors.jobTitle = 'Job title is required';
        if (!formData.totalExperience || isNaN(formData.totalExperience)) newErrors.totalExperience = 'Total experience must be a number';
        if (!formData.jobLocation) newErrors.jobLocation = 'Job location is required';
        if (!formData.salary || isNaN(formData.salary)) newErrors.salary = 'Salary must be a number';
        if (!formData.shift) newErrors.shift = 'Shift is required';
        if (!formData.submissionDate) newErrors.submissionDate = 'Submission date is required';
        if (!formData.expiryDate) newErrors.expiryDate = 'Expiry date is required';
        if (!formData.industryId || formData.industryId === "") {
          newErrors.industryId = 'Industry is required';
        }
        if (!formData.category_id || formData.category_id === "") {
          newErrors.category_id = 'Category is required';
        }
        if (!formData.subcategory_id || formData.subcategory_id === "") {
          newErrors.subcategory_id = 'Subcategory is required';
        }
    
      }
    
      if (step === 2) {
        if (!formData.responsibilities) newErrors.responsibilities = 'Responsibilities are required';
        if (!formData.qualifications) newErrors.qualifications = 'Qualifications are required';
        if (!formData.benefitsDetails) newErrors.benefitsDetails = 'Benefit details are required';
        if (!formData.termsAndConditions) newErrors.termsAndConditions = 'Terms and Conditions are required';
        if (!formData.skills) newErrors.skills = 'Skills required';
        // if (!formData.miscDetails) newErrors.miscDetails = 'Misc Details are required';
      }
    
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
    


    const isValidDate = (dateString) => {
      const date = new Date(dateString);
      return !isNaN(date.getTime());
    };

    let formattedDate = "";
    if (isValidDate(formData.submissionDate)) {
      formattedDate = new Date(formData.submissionDate).toISOString();
    } else {
      console.error("Invalid submission date:", formData.submissionDate);
      formattedDate = new Date().toISOString();
    }
    let jobstartDate = "";
    if (isValidDate(formData.submissionDate)) {
      jobstartDate = new Date(formData.submissionDate).toISOString();
    } else {
      console.error("Invalid submission date:", formData.submissionDate);
      jobstartDate = new Date().toISOString();
    }
    let jobEndDate = "";
    if (isValidDate(formData.expiryDate)) {
      jobEndDate = new Date(formData.expiryDate).toISOString();
    } else {
      console.error("Invalid submission date:", formData.expiryDate);
      jobEndDate = new Date().toISOString();
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      if (!validateStep(2)) return;

      const totalExperienceIntFrom = parseInt(formData.totalExperience, 10);
      const salary = parseInt(formData.salary, 10);

      // Convert Array
      const skillsArray = formData.skills
        .trim()
        .split(',')
        .map(skill => skill.trim());

        const responsibilitiesAndDutiesArray = formData.responsibilities
        .trim()
        .split(',')
        .map(skill => skill.trim());

      const payload = {
        jobTitle: formData.jobTitle,
        reportsTo: formData.companyName,
        location: formData.jobLocation,
        startDate: jobstartDate,
        shift: formData.shift,
        expiryDate: jobEndDate,
        responsibilitiesAndDuties: responsibilitiesAndDutiesArray,
        benefitsDetails: formData.benefitsDetails,
        miscDetails: formData.miscDetails,
        skills: skillsArray,
        termsAndConditions: formData.termsAndConditions,
        qualificationRequirements: formData.qualifications,
        totalExperience: totalExperienceIntFrom,
        salary: salary,
        jobCategory: formData.categoryName || null,
        hoursPerWeek: formData.hoursPerWeek,
        postedById: sessionStorage.getItem('userId'),
        category: formData.category_id || null,
        subcategory: formData.subcategory_id,
        industry: formData.industryId,
        isDeleted: false,
        createdAt: formattedDate,
        updatedAt: formattedDate,
        jobType: "Full Time",
      };

      axios.post(`${apiUrl}/job`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          console.log('Job posted successfully:', response.data);

          Swal.fire({
            icon: 'success',
            title: 'Job Posted Successfully!',
            text: 'Your job description has been posted.',
            confirmButtonText: 'OK',
          }).then(() => {
            navigate('/applicants');
          });

          setFormData({
            jobTitle: '',
            totalExperience: '',
            jobLocation: '',
            salary: '',
            companyName: '',
            jobType: 'softwatr',
            category_id: '',
            subcategory_id: '',
            industryId: '',
            qualifications: '',
            responsibilities: [],
            skills: [],
            totalExperience: '',
            salary: 0,
            hoursPerWeek: 40,
            jobPosition: '',
            miscDetails: '',
            termsConditions: '',
            noOfPosition: 1,
            submissionDate: '',
            expiryDate: '',
            benefitsDetails: '',
            shift: '',
            workWeek: 'y',
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error)

          
        });

    };

    const handleCategoryChange = async (e) => {
      const { name, value } = e.target;
    
      // Update the form data state with the selected category
      setFormData({ ...formData, [name]: value });
    
      // Save to localStorage
      win.setItem(name, value);
    
      // If category is selected, fetch subcategories
      if (value) {
        try {
          const fetchedSubcategories = await fetchSubcategories(value);
          setSubcategories(fetchedSubcategories);
        } catch (error) {
          console.error('Error fetching subcategories:', error);
          setSubcategories([]); // Clear subcategories on error
        }
      } else {
        setSubcategories([]); // Clear subcategories if no category is selected
      }
    };
    
    
    

    return (
      <div>

        <div className="dashboard">
          < EmployerDashboardHeader />

          <Container fluid className="content">
            <Row className='g-4'>
              <EmployerDashboardSideBar />

              <Col md={10} className="main-content">
                <Row>
                  <Col md={12}>
                    <h5 className='mb-3 fw-bold'>Post Job Description</h5>
                    <div className='card border-0 rounded-0'>
                      <div className='card-body p-4'>
                        <form onSubmit={handleSubmit}>
                          {currentStep === 1 && (
                            <div>
                              <div className="row g-4">
                                <div className="col-md-4">
                                  <label className="form-label">Job Title <span className='text-danger'>*</span></label>
                                  <input type="text" name="jobTitle" value={formData.jobTitle} onChange={handleChange} className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                                  />
                                  {errors.jobTitle && (
                                    <div className="invalid-feedback">{errors.jobTitle}</div>
                                  )}
                                </div>
 
                                <div className="col-md-4">
  <label className="form-label">
    Industry <span className="text-danger">*</span>
  </label>
  <select
    name="industryId"
    value={formData.industryId}
    onChange={handleIndustryChange}
    className={`form-control ${errors.industryId ? 'is-invalid' : ''}`}
  >
    <option value="">Select an industry</option>
    {industries.length > 0 ? (
      industries.map((industry) => (
        <option key={industry.industryId} value={industry.industryId}>
          {industry.industryValue}
        </option>
      ))
    ) : (
      <option value="">No industries available</option>
    )}
  </select>
  {errors.industryId && (
    <div className="invalid-feedback">{errors.industryId}</div>
  )}
</div>

                                <div className="col-md-4">
                                  <label className="form-label">Job Category</label>
                                  <select
                                    name="category_id"
                                    value={formData.category_id}
                                    onChange={handleCategoryChange}
                                    className={`form-control ${errors.category_id ? 'is-invalid' : ''}`}
                                  >
                                    <option value="">Select a category</option>
                                    {Array.isArray(categories) && categories.length > 0 ? (
                                      categories.map((category) => (
                                        <option key={category.category_id} value={category.category_id}>
                                          {category.categoryName}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">No categories available</option>
                                    )}
                                  </select>

                                  {errors.category_id && (
                                    <div className="invalid-feedback">{errors.category_id}</div>
                                  )}
                                </div>
                                <div className="col-md-4">
  <label className="form-label">Subcategory</label>
  <select
    name="subcategory_id"
    value={formData.subcategory_id}
    onChange={handleSubcategoryChange}
    className={`form-control ${errors.subcategory_id ? 'is-invalid' : ''}`}
  >
    <option value="">Select a subcategory</option>
    {subcategories.length > 0 ? (
      subcategories.map((subcategory) => (
        <option key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
          {subcategory.subcategoryName}
        </option>
      ))
    ) : (
      <option value="">No subcategories available</option>
    )}
  </select>
  {errors.subcategory_id && <div className="invalid-feedback">{errors.subcategory_id}</div>}
</div>

                                <div className="col-md-4">
                                  <label className="form-label">Total Experience (Years) <span className='text-danger'>*</span></label>
                                  <input type="text" name="totalExperience" value={formData.totalExperience} onChange={handleChange} className={`form-control ${errors.totalExperience ? 'is-invalid' : ''}`}
                                  />
                                  {errors.totalExperience && (
                                    <div className="invalid-feedback">{errors.totalExperience}</div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label className="form-label">Job Location <span className='text-danger'>*</span></label>
                                  <input type="text" name="jobLocation" value={formData.jobLocation} onChange={handleChange} className={`form-control ${errors.jobLocation ? 'is-invalid' : ''}`}
                                  />
                                  {errors.jobLocation && (
                                    <div className="invalid-feedback">{errors.jobLocation}</div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label className="form-label">Salary <span className='text-danger'>*</span></label>
                                  <input type="text" name="salary" value={formData.salary} onChange={handleChange} className={`form-control ${errors.salary ? 'is-invalid' : ''}`}
                                  />
                                  {errors.salary && (
                                    <div className="invalid-feedback">{errors.salary}</div>
                                  )}
                                </div>
                                <div className="col-md-4">
    <label className="form-label">Shift Type <span className='text-danger'>*</span></label>
    <div className="custom-dropdown">
      <select style={{height:'35px'}}
        name="shift"
        value={formData.shift}
        onChange={handleChange}
        className={`form-control ${errors.shift ? 'is-invalid' : ''}`}
      >
        <option value="">Select a Shift</option>
        <option value="General shift(9 AM - 6 PM)">General shift (9 AM - 6 PM)</option>
        <option value="First shift(6 AM - 3 PM)">First shift (6 AM - 3 PM)</option>
        <option value="Second shift(2 PM - 11 PM)">Second shift (2 PM - 11 PM)</option>
        <option value="Night shift(10 PM - 7 AM)">Night shift (10 PM - 7 AM)</option>
      </select>
      <i className="fa fa-chevron-down custom-dropdown-arrow"></i>
    </div>
    {errors.shift && (
      <div className="invalid-feedback">{errors.shift}</div>
    )}
  </div>

                                <div className="col-md-4"></div>

                                <div className="col-md-4">
                                  <label className="form-label">Submission Date <span className='text-danger'>*</span></label>
                                  <input type="date" name="submissionDate" value={formData.submissionDate} onChange={handleChange} className={`form-control ${errors.submissionDate ? 'is-invalid' : ''}`}
                                  />
                                  {errors.submissionDate && (
                                    <div className="invalid-feedback">{errors.submissionDate}</div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label className="form-label">Expiry Date <span className='text-danger'>*</span></label>
                                  <input type="date" name="expiryDate" value={formData.expiryDate} onChange={handleChange} className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
                                  />
                                  {errors.expiryDate && (
                                    <div className="invalid-feedback">{errors.expiryDate}</div>
                                  )}
                                </div>
                                <div className="col-md-12 text-end">
                                  <button type="button" onClick={handleNextStep} className="default-btn">Next</button>
                                </div>
                              </div>
                            </div>
                          )}

                          {currentStep === 2 && (
                            <div>
                              <div className="row g-4">

                              <div className="col-md-6">
                                  <label className="form-label">Skills<span className='text-danger'>*</span></label>
                                  <textarea name="skills" value={formData.skills} onChange={handleChange} className={`form-control ${errors.skills ? 'is-invalid' : ''}`} />
                                  {errors.Skills && (
                                    <div className="invalid-feedback">{errors.Skills}</div>
                                  )}
                                </div>


                                

                                <div className="col-md-6">
                                  <label className="form-label">Responsibilities<span className='text-danger'>*</span></label>
                                  <textarea name="responsibilities" value={formData.responsibilities} onChange={handleChange} className={`form-control ${errors.responsibilities ? 'is-invalid' : ''}`} />
                                  {errors.responsibilities && (
                                    <div className="invalid-feedback">{errors.responsibilities}</div>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label">Qualifications<span className='text-danger'>*</span></label>
                                  <textarea name="qualifications" value={formData.qualifications} onChange={handleChange} className={`form-control ${errors.qualifications ? 'is-invalid' : ''}`} />
                                  {errors.qualifications && (
                                    <div className="invalid-feedback">{errors.qualifications}</div>
                                  )}
                                </div>

                                <div className="col-md-6">
                                  <label className="form-label">Benefit Details<span className='text-danger'>*</span></label>
                                  <textarea name="benefitsDetails" value={formData.benefitsDetails} onChange={handleChange} className={`form-control ${errors.benefitsDetails ? 'is-invalid' : ''}`} />
                                  {errors.benefitsDetails && (
                                    <div className="invalid-feedback">{errors.benefitsDetails}</div>
                                  )}
                                </div>


                                <div className="col-md-6">
                                  <label className="form-label">Terms Conditions Details<span className='text-danger'>*</span></label>
                                  <textarea name="termsAndConditions" value={formData.termsAndConditions} onChange={handleChange} className={`form-control ${errors.termsAndConditions ? 'is-invalid' : ''}`} />
                                  {errors.termsAndConditions && (
                                    <div className="invalid-feedback">{errors.termsAndConditions}</div>
                                  )}
                                </div>

                                <div className="col-md-6">
                                  <label className="form-label">Misc Details</label>
                                  <textarea name="miscDetails" value={formData.miscDetails} onChange={handleChange} className={`form-control ${errors.miscDetails ? 'is-invalid' : ''}`} />
                                  {errors.miscDetails && (
                                    <div className="invalid-feedback">{errors.miscDetails}</div>
                                  )}
                                </div>

                                <div className="col-md-6">
                                  <label className="form-label">Special Requirements</label>
                                  <textarea name="specialRequirements" value={formData.specialRequirements} onChange={handleChange} className={`form-control ${errors.specialRequirements ? 'is-invalid' : ''}`} />
                                  {errors.specialRequirements && (
                                    <div className="invalid-feedback">{errors.specialRequirements}</div>
                                  )}
                                </div>

                                <div className="col-md-12 text-end">
                                  <button type="button" onClick={handlePreviousStep} className="default-btn border-btn">Previous</button>
                                  <button type="submit" className="default-btn ms-2">Submit</button>
                                </div>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>

                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" style={{borderRadius:"10px"}}>
          <DialogContent style={{backgroundColor:"#f5f6fa"}}>
          <IconButton onClick={handleClose} size="small"
            style={{
             position: "absolute",
             top: "10px",
             right: "10px",
             zIndex: 10,
           }}>
                <CloseIcon />
              </IconButton>
            <SubscriptionPacks /> 
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default JobDescriptionForm;
