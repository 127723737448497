import React, { useState, useEffect } from 'react';
import { Container, Col, Row, } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import AllCandidates from '../components/All candidates';

const EmployerDashboard = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);;

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
  return (
    <div className="dashboard">
        < EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className='g-4'>
          <EmployerDashboardSideBar/>

          <Col md={10} className="main-content">
              <AllCandidates />
          </Col>
             
        </Row>
      </Container>

     

    </div>
  );
};

export default EmployerDashboard;
