import React, { useState } from "react";
import axios from "axios";
import logo from "../assets/workaroo_logo.png";
import { useNavigate } from "react-router-dom";

const CreateUserModal = ({handleLogin, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Create User</h3>
        <div className="role-toggle">
          <button className="modal-button">Create Employer</button>
          <button className="modal-button">Create Job Seeker</button>
        </div>
        <button onClick={onClose} className="modal-button">
          Close
        </button>
      </div>
    </div>
  );
};

const LoginModal = ({ onLogin, isOpen, onClose }) => {
  const [role, setRole] = useState("employer");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleSendOtp = async () => {
    if (!username || !phoneNumber || !password) {
      setErrorMessage("Username, phone number, and password are required.");
      setSuccessMessage("");
      return;
    }

    if (!/^\d{10}$/.test(phoneNumber)) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      setSuccessMessage("");
      return;
    }

    setErrorMessage("");
    setSuccessMessage("Sending OTP...");

    try {
      const endpoint =
        role === "employer"
          ? `${apiUrl}/employer/send-otp`
          : `${apiUrl}/jobseeker/send-otp`;

      const response = await axios.post(endpoint, {
        username,
        phoneNumber,
        password,
      });

      if (response.data.status) {
        setSuccessMessage("OTP sent successfully!");
        setErrorMessage("");
        setShowOtpModal(true);
      } else {
        setErrorMessage(response.data.message || "Failed to send OTP.");
        setSuccessMessage("");
        setShowOtpModal(false);
      }
    } catch (error) {
      setErrorMessage("Error while sending OTP. Please try again.");
      setSuccessMessage("");
      setShowOtpModal(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setErrorMessage("Please enter the OTP.");
      return;
    }

    try {
      const endpoint =
        role === "employer"
          ? `${apiUrl}/employer/verify-otp`
          : `${apiUrl}/jobseeker/verify-otp`;

      const response = await axios.post(endpoint, {
        username,
        phoneNumber,
        otpCode: otp,
      });

      if (response.data.status) {
        const { data } = response.data;
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("userDetails", JSON.stringify(data));
        sessionStorage.setItem("userName",JSON.stringify(data));
        sessionStorage.setItem("userId", data.userId)
        sessionStorage.setItem("role", data.role);
        // onLogin('jobseeker')
        navigate("/");
        onClose();
      } else {
        setErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Error verifying OTP. Please try again.");
    }
  };

  const resetFields = () => {
    setUsername("");
    setPhoneNumber("");
    setPassword("");
    setOtp("");
    setErrorMessage("");
    setSuccessMessage("");
    setShowOtpModal(false);
  };

  return (
    <div className="modal-overlay">
      <div className={`modal-content ${showOtpModal ? "blurred" : ""}`}>
        <div className="role-toggle">
          <button
            className={role === "employer" ? "active" : ""}
            onClick={() => {
              setRole("employer");
              resetFields();
            }}
          >
            Employer Login
          </button>
          <button
            className={role === "jobseeker" ? "active" : ""}
            onClick={() => {
              setRole("jobseeker");
              resetFields();
            }}
          >
            Job Seeker Logins
          </button>
        </div>

        <img src={logo} alt="Logo" className="modal-logo" />
        <h3 className="modal-title">
          {role === "employer" ? "Employer Login" : "Job Seeker Login"}
        </h3>

        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="modal-input"
        />
        <input
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))}
          className="modal-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="modal-input"
        />

        <button onClick={handleSendOtp} className="modal-button">
          Send OTP
        </button> 

        {(successMessage || errorMessage) && (
          <p className={successMessage ? "success-text" : "error-text"}>
            {successMessage || errorMessage}
          </p>
        )}

        <div className="modal-actions">
          <button onClick={onClose} className="modal-link1">
            Close
          </button>
          <button
            onClick={() => setShowCreateUserModal(true)}
            className="modal-link1"
          >
            Create User
          </button>
        </div>
      </div>

      {showOtpModal && (
        <div className="otp-modal">
          <h3>Verify OTP</h3>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="modal-input"
          />
          <button onClick={handleVerifyOtp} className="modal-button">
            Verify OTP
          </button>
          <button
            onClick={() => setShowOtpModal(false)}
            className="modal-button"
          >
            Back to Login
          </button>
        </div>
      )}

      <CreateUserModal
         handleLogin = {test}
        isOpen={showCreateUserModal}
        onClose={() => setShowCreateUserModal(false)}
      />
    </div>
  );
};

export default LoginModal;
