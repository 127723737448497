// import React, { useState, useEffect } from "react";
// import '../index.css';
// import { createBrowserRouter, useNavigate, Link } from "react-router-dom";
// import { FaFacebookF, FaGoogle, FaInstagram, FaYoutube, FaEnvelope, FaMapMarkerAlt, FaLinkedinIn } from "react-icons/fa";
// import logo from '../assets/logo.png';
// import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
// import { Container, Col, Row, Table, Button, Modal, Spinner, Card, Form } from 'react-bootstrap';
// import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";




// const Contact = () => {

//   const navigate = useNavigate();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const handleDropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);
//   const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
//   const [showJobseekerPopup, setShowJobseekerPopup] = useState(false);
//   const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const role = sessionStorage.getItem('role');


//   useEffect(() => {
//     const token = sessionStorage.getItem('accessToken');
//     if (token) {
//       console.log('Token exists:', token); // Token is found
//     } else {
//       console.log('Token does not exist'); // Token is not found
//     }
//     if (token) {
//       setIsLoggedIn(true);
//     }
//   }, []);

//   const handleLogout = () => {
//     sessionStorage.removeItem('accessToken');
//     sessionStorage.removeItem('role');
//     sessionStorage.removeItem('userDetails');
//     setIsLoggedIn(false);
//     setShowLogoutConfirmation(false);
//   };

//   const openJobseekerPopup = () => {
//     setShowJobseekerPopup(true);
//   };

//   const closeJobseekerPopup = () => {
//     setShowJobseekerPopup(false);
//   };


//   const openEmployerLoginPopup = () => {
//     setShowEmployerLoginPopup(true);
//   };

//   const closeEmployerLoginPopup = () => {
//     setShowEmployerLoginPopup(false);
//   };

//   const handleLogoutConfirmation = () => {
//     setShowLogoutConfirmation(true);
//   };




import React, { useState, useEffect } from "react";
import '../index.css';
import { useNavigate } from "react-router-dom";
import {
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import logo from '../assets/logo.png';
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";


const Contact = () => {
    const location = useLocation();
    const { Roles } = location.state || {};
    console.log(Roles);
    

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  // const [isJobseekerForm, setIsJobseekerForm] = useState(true); // State to toggle forms
  const [isJobseekerForm, setIsJobseekerForm] = useState(false);
  const colWidth = isLoggedIn ? 10 : 12; 
  
  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const role = sessionStorage.getItem('role');
    if (token) {
      setIsLoggedIn(true);
      setSidebar(true)
    }
    // if (role == 'employer'){
    //   setIsJobseekerForm(false)
    // }else{
    //   setIsJobseekerForm(true)
       
    //    }

  }, []);

  useEffect(()=>{
    if (Roles == 'employer'){
      setIsJobseekerForm(false)
      // setIsJobseekerForm(true)
      // alert("emp")
      // alert(isJobseekerForm)


    }
    if(Roles == 'jobseeker'){
      setIsJobseekerForm(true); // Toggle between forms
      // alert(isJobseekerForm)

      // setIsJobseekerForm(false)

       }
  },[Roles])

  const handleToggleForm = () => {
    setIsJobseekerForm(!isJobseekerForm); // Toggle between forms
  };

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
        {isLoggedIn? <EmployerDashboardSideBar />:
        <></>
           }
          <Col md={colWidth} className="main-content">
            <section className="slider-sec">
              <div className="container">
                <div className="row g-4">
                  <div className="col-lg-12 text-center">
                    <h1>Bridging the gap in blue-collar jobs.</h1>
                    <h5 className="mt-3 lh-base pe-lg-4">
                      At Workaroo, we connect skilled workers with top employers using AI-driven solutions to streamline recruitment.
                    </h5>
                  </div>
                </div>
                <div className="row g-4 mt-5">
                  <div className="col-lg-8 offset-lg-2">
                    {/* <div className="text-center mb-4">
                      <button
                        className="btn btn-primary me-2"
                        onClick={handleToggleForm}
                      >
                        {isJobseekerForm
                          ? "Switch to Employer Form"
                          : "Switch to Jobseeker Form"}
                      </button>
                    </div> */}
                    <form className="p-lg-5 p-4 shadow bg-white">
                      <h3 className="text-center">
                        {isJobseekerForm ? "Jobseeker Contact Form" : "Employer Contact Form"}
                      </h3>
                      <div className="row g-4 mt-4">
                        <div className="col-md-6">
                          <label className="form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" required />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Sur name 
                          </label>
                          <input type="text" className="form-control" required />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input type="email" className="form-control" required />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" required />
                        </div>
                        {isJobseekerForm ? (
                          <>
                            <div className="col-md-6">
                              <label className="form-label">
                                Skill <span className="text-danger">*</span>
                              </label>
                              <input type="text" className="form-control" required />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Experience <span className="text-danger">*</span>
                              </label>
                              <input type="text" className="form-control" required />
                            </div>
                            <div class="col-md-12">
                    <label for="jobSelect" class="form-label">What type of work/job do you need?</label>
                    <div id="jobSelect">
                      <div class="form-check-inline">
                        <input class="form-check-input" type="radio" name="job" id="plumber" value="plumber" />
                        <label class="form-check-label ms-2" for="plumber">
                          Plumber
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input class="form-check-input" type="radio" name="job" id="electrician" value="electrician" />
                        <label class="form-check-label ms-2" for="electrician">
                          Electrician
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input class="form-check-input" type="radio" name="job" id="driver" value="driver" />
                        <label class="form-check-label ms-2" for="driver">
                          Driver
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input class="form-check-input" type="radio" name="job" id="mechanical" value="mechanical" />
                        <label class="form-check-label ms-2" for="mechanical">
                          Mechanic
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input class="form-check-input" type="radio" name="job" id="welding" value="welding" />
                        <label class="form-check-label ms-2" for="welding">
                          Welder
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input class="form-check-input" type="radio" name="job" id="other" value="other" />
                        <label class="form-check-label ms-2" for="other">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                          </>
                        ) : (
                          <>
                          <div className="col-md-6">
                            <label className="form-label">
                              Company Name <span className="text-danger">*</span>
                            </label>
                            <input type="text" className="form-control" required />
                          </div>
                          
                          <div class="col-md-12">
                          <label for="jobSelect" class="form-label">What type of worker/job seeker  do you need?</label>
                          <div id="jobSelect">
                            <div class="form-check-inline">
                              <input class="form-check-input" type="radio" name="job" id="plumber" value="plumber" />
                              <label class="form-check-label ms-2" for="plumber">
                                Plumber
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <input class="form-check-input" type="radio" name="job" id="electrician" value="electrician" />
                              <label class="form-check-label ms-2" for="electrician">
                                Electrician
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <input class="form-check-input" type="radio" name="job" id="driver" value="driver" />
                              <label class="form-check-label ms-2" for="driver">
                                Driver
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <input class="form-check-input" type="radio" name="job" id="mechanical" value="mechanical" />
                              <label class="form-check-label ms-2" for="mechanical">
                                Mechanic
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <input class="form-check-input" type="radio" name="job" id="welding" value="welding" />
                              <label class="form-check-label ms-2" for="welding">
                                Welder
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <input class="form-check-input" type="radio" name="job" id="other" value="other" />
                              <label class="form-check-label ms-2" for="other">
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        </>
                        )}
                                        
                        
                        <div className="col-md-12">
                          <label className="form-label">Message </label>
                          <textarea
                            placeholder="Write your message..."
                            rows="4"
                            className="form-control"
                          ></textarea>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="default-btn">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      {/* <Footer/> */}
    </div>
  );
};

export default Contact;


