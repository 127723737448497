import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Form, Spinner, Button, InputGroup, Image, Modal, Card } from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { toast } from 'react-hot-toast';


const JobSeekerProfileView = () => {

  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null); 
  const [preview, setPreview] = useState(null); 

  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    city: "",
    state: "",
    firstName: "",
    lastName: "",
    surName: "",
    designation: "",
    department: "",
    cinNumber: "",
    gstIn: "",
    panNumber: "",
    industryCategory: "",
    industrySubCategory: "",
    branch: "",
    landmark: "",
    pincode: "",
    communicationAdress: "",
    country: "",
    image: "",
    otpCode: "",
    otpExpiryTime: "",
    emailAddress: "",
    address: "",
    pinCode: "",
    aadharNo: "",
    panNo: "",
    voterId: "",
    drivingLicenceNumber: "",
    profileTitle: "",
    totalExperience: "",
    salary: "",
    expectedSalary: "",
    miscDetails: "",
    skills: [],
    qualification: [],
    responsibilities: [],
    dob: "",
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
  

  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const id = sessionStorage.getItem("userId");

        const response = await axios.get(`${apiUrl}/user/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.data && response.data.data) {
          setUserDetails(response.data.data);
          setFormData(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch profile.");
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError("An error occurred while fetching the profile.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
 
    // Fields that should be converted to arrays
    const arrayFields = ['skills', 'responsibilities', 'qualification'];
 
    setFormData((prevData) => ({
      ...prevData,
      [name]: arrayFields.includes(name)
        ? value.split(',').map((item) => item.trim()) // Convert comma-separated string to array
        : value, // Directly set the value for other fields
    }));
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClick = () => {
    setShowSuccessModal(true);
  };

  const handleFileUpload = async () => {
    if (!file) return null;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(`${apiUrl}/shared/file-upload`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setIsUploading(false);

      if (response.status === 200) {
        toast.success("File uploaded successfully.")
        return response.data.fileName; 
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      setIsUploading(false);
      toast.error("File upload failed.")
      // Swal.fire({
      //   title: "Error!",
      //   text: error.response?.data?.message || "File upload failed.",
      //   icon: "error",
      //   confirmButtonText: "OK",
      // });
      return null;
    }
  };

  const handleDoneClick = async () => {
    // Check if required fields are filled
    const requiredFields = [
      "firstName", // Personal Details
      "salary", "expectedSalary", "skills", "totalExperience" // Professional Details
    ];
  
    const missingFields = requiredFields.filter(field => !formData[field]);
  
    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the following required fields: ${missingFields.join(", ")}`,
        {
          style: {
            marginTop: '60px', // You can adjust this
            backgroundColor: 'red', // Example custom background color
            color: 'white', // Text color
          }
        }
      );
      return;
    }
    // Proceed with the file upload and saving if all required fields are filled
    const uploadedFileName = await handleFileUpload();
    const userId = sessionStorage.getItem("userId");
  
console.log(formData);
    const payload = {
      ...formData,
      resume: uploadedFileName,
      userId,
      skills: Array.isArray(formData.skills) ? formData.skills : [],
      responsibilities: Array.isArray(formData.responsibilities) ? formData.responsibilities : [],
      qualification: Array.isArray(formData.qualification) ? formData.qualification : []
    };

    try {
      await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });

      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile.");
    }

  };
  
  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result); 
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const personalDetailsFields = [
    "firstName",
    "lastName",
    "phoneNumber",
    "email",
    "address",
    // "dob",
    "city",
    "state",
    "pinCode",
    // "country",
    // "profileTitle",
  ];

  const kycFields = [
    "aadharNo",
    "panNo",
    "voterId",
    "drivingLicenceNumber",
    // "address",
    // "pinCode",
  ];

  const professionalDetailsFields = [
    "profileTitle",
    "totalExperience",
    "companyName",
    "skills",
    "responsibilities",
    "qualification",
    "salary",
    "expectedSalary",
    // "languageKnown",
    "miscDetails",
    // "role",
    // "designation",
    // "department",
    // "salary",
    // "expectedSalary",
    // "coverLetter"
  ];

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">

        <Row className="g-4">
          <EmployerDashboardSideBar />

          <Col md={10} className="main-content">
            <div className="card border-0 rounded-0">
              <div className="card-body">
                <div className="profile-details">
                  <div
                    className="profile-image-wrapper"
                    style={{ position: "absolute", top: "106px", right: "20px", zIndex: "1000", cursor: "pointer" }}
                    onClick={handleImageClick}
                  >
                    <Image
                      src={formData.image || "https://via.placeholder.com/100"}
                      alt="Profile Picture"
                      roundedCircle
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                  </div>

                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                <h5 className="mb-4">Profile Details</h5>
<Card className="mb-4">
  <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
    Personal Details
  </Card.Header>
  <Card.Body>
    <Row className="g-3" style={{ marginTop: '120px' }}>
      {personalDetailsFields.map((key) => (
        <Col md={6} key={key}>
          <InputGroup>
            <InputGroup.Text>
              {key === "firstName" || key ==="email" ? (
                <>
                  {key.replace(/([A-Z])/g, ' $1').toUpperCase()} <span style={{ color: 'red' }}>*</span>
                </>
              ) : (
                key.replace(/([A-Z])/g, ' $1').toUpperCase()
              )}
            </InputGroup.Text>
            <Form.Control
              type={key === "dob" ? "date" : "text"}
              name={key}
              value={formData[key]}
              onChange={handleChange}
              required={key === "firstName"   || key ==="email"}
            />
          </InputGroup>
        </Col>
      ))}
    </Row>
  </Card.Body>
</Card>


                  <Card className="mb-4">
                    <Card.Header style={{backgroundColor:"rgb(47 39 97)",color:"white"}}>KYC Details</Card.Header>
                    <Card.Body>
                      <Row className="g-3">
                        {kycFields.map((key) => (
                          <Col md={6} key={key}>
                            <InputGroup>
                              <InputGroup.Text>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                              />
                            </InputGroup>
                          </Col>
                        ))}
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4">
                    <Card.Header style={{backgroundColor:"rgb(47 39 97)",color:"white"}}>Professional Details</Card.Header>
                    <Card.Body>
  <Row className="g-3">
    {professionalDetailsFields.map((key) => (
      <Col md={6} key={key}>
        <InputGroup>
          <InputGroup.Text>
            {key === "salary" || key === "expectedSalary" || key === "skills" || key === "totalExperience" ? (
              <>
                {key.replace(/([A-Z])/g, ' $1').toUpperCase()} <span style={{ color: 'red' }}>*</span>
              </>
            ) : (
              key.replace(/([A-Z])/g, ' $1').toUpperCase()
            )}
          </InputGroup.Text>
          <Form.Control
            type={key === "dob" ? "date" : "text"}
            name={key}
            value={formData[key]}
            onChange={handleChange}
            required={key === "salary" || key === "expectedSalary" || key === "skills" || key === "totalExperience"}
          />
        </InputGroup>
      </Col>
    ))}
  </Row>

  <Row>
    <Form.Group controlId="resume" className="mb-4">
      <Form.Label>Upload Resume</Form.Label>
      <Form.Control
        type="file"
        onChange={handleFileChange}
        required
      />
    </Form.Group>
  </Row>
</Card.Body>

                  </Card>

                  <div className="d-flex justify-content-between mt-3">
                    <Button className="default-btn" onClick={handleDoneClick}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully updated.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobSeekerProfileView;
