import React from "react";

const Pagination = () => {
  const styles = {
    pagination: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      margin: "20px 0",
    },
    button: {
      padding: "10px 15px",
      fontSize: "16px",
      border: "none",
      backgroundColor: "#210d25",
      cursor: "pointer",
      borderRadius: "5px",
      color: "#ffffff",
    },
  };

  return (
    <div style={styles.pagination}>
      <button style={styles.button}>&lt; Previous</button>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((page) => (
        <button key={page} style={styles.button}>
          {page}
        </button>
      ))}
      <button style={styles.button}>Next &gt;</button>
    </div>
  );
};

export default Pagination;