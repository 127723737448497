// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-box{
    cursor: pointer;
}
.img-box video{
    border-radius: 20px;
}
.img-box video:hover{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.blogcard:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    cursor: pointer;
}
.readmore{
    color:#170F49;
    text-decoration: underline;
}
.cardtitle:hover{
    color:#170F49
}
.readarrow{
    color: rgb(255, 34, 34);
    margin-right:5px ;
  
}`, "",{"version":3,"sources":["webpack://./src/Styles/videoPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,+EAA+E;AACnF;AACA;IACI,gFAAgF;IAChF,eAAe;AACnB;AACA;IACI,aAAa;IACb,0BAA0B;AAC9B;AACA;IACI;AACJ;AACA;IACI,uBAAuB;IACvB,iBAAiB;;AAErB","sourcesContent":[".img-box{\r\n    cursor: pointer;\r\n}\r\n.img-box video{\r\n    border-radius: 20px;\r\n}\r\n.img-box video:hover{\r\n    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;\r\n}\r\n.blogcard:hover{\r\n    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;\r\n    cursor: pointer;\r\n}\r\n.readmore{\r\n    color:#170F49;\r\n    text-decoration: underline;\r\n}\r\n.cardtitle:hover{\r\n    color:#170F49\r\n}\r\n.readarrow{\r\n    color: rgb(255, 34, 34);\r\n    margin-right:5px ;\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
