import React, { useState, useEffect } from "react";
import { FaStar, FaSuitcase, FaRupeeSign, FaMapMarkerAlt, FaBook } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import axios from 'axios';

const JobSearch = () => {
  const location = useLocation();  // useLocation hook to access query params
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { locations } = location.state || {};  // Extract location and skills from state
  const { skills } = location.state || {};
  console.log("Locations:", locations, "Skills:", skills);

  const apiUrl = process.env.REACT_APP_BASE_URL;  // Ensure this environment variable is set

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      setError(null);  // Reset error state before fetching

      try {
        const response = await axios.get(`${apiUrl}/job/joblist/filter`, {
          params: { skills, locations },
        });
        setJobs(response.data.data);  // Store jobs data in state
      } catch (err) {
        console.error("Error fetching jobs:", err);
        setError("Error fetching job listings.");
      } finally {
        setLoading(false);  // Set loading to false once the request is complete
      }
    };

    if (skills && locations) {
      fetchJobs();  // Only fetch if both skills and locations are defined
    }
  }, [skills, locations, apiUrl]);  // Effect runs when skills or locations change

  if (loading) {
    return <div>Loading...</div>;  // Show loading text while waiting for data
  }

  if (error) {
    return <div>{error}</div>;  // Show error if there's an issue with the request
  }

  return (
    <div className="job-listings">
      {jobs.length > 0 ? (
        jobs.map((job) => (
          <div key={job.jobId} className="col-lg-3">
            <div className="list-block" style={{ margin: "25px 25px", width: "95vw" }}>
              <div className="list-title">
                <h5 className="fw-bold mb-2">{job.jobTitle}</h5>
                <p className="m-0 f-14 d-flex">
                  {job.companyName || "Company Name"}{" "}
                  <span className="ms-3 me-1 rev-icon">
                    <FaStar />
                  </span>
                  <span className="f-13">1020 Reviews</span>
                </p>
              </div>
              <div className="border-bottom my-2"></div>
              <p className="list-desc mb-1">
                <span className="f-14 d-flex align-items-center gap-2">
                  <FaSuitcase />
                  <span>
                    {job.experienceFrom} - {job.experienceTo} years
                  </span>
                </span>
                <span className="f-14 d-flex align-items-center gap-2">
                  <FaRupeeSign />
                  <span>{job.currentCTC || "Not Disclosed"}</span>
                </span>
                <span className="f-14 d-flex align-items-center gap-2">
                  <FaMapMarkerAlt />
                  <span>{job.location}</span>
                </span>
                <span className="f-14 d-flex gap-2 mt-3">
                  <FaBook />
                  <span>{job.responsibilitiesAndDuties || "No further details available"}</span>
                </span>
              </p>
            </div>
          </div>
        ))
      ) : (
        <p>No job listings available at the moment.</p>
      )}
    </div>
  );
};

export default JobSearch;
