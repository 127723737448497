import React from 'react';
import logoImage from '../assets/workaroo_logo.png';

const MainPage = () => {
    const jobs = [
        { title: "Software Engineer", company: "Tech Corp", location: "New York, NY", experience: "2-3 years", datePosted: "2 hours ago" },
        { title: "Product Manager", company: "Biz Solutions", location: "San Francisco, CA", experience: "5+ years", datePosted: "1 day ago" },
        { title: "UX Designer", company: "Creative Studio", location: "Los Angeles, CA", experience: "3+ years", datePosted: "3 days ago" },
    ];

    const styles = {
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 20px',
            backgroundColor: '#e6efff',
            borderRadius: '15px',
        },
        logo: {
            fontSize: '24px',
            color: '#174177',
        },
        logoImage: {
            height: '30px', // Adjust size as needed
        },
        smallButton: {
            margin: '0 15px',
            padding: '10px 20px',
            backgroundColor: 'white',
            color: '#174177',
            border: '2px solid #174177',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
        },
        searchBar: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        },
        searchInput: {
            padding: '10px 20px',
            width: '300px',
            borderRadius: '25px',
            border: '1px solid #ccc',
            outline: 'none',
            fontSize: '16px',
        },
        nav: {
            display: 'flex',
            alignItems: 'center',
        },
        navLink: {
            margin: '0 20px',
            textDecoration: 'none',
            color: '#174177',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        iconButton: {
            margin: '0 15px',
            background: 'none',
            border: 'none',
            color: '#174177',
            cursor: 'pointer',
            fontSize: '24px',
        },
        profileImage: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            marginLeft: '15px',
        },
        content: {
            marginTop: '10px',
            padding: '20px',
            textAlign: 'center',
        },
        heading: {
            fontSize: '36px',
            color: '#174177',
            marginBottom: '5px',
        },
        subheading: {
            fontSize: '22px',
            color: '#666',
            marginBottom: '15px',
        },
        card: {
            border: '1px solid #ccc',
            borderRadius: '10px',
            padding: '20px',
            margin: '20px auto',
            width: '100%', // Set card width to 100%
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f9f9f9',
        },
        cardButton: {
            padding: '15px 20px', // Increased button height
            backgroundColor: '#174177',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
            flex: 1,
            margin: '0 5px',
        },
        jobItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid #174177',
            padding: '10px 20px',
            margin: '10px 0',
            backgroundColor: '#e6efff',
            borderRadius: '5px',
            fontFamily: "'Arial', sans-serif",
        },
        jobInfo: {
            display: 'flex',
            flexDirection: 'column',
            fontSize: '14px',
            color: '#174177',
            fontWeight: 'bold',
        },
        jobDetails: {
            fontSize: '12px',
            color: '#666',
            marginTop: '5px',
        },
        applyButton: {
            display: 'flex', // Flex to align icons
            alignItems: 'center',
            backgroundColor: '#174177',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
        },
        applyIcon: {
            marginLeft: '10px', // Space between text and second icon
        },
        footer: {
            textAlign: 'center',
            padding: '20px',
            backgroundColor: '#174177',
            color: 'white',
            marginTop: '20px',
        },
        newsletterSignup: {
            marginTop: '10px',
        },
        inputEmail: {
            padding: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #174177',
        },
        buttonContent: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            color: 'white',
        },
        buttonIcon: {
            marginRight: '5px',
        },
    };

    return (
        <div className="main-page">
            <header style={styles.header}>
            <div style={styles.logo}>
                    <img src={logoImage} alt="Workaroo Logo" style={styles.logoImage} /> {/* Logo image */}
                </div>
                <button style={styles.smallButton}>Post a Job</button>
                <div style={styles.searchBar}>
                    <input
                        style={styles.searchInput}
                        type="text"
                        placeholder="🔍 Search"
                    />
                </div>
                <nav style={styles.nav}>
                    <a style={styles.navLink} href="#">Dashboard</a>
                    <a style={styles.navLink} href="#">Profile</a>
                    <button style={styles.iconButton}>🔔</button> {/* Notification bell icon */}
                    <img
                        style={styles.profileImage}
                        src="https://via.placeholder.com/50" // Placeholder for profile image
                        alt="Profile"
                    />
                    <button style={styles.iconButton}>🚪</button> {/* Logout icon */}
                </nav>
            </header>

            <div style={styles.content}>
                <h1 style={styles.heading}>Find your dream job here</h1>
                <h4 style={styles.subheading}>Get the job you want</h4>

                {/* Empty card with four buttons */}
                <div style={styles.card}>
                    <button style={styles.cardButton}>
                        <div style={styles.buttonContent}>
                            <span style={styles.buttonIcon}>🔍</span> {/* Job search icon */}
                            Search Job Keyword
                        </div>
                    </button>
                    <button style={styles.cardButton}>
                        <div style={styles.buttonContent}>
                            <span style={styles.buttonIcon}>📍</span> {/* Location icon */}
                            Search Job Location
                        </div>
                    </button>
                    <button style={styles.cardButton}>
                        <div style={styles.buttonContent}>
                            <span style={styles.buttonIcon}>💼</span> {/* Experience icon */}
                            Enter Experience
                        </div>
                    </button>
                    <button style={styles.cardButton}>
                        <div style={styles.buttonContent}>
                            <span style={styles.buttonIcon}>📁</span> {/* Changed icon to folder */}
                            Browse Jobs
                        </div>
                    </button>
                </div>

                <section>
                    <h2>Recent Job Listings</h2>
                    {jobs.map((job, index) => (
                        <div style={styles.jobItem} key={index}>
                            <div style={styles.jobInfo}>
                                <div>Job Name: {job.title}</div>
                                <div>Job Location: {job.location}</div>
                                <div>Job Exp: {job.experience}</div>
                                <div>Job Posted: {job.datePosted}</div>
                            </div>
                            <button style={styles.applyButton}>
                                Apply Now &nbsp; <span>➡️</span> {/* Added second icon */}
                                <span style={styles.applyIcon}>📄</span> {/* Added additional icon */}
                            </button>
                        </div>
                    ))}
                </section>
            </div>

            <footer style={styles.footer}>
                <p>© 2024 Company Name. All rights reserved.</p>
                <a href="#" style={{ color: 'white' }}>Terms of Service</a>
                <a href="#" style={{ color: 'white', marginLeft: '15px' }}>Privacy Policy</a>
                <div style={styles.newsletterSignup}>
                    <input style={styles.inputEmail} type="email" placeholder="Subscribe to job alerts" />
                    <button style={styles.smallButton}>Subscribe</button>
                </div>
            </footer>
        </div>
    );
};

export default MainPage;
