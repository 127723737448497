import React, { useState, useEffect } from "react";

import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';

import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";

import  aboutVideo  from "../../assets/Aboutvideo.mp4"
import '../../Styles/videoPage.css'


const VideoPage = () => {

  const [show, setShow] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const colWidth = isLoggedIn ? 10 : 12;

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const role = sessionStorage.getItem('role');
    if (token) {
      setIsLoggedIn(true);
      // setSidebar(true)
    }

  }, []);
  

  return (
    <>
      <div className="dashboard">
        <EmployerDashboardHeader/>
        <Container fluid className="content">
          <Row className="g-4">
            {isLoggedIn ? <EmployerDashboardSideBar /> :
              <></>
            }
            <Col md={colWidth} className="main-content">
              <section
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "40px 0 10px",
                  textAlign: "center",
                }}
              >
                <Container>
                  <h1 style={{ fontWeight: "bold" ,marginBottom:'0px',color:'#170F49'}}> Videos </h1>
                  
                </Container>
              </section>

              {/* Blog Section */}
              <Container style={{ padding: "30px 0" }}>
  <Row className="g-4" style={{ display: 'flex', alignItems: 'stretch' }}>
        <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                <video width="100%" height="auto" controls>
                    <source src={aboutVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>
            
  </Row>
</Container> 
            </Col>
          </Row>
        </Container>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export default VideoPage;


