import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../EmployerDashboard.css';
import axios from 'axios';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';

const AppliedJob = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedJobDetails, setSelectedJobDetails] = useState(null);
  const userId = sessionStorage.getItem("userId");
  const handleJobNameClick = (jobDetails) => {
    setSelectedJobDetails(jobDetails); 
    setShowModal(true); 
  };

  const [filter, setFilter] = useState({
    jobTitle: '',
    location: '',
    status: '',
    minSalary: '',
    maxSalary: '',
  });

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/job/applications/${userId}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data.data)) {
          setApplicants(response.data.data);
          setFilteredApplicants(response.data.data); 
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [apiUrl]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    let filtered = applicants;

    if (filter.jobTitle) {
      filtered = filtered.filter(applicant =>
        applicant?.job?.jobTitle && applicant?.job?.jobTitle.toLowerCase().includes(filter.jobTitle.toLowerCase())
      );
    }
    if (filter.location) {
      filtered = filtered.filter(applicant =>
        applicant?.job?.location && applicant?.job?.location.toLowerCase().includes(filter.location.toLowerCase())
      );
    }
    if (filter.status) {
      filtered = filtered.filter(applicant =>
        applicant?.status && applicant?.status.toLowerCase() === filter.status.toLowerCase()
      );
    }

    setFilteredApplicants(filtered);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <EmployerDashboardSideBar />
          <Col md={10} className="main-content">
            <h5 className="mb-4 fw-bold">Applied Jobs</h5>

          
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Form>
                  <Row className="justify-content-between">
                    <Col sm={3}>
                      <Form.Group controlId="jobTitle">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="jobTitle"
                          value={filter?.jobTitle}
                          onChange={handleFilterChange}
                          placeholder="Job Title"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="location">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type="text"
                          name="location"
                          value={filter?.location}
                          onChange={handleFilterChange}
                          placeholder="Location"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          value={filter?.status}
                          onChange={handleFilterChange}
                        >
                          <option value="">All</option>
                          <option value="applied">Applied</option>
                          <option value="shortlist">Shortlisted</option>
                          <option value="scheduled">schudule</option>
                          <option value="selected">Selected</option>
                          <option value="offer">Offered</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={2} className="d-flex align-items-end" style={{marginTop:"27px"}}>
                      <Button style={{backgroundColor:" rgb(47 39 97)"}}  onClick={applyFilters} className="btn w-100">
                        Apply Filters
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>

            
            <Card className="shadow-sm">
              <Card.Body>
                <div className="table-responsive">
                  <Table hover striped variant="light">
                    <thead>
                      <tr className="bg-light">
                        <th>Job Title</th>
                        <th>Location</th>
                        <th>₹ Salary/Month</th>
                        <th>Applied Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredApplicants.map((applicant) => (
                        <tr key={applicant?.applicationId} className="table-row">
                          <td>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleJobNameClick(applicant?.job)}
                            >
                              {applicant?.job?.jobTitle}
                            </span>
                          </td>
                          <td>{applicant?.job?.location}</td>
                          <td>{applicant?.job?.salary}</td>
                          <td>{new Date(applicant?.createdAt).toLocaleDateString()}</td>
                          <td>{applicant?.status || 'Applied'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Job Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedJobDetails ? (
            <>
              <p><strong>Job Name:</strong> {selectedJobDetails?.jobTitle}</p>
              <p><strong>Location:</strong> {selectedJobDetails?.location}</p>
              <p><strong>Description:</strong> {selectedJobDetails?.responsibilitiesAndDuties}</p>
              <p><strong>Posted Date:</strong> {new Date(selectedJobDetails?.startDate).toLocaleDateString()}</p>
              <p><strong>Expiry Date:</strong> {new Date(selectedJobDetails?.expiryDate).toLocaleDateString()}</p>
              <p><strong>₹ Salary/Month:</strong> {selectedJobDetails?.salary}</p>
              <p><strong>Total Experience:</strong> {selectedJobDetails?.totalExperience}</p>
              <p><strong>Skills:</strong> {selectedJobDetails?.skills}</p>
            </>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AppliedJob;
